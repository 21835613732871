import React, {Fragment, useEffect, useState} from 'react';
import ToolbarTitle from "../../page/toolbar/ToolbarTitle";
import {useParams} from "react-router-dom";
import PageContainer from "../../shared/page/PageContainer";
import {FunnelGeneralInfo, Order} from "../../../typings/Dropton";
import ApiFunnels from "../../../services/ApiFunnels";
import Filter from "./Components/Filter";
import ApiOrders from "../../../services/ApiOrders";
import Stats from "./Components/Stats";
import moment from "moment";

export default function FunnelMarketingPage() {
    const {id, secret} = useParams();
    const [funnel, setFunnel] = useState<FunnelGeneralInfo>();

    const [orders, setOrders] = useState<Order[]>([]);

    const yesterday = moment().subtract(1, 'days').format("YYYY-MM-DD")
    // const yesterday = '2020-06-07';
    const [dateFrom, setDateFrom] = useState<string>(yesterday);
    const [dateTo, setDateTo] = useState<string>(yesterday);
    const [paymentType, setPaymentType] = useState<string>('');

    useEffect(() => {
        ApiFunnels.token = secret;
        ApiOrders.token = secret;

        getData();
        getStatsData();
    }, [secret, id]);

    const getData = () => {
        ApiFunnels.getFunnelGeneralInfo(id).then((res: any) => {
            if (res.success === 1) {
                setFunnel(res.data);
                getStatsData();
            }
        });
    };

    const getStatsData = () => {
        setOrders([]);
        if (!!id && !!dateFrom && !!dateTo) {
            ApiOrders.fetchStatsOrders(id, dateFrom, dateTo, paymentType).then(res => {
                if (res.success === 1) {
                    setOrders(res.data);
                }
            });
        }
    }

    const downloadStatsData = () => {
        if (!!id && !!dateFrom && !!dateTo) {
            ApiOrders.downloadStatsOrders(id, dateFrom, dateTo, paymentType).then(res => {
                window.open(res.url);
            });
        }
    }

    // useEffect(getData, [id, secret]);
    // useEffect(getStatsData, [id]);


    return (
        <PageContainer>
            {funnel &&
            <Fragment>
                {/*<ToolbarTitle title={funnel.name + " - Stats"}/>*/}

                <Filter
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    setDateFrom={setDateFrom}
                    setDateTo={setDateTo}
                    paymentType={paymentType}
                    setPaymentType={setPaymentType}
                    getStatsData={getStatsData}
                    downloadStatsData={downloadStatsData}
                />
                {orders.length > 0 &&
                <Stats orders={orders} dateFrom={dateFrom} dateTo={dateTo}/>
                }
            </Fragment>
            }
        </PageContainer>
    )
}

import React from "react";
import FormService from "../../service/FormService";
import FormGroupInput from "../fields/FormGroupInput";
import {useForm} from "../FormProvider";

interface Props {
    title: string;
    inputKey: string;
    size?: string;
}

export default function ProviderFormGroupInput({title, inputKey, size}: Props) {

    const {formData, setFormData, setIsDataChange} = useForm();

    return (<FormGroupInput title={title} value={formData[inputKey]}
                            size={size}
                            onChange={(val: string) => {
                                FormService.onStringChange(inputKey, val, formData, setFormData);
                                setIsDataChange(true);
                            }}/>);
}

import React, {Fragment} from 'react';
import ToolbarTitle from "../../page/toolbar/ToolbarTitle";
import FeedbackText from "./Components/FeedbackText";
import FeedbackForm from "./Components/FeedbackForm";
import PageContainer from "../../shared/page/PageContainer";
import FullSizeRow from "../../shared/grid/FullSizeRow";

export default function FeedbackPage() {
    return (
        <Fragment>
            <PageContainer>
                <ToolbarTitle title={"Contact US"} subtitle={"We would love to hear from you"} skipBack={true}/>
                <FullSizeRow>
                    <div className="card">
                        <div className="card-body">
                            <div className="row d-flex align-items-center">
                                <div className="col-6">
                                    <FeedbackText/>
                                </div>
                                <div className="col-6">
                                    <FeedbackForm/>
                                </div>
                            </div>
                        </div>
                    </div>
                </FullSizeRow>
            </PageContainer>
        </Fragment>
    )
}

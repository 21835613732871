import React, {Fragment, useEffect, useState} from "react";
import RowAction from "./RowAction";
import {useDispatch, useSelector} from "react-redux";
import {useListProvider} from "../../../../../Providers/ListProvider";
import {SourceProduct} from "../../../../../typings/Dropton";
import ProductsSelectComponent from "../../Components/ProductsSelectComponent";
import ApiFunnels from "../../../../../services/ApiFunnels";
import {LctlogisticSettings, ShipbobSettings, ShipicaSettings} from "../../../../../typings/Settings";
import ShipbobProductsSelectComponent from "../Shipbob/ShipbobProductsSelectComponent";
import ProductsListQuantityValue from "./ProductsListQuantityValue";
import ShipicaProductsSelectComponent, {Option} from "../../Components/ShipicaProductsSelectComponent";
import LctlogisticProductsSelectComponent from "../Lctlogistic/LctlogisticProductsSelectComponent";
import ProductsListSkuValue from "./ProductsListSkuValue";
import ProductsListTypeValue from "./ProductsListTypeValue";


interface Props {
    id: number;
}

export default function ProductsList({id}: Props) {
    const {archiveMode, searchString} = useListProvider();
    const [filteredProducts, setFilteredProducts] = useState<SourceProduct[]>([]);
    const [settingsShipbob, setSettingsShipbob] = useState<ShipbobSettings>();
    const [settingsShipica, setSettingsShipica] = useState<ShipicaSettings>();
    const [settingsLctlogistic, setSettingsLctlogistic] = useState<LctlogisticSettings>();
    const [viewId, setViewId] = useState(0);

    const products = useSelector((state: any) => state.SourceProducts);
    const dispatch = useDispatch();

    const getData = () => {
        dispatch({type: "FUNNEL_SOURCE_PRODUCTS_FETCH_REQUESTED", id: id})
    };

    const mapProduct = (id: number, productId: number) => {
        dispatch({type: "MAP_SOURCE_PRODUCT", id: id, productId: productId});
    };

    const mapLctlogisticProduct = (id: number, productId: string, index: number) => {
        dispatch({type: "MAP_SOURCE_PRODUCT_LCTLOGISTIC", id: id, productId: productId, index: index});
    };

    const mapShipbobProduct = (id: number, productId: string, index: number) => {
        dispatch({type: "MAP_SOURCE_PRODUCT_SHIPBOB", id: id, productId: productId, index: index});
    };

    const mapShipicaProduct = (id: number, productId: number, index: number) => {
        dispatch({type: "MAP_SOURCE_PRODUCT_SHIPICA", id: id, productId: productId, index: index});
    };

    const mapQuantityProduct = (id: number, quantity: number, index: number) => {
        dispatch({type: "MAP_SOURCE_PRODUCT_QUANTITY", id: id, quantity: quantity, index: index});
    };

    const mapSkuProduct = (id: number, val: string) => {
        dispatch({type: "MAP_SOURCE_PRODUCT_SKU", id: id, sku: val});
    };

    const mapTypeProduct = (id: number, val: string) => {
        dispatch({type: "MAP_SOURCE_PRODUCT_TYPE", id: id, typeVal: val});
    };

    const filterProducts = () => {
        let data = products;
        if (archiveMode === 0) {
            data = data.filter((item: SourceProduct) => !item.archived);
        } else if (archiveMode === 1) {
            data = data.filter((item: SourceProduct) => item.archived);
        }
        if (searchString.length > 0) {
            data = data.filter((item: SourceProduct) => {
                if (item.name.toLowerCase().indexOf(searchString.toLowerCase()) >= 0) {
                    return true;
                }
                return false;
            });
        }
        data.reverse();
        setFilteredProducts(data);
    };

    useEffect(() => {
        if (id) {
            getData();
            ApiFunnels.getFunnelShipbobSettings(id).then(res => {
                if (res.success === 1) {
                    setSettingsShipbob(res.data);
                }
            })
            ApiFunnels.getFunnelShipicaSettings(id).then(res => {
                if (res.success === 1) {
                    setSettingsShipica(res.data);
                }
            })
            ApiFunnels.getFunnelLctlogisticSettings(id).then(res => {
                if (res.success === 1) {
                    setSettingsLctlogistic(res.data);
                }
            })
        }
    }, [id]);

    useEffect(() => {
        filterProducts();
    }, [products, archiveMode, searchString]);

    return (<table className="table table-striped no-table-th-border">
        <thead>
        <tr>
            <th scope="col">ID</th>
            <th scope="col" style={{width: '20%'}}>Name</th>
            <th scope="col" style={{width: '20%'}}>Source</th>
            <th scope="col" style={{width: '20%'}}>Map</th>
            <th scope="col">Type</th>
            <th scope="col">Sku</th>
            <th scope={"col"} style={{width: 50}}></th>
        </tr>
        </thead>
        <tbody>
        {filteredProducts.map((product: SourceProduct) => {
            return (
                <Fragment key={"product-key-" + product.id}>
                    <tr onClick={() => setViewId(product.id)}>
                        <th scope={"row"}>{product.id}</th>
                        <td>
                            {product.name}
                            {product.map === '0' && (<Fragment><br/><span
                                className="badge badge-pill badge-danger badge-outline mr-1">UNMAPPED</span></Fragment>)}
                            {product.map === '-1' && (
                                <Fragment><br/><span className="badge badge-pill badge-light badge-digital mr-1">DIGITAL ITEM</span></Fragment>)}
                            {product.map === '-2' && (
                                <Fragment><br/><span className="badge badge-pill badge-light badge-digital mr-1">MAPPED FF</span></Fragment>)}
                        </td>
                        <td>
                            {product.source}
                        </td>
                        <td style={{width: '20%'}}>
                            <ProductsSelectComponent onlyActive={true} value={product.map}
                                                     onChange={(productId: string) => {
                                                         mapProduct(product.id, parseInt(productId, 10));
                                                     }}/>
                        </td>


                        <td style={{width: '10%'}}>
                            <ProductsListTypeValue
                                onChange={(val) => mapTypeProduct(product.id, val)}
                                value={product.type}
                            />
                        </td>
                        <td style={{width: '10%'}}>
                            <ProductsListSkuValue
                                onChange={(val) => mapSkuProduct(product.id, val)}
                                value={product.sku}
                            />
                        </td>

                        <td>
                            <RowAction id={product.id} archived={product.archived}/>
                        </td>
                    </tr>
                    {viewId === product.id &&
                    <Fragment>
                        <tr>
                            <td>Quantity</td>

                            <td>
                                <ProductsListQuantityValue
                                    onChange={(val) => mapQuantityProduct(product.id, val, 0)}
                                    value={product.quantity}
                                />
                            </td>

                            <td>
                                <ProductsListQuantityValue
                                    onChange={(val) => mapQuantityProduct(product.id, val, 2)}
                                    value={product.quantity2}
                                />
                            </td>

                            <td>
                                <ProductsListQuantityValue
                                    onChange={(val) => mapQuantityProduct(product.id, val, 3)}
                                    value={product.quantity3}
                                />
                            </td>
                            <td colSpan={3}/>
                        </tr>

                        {settingsShipbob && settingsShipbob.activated &&
                        <tr>
                            <td>Shipbob</td>
                            <td>
                                <ShipbobProductsSelectComponent products={settingsShipbob.products}
                                                                value={product.shipbobId}
                                                                onChange={(productId: string) => {
                                                                    mapShipbobProduct(product.id, productId, 0);
                                                                }}/>

                                {/*<input value={product.shipbobId} className={"form-control"}/>*/}
                            </td>

                            <td>
                                <ShipbobProductsSelectComponent products={settingsShipbob.products}
                                                                value={product.shipbobId2}
                                                                onChange={(productId: string) => {
                                                                    mapShipbobProduct(product.id, productId, 2);
                                                                }}/>

                                {/*<input value={product.shipbobId} className={"form-control"}/>*/}
                            </td>

                            <td>
                                <ShipbobProductsSelectComponent products={settingsShipbob.products}
                                                                value={product.shipbobId3}
                                                                onChange={(productId: string) => {
                                                                    mapShipbobProduct(product.id, productId, 3);
                                                                }}/>

                                {/*<input value={product.shipbobId} className={"form-control"}/>*/}
                            </td>
                            <td colSpan={3}/>

                        </tr>
                        }
                        {settingsShipica && settingsShipica.activated &&
                        <tr>
                            <td>Shipica</td>
                            <td>
                                <ShipicaProductsSelectComponent onlyActive={true} value={product.shipicaId}
                                                                onChange={(productId: Option) => {
                                                                    mapShipicaProduct(product.id, parseInt(productId.value, 10), 0);
                                                                }}/>

                                {/*<input value={product.shipbobId} className={"form-control"}/>*/}
                            </td>
                            <td>
                                <ShipicaProductsSelectComponent onlyActive={true} value={product.shipicaId2}
                                                                onChange={(productId: Option) => {
                                                                    mapShipicaProduct(product.id, parseInt(productId.value, 10), 2);
                                                                }}/>

                                {/*<input value={product.shipbobId} className={"form-control"}/>*/}
                            </td>
                            <td>
                                <ShipicaProductsSelectComponent onlyActive={true} value={product.shipicaId3}
                                                                onChange={(productId: Option) => {
                                                                    mapShipicaProduct(product.id, parseInt(productId.value, 10), 3);
                                                                }}/>

                                {/*<input value={product.shipbobId} className={"form-control"}/>*/}
                            </td>
                            <td colSpan={3}/>
                        </tr>
                        }
                        {settingsLctlogistic && settingsLctlogistic.activated &&
                        <tr>
                            <td>Lctlogistic</td>
                            <td>
                                <LctlogisticProductsSelectComponent products={settingsLctlogistic.products}
                                                                    value={product.lctlogisticId}
                                                                    onChange={(productId: string) => {
                                                                        mapLctlogisticProduct(product.id, productId, 0);
                                                                    }}/>
                            </td>
                            <td>
                                <LctlogisticProductsSelectComponent products={settingsLctlogistic.products}
                                                                    value={product.lctlogisticId2}
                                                                    onChange={(productId: string) => {
                                                                        mapLctlogisticProduct(product.id, productId, 2);
                                                                    }}/>
                            </td>
                            <td>
                                <LctlogisticProductsSelectComponent products={settingsLctlogistic.products}
                                                                    value={product.lctlogisticId3}
                                                                    onChange={(productId: string) => {
                                                                        mapLctlogisticProduct(product.id, productId, 3);
                                                                    }}/>
                            </td>
                            <td colSpan={3}/>
                        </tr>
                        }
                    </Fragment>
                    }
                </Fragment>
            );
        })}

        </tbody>
    </table>)
}

import React from "react";

interface Props {
    children: any;
    centerX?: boolean;
    mt?: number;
}

export default function FullSizeRow({children, centerX, mt}: Props) {

    let rowClassName = ["row"];
    if (mt) {
        rowClassName.push("mt-" + mt);
    }

    let colClassName = ["col"];
    if (centerX) {
        colClassName.push("text-center");
    }

    return (
        <div className={rowClassName.join(" ")}>
            <div className={colClassName.join(" ")}>
                {children}
            </div>
        </div>
    )
}

import React, {Fragment, useContext, useEffect, useState} from "react";
import {useHistory, useLocation, useParams} from "react-router-dom";


interface ProviderValue {
    page: number;
    setPage: (val: number) => void;
    archiveMode: number;
    setArchiveMode: (val: number) => void;
    searchString: string;
    setSearchString: (val: string) => void;

    activeStatus: string;
    setActiveStatus: (val: string) => void;

    activeElementId: string;
    setActiveElementId: (val: string) => void;

    nonce: string;
    pageId: number;
}

export const ListProviderContext = React.createContext<ProviderValue>({
    page: 1,
    setPage: (val: number) => {
    },
    archiveMode: 0,
    setArchiveMode: (val: number) => {
    },
    searchString: '',
    setSearchString: (val: string) => {
    },

    activeStatus: 'new',
    setActiveStatus: (val: string) => {
    },

    activeElementId: '',
    setActiveElementId: (val: string) => {
    },

    nonce: '',
    pageId: -1,
});

export const useListProvider = () => useContext(ListProviderContext);

interface Props {
    children: any;
}

export const ListProvider = ({children}: Props) => {
    const history = useHistory();
    const location = useLocation();
    const [queryParams, setQueryParams] = useState<any>({});
    const [loaded, setLoaded] = useState(false);

    const setQueryParam = (change: any) => {
        const params = {...queryParams, ...change};
        const query = location.pathname + '?' + Object.keys(params).map(key => key + '=' + params[key]).join('&')
        history.push(query);
    }

    useEffect(() => {
        const appendPageId = () => {
            if (location.pathname.length > 0) {
                const pathArray = location.pathname.split('/');
                const lastPathEl = pathArray[pathArray.length - 1];
                return parseInt(lastPathEl, 10);
            }
            return -1;
        }
        if (location.search.length > 0) {
            const q = {...Object.fromEntries(new URLSearchParams(location.search)), pageId: appendPageId()};
            setQueryParams(q);
        } else {
            const q = {pageId: appendPageId()};
            setQueryParams(q);
        }
        setLoaded(true);

    }, [location.search, location.pathname]);

    const setPage = (page: number) => {
        setQueryParam({page, elementId: ''});
    }
    const setArchiveMode = (archiveMode: number) => {
        setQueryParam({archiveMode, elementId: ''});
    }
    const setActiveElementId = (activeElementId: string) => {
        setQueryParam({elementId: activeElementId});
    }

    const setActiveStatus = (status: string) => {
        if (status !== queryParams.status) {
            setQueryParam({status, page: 1, elementId: ''});
        } else {
            setQueryParam({status, elementId: ''});
        }
    }
    const setSearchString = (search: string) => {
        if (search !== queryParams.search) {
            setQueryParam({search, page: 1, elementId: ''});
        } else {
            setQueryParam({search, elementId: ''});
        }
    }

    const page = !!queryParams.page ? parseInt(queryParams.page, 10) : 1;
    const archiveMode = !!queryParams.archiveMode ? parseInt(queryParams.archiveMode, 10) : 0;
    const searchString = !!queryParams.search ? queryParams.search : '';
    const activeStatus = !!queryParams.status ? queryParams.status : 'new';
    const activeElementId = !!queryParams.elementId ? queryParams.elementId : '';
    const nonce = !!queryParams.nonce ? queryParams.nonce : '';
    const pageId = !!queryParams.pageId ? queryParams.pageId : -1;

    if (!loaded) {
        return <Fragment/>
    }

    return (
        <ListProviderContext.Provider value={{
            page,
            setPage,
            archiveMode,
            setArchiveMode,
            searchString,
            setSearchString,
            activeStatus,
            setActiveStatus,

            activeElementId,
            setActiveElementId,

            nonce,
            pageId,
        }}>
            {children}
        </ListProviderContext.Provider>
    )
};

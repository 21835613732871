// @ts-nocheck
import React, {Fragment, useEffect, useState} from "react";
import {Modal, ModalBody, ModalHeader} from 'reactstrap';

import {Order, ProductAddOption} from "../../../../../typings/Dropton";
import SaveRow from "../../../FunnelSettings/Components/SaveRow";
import {useDispatch} from "react-redux";
import {runAddManualProductsOrder} from "../../../../../store/sagas/OrdersSaga";
import ProductsForm from "../../Forms/ProductsForm";
import {LctlogisticSettings, ShipbobSettings, ShipicaSettings} from "../../../../../typings/Settings";
import ApiFunnels from "../../../../../services/ApiFunnels";
import ShipbobProductsForm from "../../Forms/ShipbobProductsForm";
import ShipicaProductsForm from "../../Forms/ShipicaProductsForm";
import LctlogisticProductsForm from "../../Forms/LctlogisticProductsForm";

interface Props {
    isOpen: boolean;
    toggle: () => void;
    order: Order
}

export default function OrderProductAddModal({isOpen, toggle, order}: Props) {
    const dispatch = useDispatch();
    const [settingsShipbob, setSettingsShipbob] = useState<ShipbobSettings>();
    const [settingsShipica, setSettingsShipica] = useState<ShipicaSettings>();
    const [settingsLctlogistic, setSettingsLctlogistic] = useState<LctlogisticSettings>();

    const getData = () => {
        dispatch({type: "FUNNEL_PRODUCTS_FETCH_REQUESTED", id: order.funnelId})
        dispatch({type: "FUNNEL_SHIPICA_PRODUCTS_FETCH_REQUESTED", id: order.funnelId})
    };

    useEffect(() => {
        if (order.funnelId) {
            getData();
            ApiFunnels.getFunnelShipbobSettings(order.funnelId).then(res => {
                if (res.success === 1) {
                    setSettingsShipbob(res.data);
                }
            })
            ApiFunnels.getFunnelShipicaSettings(order.funnelId).then(res => {
                if (res.success === 1) {
                    setSettingsShipica(res.data);
                }
            })
            ApiFunnels.getFunnelLctlogisticSettings(order.funnelId).then(res => {
                if (res.success === 1) {
                    setSettingsLctlogistic(res.data);
                }
            })
        }
    }, []);


    const [isDataChange, setIsDataChange] = useState(false);
    const [products, setProducts] = useState<ProductAddOption[]>([]);
    const [shipicaProducts, setShipicaProducts] = useState<ProductAddOption[]>([]);
    const [shipbobProducts, setShipbobProducts] = useState<ProductAddOption[]>([]);
    const [lctlogisticProducts, setLctlogisticProducts] = useState<ProductAddOption[]>([]);

    const saveData = (e) => {
        e.preventDefault();
        dispatch(
            runAddManualProductsOrder(
                order.id,
                products.map(item => item.value),
                shipbobProducts.map(item => item.value),
                shipicaProducts.map(item => item.value),
                lctlogisticProducts.map(item => item.value)
            )
        );
        setProducts([]);
        setShipicaProducts([]);
        setShipbobProducts([]);
        toggle();
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Add products</ModalHeader>
            <ModalBody>
                <form>
                    <strong>Aliexpress products</strong>
                    <ProductsForm products={products}
                                  setIsDataChange={setIsDataChange}
                                  setProducts={setProducts}
                                  funnelId={order.funnelId}/>

                    {settingsShipbob && settingsShipbob.activated &&
                    <Fragment>
                        <strong>Shipbob products</strong>

                        <ShipbobProductsForm products={shipbobProducts}
                                             setIsDataChange={setIsDataChange}
                                             setProducts={setShipbobProducts}
                                             sourceProducts={settingsShipbob.products}/>
                    </Fragment>}

                    {settingsShipica && settingsShipica.activated &&
                    <Fragment>
                        <strong>Shipica products</strong>

                        <ShipicaProductsForm products={shipicaProducts}
                                             setIsDataChange={setIsDataChange}
                                             setProducts={setShipicaProducts}
                                             funnelId={order.funnelId}
                        />
                    </Fragment>}

                    {settingsLctlogistic && settingsLctlogistic.activated &&
                    <Fragment>
                        <strong>Lctlogistic products</strong>

                        <LctlogisticProductsForm products={lctlogisticProducts}
                                                 setIsDataChange={setIsDataChange}
                                                 setProducts={setLctlogisticProducts}
                                                 sourceProducts={settingsLctlogistic.products}/>
                    </Fragment>}

                    <SaveRow isDataChange={isDataChange} saveData={saveData}/>
                </form>
            </ModalBody>
        </Modal>
    )
}

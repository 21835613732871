import React, {Fragment, useState} from "react";
import {Order} from "../../../../../typings/Dropton";
import OrderEditModal from "../OrderEditModal";
import ContactEditModal from "../ContactEditModal";
import TargetProducts from "./TargetProducts";
import SourceProducts from "./SourceProducts";
import ContactDetailsCard from "./ContactDetailsCard";
import OrderDetailsCard from "./OrderDetailsCard";
import OrderProductAddModal from "../ProductsAdd/OrderProductAddModal";
import TrackingMailCard from "./TrackingMailCard";
import PlaceOrderButton from "./PlaceOrderButton";
import {
    EmailNotificationsSettings,
    LctlogisticSettings,
    ShipbobSettings,
    ShipicaSettings
} from "../../../../../typings/Settings";
import ShipbobCard from "./ShipbobCard";
import {useAuthNewAgeErp} from "../../../../../Providers/AuthNewAgeErpProvider";
import ShipicaCard from "./ShipicaCard";
import LctlogisticCard from "./LctlogisticCard";

interface Props {
    order: Order;
    shipbobSettings?: ShipbobSettings,
    mailSettings?: EmailNotificationsSettings,
    shipicaSettings?: ShipicaSettings,
    lctlogisticSettings?: LctlogisticSettings,
}

export default function OrderCard({order, shipbobSettings, mailSettings, shipicaSettings, lctlogisticSettings}: Props) {
    const [orderEditMode, setOrderEditMode] = useState(false);
    const [contactEditMode, setContactEditMode] = useState(false);
    const [productAddMode, setProductAddMode] = useState(false);
    const {isAuthenticated} = useAuthNewAgeErp();
    // const [order, setOrder] = useState<Order>({
    //     externalId: 'test',
    //     ajCreationTime: '20002',
    //     statusName: 'Status',
    //     aliexpressId: 'aliexressId',
    //     trackingId: 'trackingId',
    //     contact: {
    //         address: 'address',
    //         city: 'city',
    //         country: 'country',
    //         zip: 'zip',
    //         email: 'email',
    //         name: 'name',
    //         phone: 'phone',
    //         state: 'state'
    //     }
    // });

    if (!order) {
        return <Fragment/>;
    }
    //
    // const noop = () => {
    //     setOrder({
    //         externalId: 'test',
    //         ajCreationTime: '20002',
    //         statusName: 'Status',
    //         aliexpressId: 'aliexressId',
    //         trackingId: 'trackingId',
    //         contact: {
    //             address: 'address',
    //             city: 'city',
    //             country: 'country',
    //             zip: 'zip',
    //             email: 'email',
    //             name: 'name',
    //             phone: 'phone',
    //             state: 'state'
    //         }
    //     });
    // };

    return (
        <Fragment>
            <div className="card-body pt-0" style={{overflow: "auto", maxHeight: '90vh'}}>
                <div className="pt-2">
                    <div className="row no-gutters">
                        <div className="col">
                            <strong style={{fontSize: 18}}># {order.externalId} </strong>
                            <div className="pl-2"><span className={"txt-gray"}>{order.ajCreationTimeTimezone}</span></div>
                        </div>
                        <div className="col-auto">
                            <PlaceOrderButton order={order}/>
                        </div>
                    </div>
                    <OrderDetailsCard order={order} setOrderEditMode={setOrderEditMode}/>
                    <ContactDetailsCard order={order} setContactEditMode={setContactEditMode}/>
                    <SourceProducts order={order}/>

                    {isAuthenticated &&
                    <TargetProducts order={order} onAddProduct={() => setProductAddMode(true)}/>
                    }

                    {mailSettings && mailSettings.activated && isAuthenticated && <TrackingMailCard order={order}/>}
                    {shipbobSettings && shipbobSettings.activated && isAuthenticated && <ShipbobCard order={order}/>}
                    {shipicaSettings && shipicaSettings.activated && isAuthenticated && <ShipicaCard order={order}/>}
                    {lctlogisticSettings && lctlogisticSettings.activated && isAuthenticated && <LctlogisticCard order={order}/>}
                </div>
            </div>
            {order && <Fragment>
                <OrderProductAddModal isOpen={productAddMode} toggle={() => setProductAddMode(!productAddMode)}
                                      order={order}/>
                <OrderEditModal isOpen={orderEditMode} toggle={() => setOrderEditMode(!orderEditMode)} order={order}/>
                <ContactEditModal isOpen={contactEditMode} toggle={() => setContactEditMode(!contactEditMode)}
                                  contact={order.contact} order={order}/>
            </Fragment>}

        </Fragment>
    )
}

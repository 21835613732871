import React from "react";
import StepContactText from "./StepContactText";
import StepTitle from "./StepTitle";
import StepText from "./StepText";
import {StepWizardChildProps} from "react-step-wizard";
import StepNavigation from "./StepNavigation";

interface Props extends StepWizardChildProps {
    onNext: () => void;
}

export default function Step7(props: Props) {
    const texts = {
        title: "Integration done!"
    };
    return (
        <div className="get-started" data-step="7">
            <StepTitle title={texts.title}/>
            <p className="text-center">
                You have successfully integrated funnel with Dropton system. Now you are ready to
                start your work.
            </p>

            <StepNavigation {...props} nextStep={props.onNext}/>

            <StepText currentStep={props.currentStep} allSteps={props.totalSteps} text={texts.title}/>
            <StepContactText/>
        </div>
    )
}
const DroptonAssets = {
    getStarted: {
        1: require("./get-started/1.png"),
        2: require("./get-started/2.png"),
        3: require("./get-started/3.png"),
        4: require("./get-started/4.png"),
        5: require("./get-started/5.png"),
        6: require("./get-started/6.png")
    },
    logo: require("./logo.png"),
    loginLogo: require("./loginLogo.png")
};
export default DroptonAssets;

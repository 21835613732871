// @ts-nocheck
import React, {Fragment} from "react";
import {ProductAddOption} from "../../../../typings/Dropton";
import {LctlogisticProduct} from "../../../../typings/Settings";
import LctlogisticProductsSelectComponent
    from "../../FunnelSettings/Plugins/Lctlogistic/LctlogisticProductsSelectComponent";

interface Props {
    products: ProductAddOption[];
    setProducts: (val: ProductAddOption[]) => void;
    setIsDataChange: (val: boolean) => void;
    sourceProducts: LctlogisticProduct[],
}

export default function LctlogisticProductsForm({products, setProducts, setIsDataChange, sourceProducts}: Props) {

    const addProduct = (item?: ProductAddOption) => {
        if (item && item.value > 0) {
            setProducts([...products, item]);
            setIsDataChange(true);
        }
    };
    const removeProduct = (index: number) => {
        setProducts([...products.filter((item: ProductAddOption, i: number) => i !== index)]);
        setIsDataChange(true);
    };

    return (
        <Fragment>
            {products.map((item, index: number) => {
                return (
                    <div className="row p-2" key={"product-" + item.value + "-" + index}>
                        <div className="col">{item.label}</div>

                        <div className="col-auto">
                            <a href={"#"} onClick={e => {
                                e.preventDefault();
                                removeProduct(index);
                            }}>X Remove product</a>
                        </div>

                    </div>
                )
            })}
            <div className={"row mt-2 mb-2"}>
                <div className={"col-12"}>
                    <LctlogisticProductsSelectComponent products={sourceProducts} value={-1}
                                                        onChange={(productId: string) => {
                                                            const p = sourceProducts.filter((i: LctlogisticProduct) => i.GoodsID.toString() === productId)
                                                            addProduct({
                                                                label: p[0].EnName,
                                                                value: p[0].GoodsID.toString()
                                                            })
                                                        }}/>
                </div>
            </div>
        </Fragment>
    )
}

import React, {Fragment, useEffect, useState} from "react";
import {Collapse} from "reactstrap";
import {SettingsStepProps} from "./types";
import CardHeaderCollapse from "../../../page/card/CardHeaderCollapse";
import SaveRow from "../Components/SaveRow";
import Api from "../../../../services/ApiFunnels";
import {toast} from "react-toastify";
import SettingsAfterTitle from "../Components/SettingsAfterTitle";
import ActivatedCheckbox from "../Components/ActivatedCheckbox";
import ShippingsComponent from "../Components/ShippingsComponent";

interface Settings {
    activated: boolean;
    shipping1: string;
    shipping2: string;
    shipping3: string;
}

const texts = {
    title: "Aliexpress shipping options"
};

export default function FunnelSettingsShipping({index, opened, onCollapse, id}: SettingsStepProps) {
    const isOpened = index === opened;
    const [isDataChange, setIsDataChange] = useState(false);
    const [settings, setSettings] = useState<Settings>();

    const getData = () => {
        Api.getFunnelAliexpressSettings(id).then(res => {
            if (res.success === 1) {
                setSettings(res.data);
            }
        })
    };

    const saveData = () => {
        Api.setFunnelAliexpressSettings(settings, id).then(res => {
            if (res.success === 1) {
                setIsDataChange(false);
                toast.success("Settings saved.");
            } else {
                toast.error("Error!");
            }
        })
    };

    useEffect(() => {
        getData();
    }, []);

    const afterTitle = settings ?
        <SettingsAfterTitle activated={settings.activated} isDataChange={isDataChange}/> : null;

    const onActivatedChange = (val: boolean) => {
        if (settings) {
            let f = {...settings};
            f.activated = val;
            setSettings(f);
            setIsDataChange(true);
        }
    };

    const onShipping1Change = (val: string) => {
        if (settings) {
            let f = {...settings};
            f.shipping1 = val;
            setSettings(f);
            setIsDataChange(true);
        }
    };

    const onShipping2Change = (val: string) => {
        if (settings) {
            let f = {...settings};
            f.shipping2 = val;
            setSettings(f);
            setIsDataChange(true);
        }
    };

    const onShipping3Change = (val: string) => {
        if (settings) {
            let f = {...settings};
            f.shipping3 = val;
            setSettings(f);
            setIsDataChange(true);
        }
    };

    return (
        <Fragment>
            <div className={"card"}>
                <CardHeaderCollapse title={texts.title} onCollapse={() => onCollapse(isOpened ? -1 : index)}
                                    isOpened={isOpened}
                                    afterTitle={afterTitle}/>
                <Collapse isOpen={opened === index}>
                    <div className="card-body">
                        {settings && <Fragment>

                            <div className="row">
                                <div className="col-6">

                                    <ActivatedCheckbox activated={settings.activated}
                                                       onActivatedChange={onActivatedChange}
                                                       inputKey={"shipping"}
                                    />

                                    <div className="form-group">
                                        <label htmlFor="shipping_1">Default shipping method</label>
                                        <ShippingsComponent
                                            value={settings.shipping1}
                                            onChange={onShipping1Change}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="shipping_2">Secondary shipping method</label>
                                        <ShippingsComponent
                                            value={settings.shipping2}
                                            onChange={onShipping2Change}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="shipping_3">Tertiary shipping method</label>
                                        <ShippingsComponent
                                            value={settings.shipping3}
                                            onChange={onShipping3Change}
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <p className="text-grey pt-12">
                                        When the Default shipping method is selected, Dropton will select it
                                        for all products in the funnel.<br/>
                                        If a default shipping method is not available, Dropton will select the
                                        secondary method.
                                        <br/>
                                        If secondary method is not available, Dropton will select tertiary
                                        method.
                                        <br/>
                                        If all of preferred shipping methods are not available, Dropton will
                                        select the cheapest method.</p>
                                </div>
                            </div>

                            <SaveRow isDataChange={isDataChange} saveData={saveData}/>

                        </Fragment>
                        }
                    </div>
                </Collapse>
            </div>
        </Fragment>
    )
}
import React from 'react'
import logo from '../../../../assets/images/logo.png';
import logo_compact from '../../../../assets/images/logo.png';
import { Link } from 'react-router-dom';

const SidebarLogoWrapper = () => {
    return (
        <div className="main-header-left d-none d-lg-block">
            <div className="logo-wrapper compactLogo">
                <Link to="/dashboard/default">
                    <img className="blur-up lazyloaded" src={logo_compact} width={200} alt="" />
                    <img className="blur-up lazyloaded" src={logo} alt="" width={200} />
                </Link>
            </div>
        </div>
    )
}

export default SidebarLogoWrapper

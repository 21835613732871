export const ordersStatuses = [
    {
        key: 'new',
        title: 'New',
    },
    {
        key: 'fulfilled',
        title: 'Fulfilled',
    },
    {
        key: 'shipped',
        title: 'Shipped',
    },
    {
        key: 'shipped_paypal',
        title: 'Waiting PayPal tracking',
    },
    {
        key: 'refunded',
        title: 'Refunded',
    },
    {
        key: 'disputes',
        title: 'Disputes',
    },
    {
        key: 'archive',
        title: 'Archive',
    },
    {
        key: 'all',
        title: 'All',
    },
];

import React, {ChangeEvent, useEffect, useState} from "react";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import PrimaryButton from "../../../../navigation/components/PrimaryButton";
import {useDispatch} from "react-redux";
import ApiFunnels from "../../../../../services/ApiFunnels";


interface Props {
    isOpen: boolean;
    toggle: () => void;
    id: number;
}


export default function NewProductModal({isOpen, toggle, id}: Props) {
    const dispatch = useDispatch();

    const [canSave, setCanSave] = useState(false);

    const [name, setName] = useState('');
    const [sku, setSku] = useState('');
    const [barcode, setBarcode] = useState('');
    const [referenceId, setReferenceId] = useState('');


    const resetState = () => {
        setName('');
        setSku('');
        setBarcode('');
        setReferenceId('');
        toggle();
    };


    useEffect(() => {
        let isError = false;
        if (name.length < 0) {
            isError = true;
        }
        if (referenceId.length < 0) {
            isError = true;
        }

        setCanSave(!isError);
    }, [name, sku, barcode, referenceId]);


    const createProduct = () => {
        ApiFunnels.createShipbobProduct(id, name, referenceId, barcode, sku).then(() => {
            toggle();
        });
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle}>New product</ModalHeader>
            <ModalBody>
                <div className="form-group">
                    <label htmlFor="name">Title *</label>
                    <input type="text" className="form-control"
                           value={name}
                           onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}/>

                </div>

                <div className="form-group">
                    <label htmlFor="name">Reference ID *</label>
                    <input type="text" className="form-control"
                           value={referenceId}
                           onChange={(e: ChangeEvent<HTMLInputElement>) => setReferenceId(e.target.value)}/>

                </div>

                <div className="form-group">
                    <label htmlFor="name">SKU</label>
                    <input type="text" className="form-control"
                           value={sku}
                           onChange={(e: ChangeEvent<HTMLInputElement>) => setSku(e.target.value)}/>

                </div>

                <div className="form-group">
                    <label htmlFor="name">Barcode</label>
                    <input type="text" className="form-control"
                           value={barcode}
                           onChange={(e: ChangeEvent<HTMLInputElement>) => setBarcode(e.target.value)}/>

                </div>

            </ModalBody>
            <ModalFooter>
                <PrimaryButton title={"Save"} disabled={!canSave} onClick={() => {
                    createProduct();
                }}/>
            </ModalFooter>
        </Modal>
    )
}

import {Api} from "./Api";
import {BackendConfig} from "../config/BackendConfig";

class ApiPublicUser extends Api {
    login = (email: string, password: string) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({email, password})
        };

        return fetch(BackendConfig.backendUrl + '/app/public/user/login', requestOptions).then(res => res.json());
    }

    register = (email: string, password: string, passwordR: string) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({email, password, passwordR})
        };

        return fetch(BackendConfig.backendUrl + '/app/public/user/register', requestOptions).then(res => res.json());
    }
}
export default new ApiPublicUser();

import React, {Fragment, useEffect} from "react";
import {runFetchOrders} from "../../../../store/sagas/OrdersSaga";
import {useDispatch, useSelector} from "react-redux";
import {Order} from "../../../../typings/Dropton";
import {useListProvider} from "../../../../Providers/ListProvider";
import NoResultsSearch from "./NoResultsSearch";
import OrderFooter from "../Order/Footer/OrderFooter";
import LoadingComponent from "../../../common/LoadingComponent";
import Table from "../../../shared/table/Table";
import Thead from "../../../shared/table/Thead";

interface Props {
    setOrder: (val?: Order) => void;
    activeStatus: string;
}

export default function OrdersList({setOrder, activeStatus}: Props) {

    const {
        page, searchString, activeElementId, setActiveElementId, nonce, pageId,
    } = useListProvider();
    // const [activeIndex, setActiveIndex] = useState(0);
    const dispatch = useDispatch();
    const orders = useSelector((state: any) => state.Orders);
    // console.log('id', id);

    // USING FOR RELOAD ON PAGING AND ORDERING CHANGE
    const paging = useSelector((state: any) => state.Settings.paging);
    const ordering = useSelector((state: any) => state.Settings.ordering);

    // LOADING
    const loading = useSelector((state: any) => state.Loading.orders);

    const getData = () => {
        console.log('runFetchOrders', pageId, page, activeStatus, searchString);
        dispatch(runFetchOrders(pageId, page, activeStatus, searchString));
    };

    useEffect(() => {
        console.log('page changed')
    }, [page]);
    useEffect(() => {
        console.log('searchString changed')
    }, [searchString]);
    useEffect(() => {
        console.log('id changed')
    }, [pageId]);
    useEffect(() => {
        console.log('activeStatus changed')
    }, [activeStatus]);
    useEffect(() => {
        console.log('paging changed')
    }, [paging]);
    useEffect(() => {
        console.log('ordering changed')
    }, [ordering]);
    useEffect(() => {
        console.log('nonce changed')
    }, [nonce]);


    useEffect(getData, [page, searchString, pageId, activeStatus, paging, ordering, nonce]);

    useEffect(() => {
        if (orders.length > 0) {
            const fOrders = orders.filter((item: Order) => item.externalId === activeElementId);
            if (fOrders.length > 0) {
                setOrder(fOrders[0]);
            } else {
                setActiveElementId(orders[0].externalId);
            }
        } else {
            setOrder(undefined);
            // setActiveElementId('');
        }
    }, [orders, activeElementId]);

    if (loading === 'loading') {
        return <LoadingComponent/>
    }

    const columns = ["Order ID", "Order date", "Customer", "Quantity", "Total amount"];
    const content =
        (orders.length === 0 ? <NoResultsSearch/> :
                (<div className="pb-2 pt-4">
                    <Table>
                        <Thead columns={columns}/>
                        <tbody>
                        {orders.map((item: Order, index: number) => {

                            const errorBadge = () => {
                                const isFieldError = (field: string) => {
                                    // @ts-ignore
                                    return item.contact.errors[field] && item.contact.errors[field].length > 0;
                                }
                                if (item.products.filter(i => i.map === 0).length > 0) {
                                    return (<Fragment>
                                        <br/><span
                                        className="badge badge-pill badge-danger badge-outline">UNMAPPED</span>
                                    </Fragment>);
                                } else if (isFieldError('country')) {
                                    return <Fragment><br/><span
                                        className="badge badge-pill badge-light">WRONG COUNTRY</span></Fragment>
                                } else if (isFieldError('phone')) {
                                    return <Fragment><br/><span
                                        className="badge badge-pill badge-light">WRONG PHONE</span></Fragment>
                                } else if (isFieldError('state')) {
                                    return <Fragment><br/><span
                                        className="badge badge-pill badge-light">WRONG STATE</span></Fragment>
                                } else if (isFieldError('address')) {
                                    return <Fragment><br/><span
                                        className="badge badge-pill badge-light">WRONG ADDRESS</span></Fragment>
                                } else if (isFieldError('zip')) {
                                    return <Fragment><br/><span
                                        className="badge badge-pill badge-light">WRONG ZIP</span></Fragment>
                                }
                                return null;
                            };
                            return (
                                <tr key={"order-" + item.externalId + "-" + item.id} style={{cursor: 'pointer'}}
                                    onClick={() => setActiveElementId(item.externalId)}
                                    className={activeElementId === item.externalId ? "active-order" : ""}>
                                    <th scope={"row"}># {item.externalId}
                                        {item.archived && (<Fragment>
                                            <br/><span
                                            className="badge badge-pill badge-danger badge-outline">ARCHIVED</span>
                                        </Fragment>)
                                        }
                                        {errorBadge()}
                                    </th>
                                    <td>{item.ajCreationTimeTimezone}</td>
                                    <td>{item.contact ? item.contact.name : 'N/A'}</td>
                                    <td>{item.productsCount} unit</td>
                                    <td>{item.productsAmount.toFixed(2)} {item.currency}</td>
                                </tr>
                            )
                        })}

                        </tbody>
                    </Table>
                </div>)
        )

    return (
        <Fragment>
            {content}
            <OrderFooter/>
        </Fragment>
    )
}

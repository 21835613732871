import React from "react";
import Select from 'react-select'
import {ShipbobProduct} from "../../../../../typings/Shipbob";

interface Props {
    value: string;
    onChange: (value: string) => void;
    onlyActive?: boolean;
    products: ShipbobProduct[],
}

interface Option {
    value: string;
    label: string;
}

export default function ShipbobProductsSelectComponent({value, onChange, products}: Props) {

    const options = [
        {
            value: '',
            label: 'Unmapped'
        },
        ...products.map((item: ShipbobProduct) => {
            return {
                value: item.id.toString(),
                label: item.name + ' (' + item.sku + ')'
            }
        })];
    const selected = options.filter((item: Option) => item.value === value);
    const defValue = selected.length > 0 ? selected[0] : options[0];

    return <Select options={options} value={defValue}
                   onChange={(item: any) => onChange(item && item.value ? item.value : '')}/>;
}

import React, {Fragment} from "react";
import FullSizeRow from "../../../shared/grid/FullSizeRow";

export default function NoResultsSearch() {
    return (
        <Fragment>
            <div className={"mt-8 mb-8"}>
                <FullSizeRow centerX={true}>
                    <i className="fa fa-search" style={{fontSize: 90}}></i>
                </FullSizeRow>
                <FullSizeRow centerX={true} mt={4}>
                    <h3>No results found</h3>
                </FullSizeRow>
                <FullSizeRow centerX={true}>
                    <h5>No results match the search criteria</h5>
                </FullSizeRow>
                {/*{% if search %}*/}
                {/*<div class="row">*/}
                {/*    <div class="col text-center">*/}
                {/*        <a href="/app/funnels/view/{{ funnel.id }}?status={{ status }}">clear filters</a>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*{% endif %}*/}
            </div>
        </Fragment>
    )
}

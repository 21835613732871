import React, {useState} from "react";

interface Props {
    value: number,
    onChange: (val: number) => void,
}

export default function ProductsListQuantityValue(props: Props) {
    const [value, setValue] = useState(props.value);
    return (
        <input
            value={value}
            className={"form-control"}
            onChange={(e) => {
                setValue(parseInt(e.target.value, 10))
            }}
            onBlur={() => {
                props.onChange(value)
            }}
        />
    )
}
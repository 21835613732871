import React, {Fragment, useContext, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import ApiFunnels from "../services/ApiFunnels";
import ApiContacts from "../services/ApiContacts";
import ApiOrders from "../services/ApiOrders";
import ApiPlaceOrder from "../services/ApiPlaceOrder";
import ApiUser from "../services/ApiUser";
import PaymentsModal from "../components/Payments/PaymentsModal";


interface Props {
    children: any
}

interface ProviderValue {
    isAuthenticated: boolean
}

export const AuthNewAgeErpContext = React.createContext<ProviderValue>({
    isAuthenticated: false
});
export const useAuthNewAgeErp = () => useContext(AuthNewAgeErpContext);
export const AuthNewAgeErpProvider = ({
                                          children
                                      }: Props) => {
    const token = useSelector((state: any) => state.Auth.token);
    const [isValidSubscription, setIsValidSubscription] = useState(true);

    const [isAuthenticated, setIsAuthenticated] = useState(!!token);
    if (token) {
        ApiFunnels.token = token;
        ApiOrders.token = token;
        ApiContacts.token = token;
        ApiPlaceOrder.token = token;
        ApiUser.token = token;
    }

    useEffect(() => {
        if (token) {
            ApiFunnels.token = token;
            ApiOrders.token = token;
            ApiContacts.token = token;
            ApiPlaceOrder.token = token;
            ApiUser.token = token;
        }
        setIsAuthenticated(!!token);
    }, [token]);

    useEffect(() => {
        if (token) {
            ApiUser.getInfo().then((res) => {
                if (res.success === 1) {
                    setIsValidSubscription(res.data.valid);
                } else {
                    setIsValidSubscription(false);
                }

            })
        }
    }, [token]);

    return (
        <AuthNewAgeErpContext.Provider
            value={{
                isAuthenticated,
            }}
        >
            <Fragment>
                {children}
                {isAuthenticated &&
                <PaymentsModal isValidSubscription={isValidSubscription}/>
                }
            </Fragment>

        </AuthNewAgeErpContext.Provider>
    );
};

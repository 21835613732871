import React, {Fragment, useEffect, useState} from "react";
import {Collapse} from "reactstrap";
import {GeneralSettingsStepProps, SettingsStepProps} from "./types";
import CardHeaderCollapse from "../../../page/card/CardHeaderCollapse";
import HowToIntegrateComponent from "../Components/HowToIntegrateComponent";
import Api from "../../../../services/ApiFunnels";
import WebhookUrl from "../Components/WebhookUrl";
import {BackendConfig} from "../../../../config/BackendConfig";
import {FunnelGeneralInfo} from "../../../../typings/Dropton";
import {toast} from "react-toastify";
import LoadingComponent from "../../../common/LoadingComponent";
import SaveRow from "../Components/SaveRow";
import FormGroupInput from "../../../shared/form/fields/FormGroupInput";
import FormGroupTextarea from "../../../shared/form/fields/FormGroupTextarea";

const texts = {
    title: "General"
};

export default function FunnelSettingsGeneral({index, opened, onCollapse, id, funnel, setFunnel}: GeneralSettingsStepProps) {
    const [isDataChange, setIsDataChange] = useState(false);
    const isOpened = index === opened;


    const saveData = () => {
        if (funnel) {
            Api.setFunnelGeneralInfo(funnel, id).then(res => {
                if (res.success === 1) {
                    setIsDataChange(false);
                    toast.success("Settings saved.");
                } else {
                    toast.error("Error!");
                }
            });
        }
    }

    const onNameChange = (val: string) => {
        if (funnel) {
            let f = {...funnel};
            f.name = val;
            setFunnel(f);
            setIsDataChange(true);
        }
    };

    const onLeaveMessageChange = (val: string) => {
        if (funnel) {
            let f = {...funnel};
            f.leaveMessage = val;
            setFunnel(f);
            setIsDataChange(true);
        }
    };

    const afterTitle = isDataChange ? <span className={"ml-4 badge badge-warning"}>Save your changes</span> : null;

    return (
        <Fragment>
            <div className={"card"}>
                <CardHeaderCollapse title={texts.title} onCollapse={() => onCollapse(isOpened ? -1 : index)}
                                    isOpened={isOpened}
                                    afterTitle={afterTitle}/>
                <Collapse isOpen={opened === index}>
                    <div className={"card-body"}>
                        <HowToIntegrateComponent/>
                        {funnel ?
                            <Fragment>
                                <FormGroupInput title={"Title"} value={funnel.name} onChange={onNameChange}/>

                                <WebhookUrl url={BackendConfig.hookUrl + '/' + funnel.url}/>

                                <FormGroupTextarea title={"Leave message"} value={funnel.leaveMessage}
                                                   onChange={onLeaveMessageChange}/>

                                <SaveRow isDataChange={isDataChange} saveData={saveData}/>

                            </Fragment>
                            :
                            <LoadingComponent/>
                        }
                    </div>
                </Collapse>
            </div>
        </Fragment>
    )
}

export class Api {
    private _token: string;

    set token(value: string) {

        this._token = value;
    }

    constructor() {
        this._token = '';
    }

    defaultRequestOptions = () => {
        return {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this._token
            },
        }
    };
}
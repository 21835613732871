import React from 'react'
import ToolbarInlineTitle from "../../../../page/toolbar/ToolbarInlineTitle";
import ToolbarSearch from "../../../../page/toolbar/ToolbarSearch";

import ListArchivedDropdown from "../../../../page/list/ListArchivedDropdown";

interface Props {
    title?: string;
    id: number;
}

export default function ListToolbar({title, id}: Props) {
    return (
        <div className="pb-2">

            <div className="row" style={{alignItems: 'center'}}>
                {title && <ToolbarInlineTitle title={title}/>}

                <div className={"col"}>
                </div>

                <ToolbarSearch/>

                <div className={"col-auto"}>
                    <ListArchivedDropdown/>
                </div>
            </div>
        </div>
    )
}

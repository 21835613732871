import React, {Fragment, useState} from "react";
import {Order} from "../../../../../typings/Dropton";
import Separator from "./Separator";
import ShowHideComponent from "./ShowHideComponent";
import {Link, useParams} from "react-router-dom";
import {useAuthNewAgeErp} from "../../../../../Providers/AuthNewAgeErpProvider";

interface Props {
    order: Order
}

export default function SourceProducts({order}: Props) {
    const [expand, setExpand] = useState(true);
    const {id} = useParams();
    const {isAuthenticated} = useAuthNewAgeErp();

    const errorForProducts = () => {
        if (order.products.filter(i => i.map === 0).length > 0) {
            return (<i className="icon icon-alert txt-red mr-2"
                       title={"UNMAPPED PRODUCT"}></i>);
        }
        return null;
    };

    return (
        <Fragment>
            <Separator/>

            <div className="row no-gutters">
                <div className="col">
                    <strong>Source products {!expand && errorForProducts()}</strong>
                </div>

                <div className="col-3 text-right">
                    {isAuthenticated &&
                    <Link to={'/ds/settings/' + id} target={"_blank"} className={"btn btn-link txt-secondary p-0"}><i
                        className="icon s-4 icon-pencil mr-1 txt-secondary"></i>Manage</Link>
                    }
                </div>

                <ShowHideComponent expand={expand} setExpand={setExpand}/>
            </div>
            {expand &&
            <Fragment>
                {order.products.map((item, index) => {
                    return (
                        <Fragment key={"source-product-" + index + "-" + item.id}>
                            <div className="row no-gutters mt-2">
                                <div className="col">
                                    {item.name}
                                </div>
                                <div className="col-auto text-grey">
                                    {order.currency} {item.amount.toFixed(2)} x {item.quantity}
                                </div>
                            </div>
                            {item.map === 0 &&
                            <div className="row no-gutters">
                                <div className="col">
                                    <span className="badge badge-pill badge-danger badge-outline">UNMAPPED</span>
                                </div>
                            </div>
                            }
                            {item.map === -1 &&
                            <div className="row no-gutters">
                                <div className="col">
                                    <span className="badge badge-pill badge-light badge-digital">DIGITAL</span>
                                </div>
                            </div>
                            }
                        </Fragment>
                    )
                })}
                <div className="row no-gutters mt-2">
                    <div className="col">

                    </div>
                    <div className="col-auto">
                        TOTAL: {order.currency} {order.productsAmount.toFixed(2)}
                    </div>
                </div>
            </Fragment>
            }
        </Fragment>
    )
}

import React from "react";

interface Props {
    children: any;
}

export default function Table({children}: Props) {
    return <table className="table table-striped table-hover no-table-th-border">
        {children}
    </table>
}

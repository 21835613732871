import React from 'react'

interface Props {
    title: string;
    onClick: () => void;
}

export default function LightButton({onClick, title}: Props) {
    return (
        <button className={"btn btn-light btn-pill btn-air-light"}
                onClick={onClick}>{title}</button>
    )
}

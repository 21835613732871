import React from 'react'
import { MenuItem } from '../../../../typings/Menu'

interface Props {
    menuItem: MenuItem;
    onPress: () => void;
}

export default function SidebarMenuItemSub({ menuItem, onPress }: Props) {
    return (
        <a className="sidebar-header" href="#javascript" onClick={onPress}>
            {menuItem.icon && <menuItem.icon />}
            <span>{menuItem.title}</span>
            <i className="fa fa-angle-right pull-right"></i>
        </a>
    )
}

import {Api} from "./Api";
import {BackendConfig} from "../config/BackendConfig";

class ApiPlaceOrder extends Api {
    placeAliexpress = (orderId: number) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: orderId})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/orders/process/aliexpress', requestOptions).then(res => res.json());
    }

    placeShipbob = (orderId: number) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: orderId})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/orders/process/shipbob', requestOptions).then(res => res.json());
    }

    placeShipica = (orderId: number) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: orderId})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/orders/process/shipica', requestOptions).then(res => res.json());
    }

    placeLctlogistic = (orderId: number) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: orderId})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/orders/process/lctlogistic', requestOptions).then(res => res.json());
    }

}
export default new ApiPlaceOrder();

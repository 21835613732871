import React from "react";
import DroptonAssets from "../../../../assets/images/dropton/DroptonAssets";
import StepContactText from "./StepContactText";
import StepText from "./StepText";
import StepTitle from "./StepTitle";
import {StepWizardChildProps} from "react-step-wizard";
import StepNavigation from "./StepNavigation";


interface Props extends StepWizardChildProps {
    isInstall: boolean;
}

export default function Step2(props: Props) {
    const texts = {
        title: props.isInstall ? "Connect first funnel" : "Connect funnel"
    };
    return (
        <div className="get-started" data-step="2">
            <StepTitle title={texts.title}/>
            <div className="text-center">
                <img src={DroptonAssets.getStarted["1"]} alt={texts.title}/>
            </div>
            <p className="text-center">Go to ClickFunnel account and select a Funnel that you want integrate with a
                Dropton system</p>

            <StepNavigation {...props}/>

            <StepText currentStep={props.currentStep} allSteps={props.totalSteps} text={texts.title}/>
            <StepContactText/>
        </div>
    )
}
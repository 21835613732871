import React from 'react'
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from 'reactstrap';
import {useListProvider} from "../../../Providers/ListProvider";

const texts = [
    "Active",
    "Archive",
    "All",
];
//
// interface Props {
//     archiveMode: number;
//     setArchiveMode: (archiveMode: number) => void;
// }

export default function ListArchivedDropdown() {
    const {archiveMode, setArchiveMode} = useListProvider();

    return (
        <UncontrolledDropdown>
            <DropdownToggle className="btn btn-light  btn-pill btn-sm ml-2">
                {texts[archiveMode]}
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem
                    onClick={() => setArchiveMode(0)}>{texts[0]}</DropdownItem>
                <DropdownItem
                    onClick={() => setArchiveMode(1)}>{texts[1]}</DropdownItem>
                <DropdownItem
                    onClick={() => setArchiveMode(2)}>{texts[2]}</DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    )
}

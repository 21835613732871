import React from 'react'
import { MenuItem } from '../../../../typings/Menu';

interface Props {
    menuItem: MenuItem;
}

export default function SidebarTitle({ menuItem }: Props) {
    return menuItem.sidebartitle ?
        <div className="sidebar-title">{menuItem.sidebartitle}</div>
        : null;
}

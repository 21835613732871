import React, {Fragment} from 'react'
import {MenuItem} from '../../../../typings/Menu'
import SidebarTitle from './SidebarTitle'
import SidebarMenuItemSub from './SidebarMenuItemSub'
import SidebarMenuItemLink from './SidebarMenuItemLink'
import SidebarMenuItemExternalLink from "./SidebarMenuItemExternalLink";
import SidebarMenuItemFunnelsSub from "./SidebarMenuItemFunnelsSub";

interface Props {
    menuItems: MenuItem[];
    toggletNavActive: (menuItem: MenuItem) => void;
}

export default function SidebarMenuItems({menuItems, toggletNavActive}: Props) {
    return (
        <Fragment>
            {
                menuItems.map((menuItem, i) => {
                    return (
                        <li className={`${menuItem.active ? 'active' : ''}`} key={i}>
                            <SidebarTitle menuItem={menuItem}/>

                            {(menuItem.type === 'sub') ?
                                <SidebarMenuItemSub menuItem={menuItem} onPress={() => toggletNavActive(menuItem)}/>
                                : ''}

                            {(menuItem.type === 'funnels') ?
                                <SidebarMenuItemFunnelsSub menuItem={menuItem} onPress={() => toggletNavActive(menuItem)}/>
                                : ''}

                            {(menuItem.type === 'link') ?
                                <SidebarMenuItemLink menuItem={menuItem} onPress={() => toggletNavActive(menuItem)}/>

                                : ''}
                            {(menuItem.type === 'external') ?
                                <SidebarMenuItemExternalLink menuItem={menuItem}
                                                             onPress={() => toggletNavActive(menuItem)}/>

                                : ''}
                            {!!menuItem.children &&
                            <ul
                                className={`sidebar-submenu ${menuItem.active ? 'menu-open' : ''}`}
                                style={menuItem.active ? {opacity: 1, transition: 'opacity 500ms ease-in'} : {}}
                            >
                                <SidebarMenuItems menuItems={menuItem.children} toggletNavActive={toggletNavActive}/>
                            </ul>
                            }
                        </li>
                    )
                })
            }
        </Fragment>
    )
}

import React, {Fragment} from "react";
import FullSizeRow from "../../../shared/grid/FullSizeRow";

export default function NoOrdersYet() {
    return (
        <Fragment>
            <div className={"mt-8 mb-8"}>
                <FullSizeRow centerX={true}>
                    <i className="icon-bag" style={{fontSize: 90}}></i>
                </FullSizeRow>
                <FullSizeRow centerX={true} mt={4}>
                    <h3>No orders yet</h3>
                </FullSizeRow>
                <FullSizeRow centerX={true}>
                    <h5>Once you will get first order it will appear here</h5>
                </FullSizeRow>
                <FullSizeRow centerX={true} mt={4}>
                    <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/DcL3JG0yA4Q"
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </FullSizeRow>
            </div>
        </Fragment>
    )
}

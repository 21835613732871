import React, { Fragment } from 'react'
import { useAuth0 } from '../../Providers/Auth0Provider'

interface Props {
    classList: string[];
}

const UserAvatar = ({classList} : Props) => {
    const { user } = useAuth0();
    if (!user) {
        return <Fragment />;
    }
    return (
        <img className={classList.join(" ")} src={user.picture} alt="#" />
    )
}

export default UserAvatar

import React, {Fragment, useEffect, useState} from 'react';
import OrderCard from "./Order/OrderCard/OrderCard";
import OrdersList from "./Search/OrdersList";
import {ListProvider} from "../../../Providers/ListProvider";
import {Order} from "../../../typings/Dropton";
import PageContainer from "../../shared/page/PageContainer";
import FullSizeRow from "../../shared/grid/FullSizeRow";
import {useParams} from 'react-router-dom';
import ApiOrders from "../../../services/ApiOrders";
import {useDispatch, useSelector} from "react-redux";
import LoadingComponent from "../../common/LoadingComponent";
import NoResultsSearch from "./List/NoResultsSearch";

export default function FunnelOrdersSearchPage() {
    const [activeStatus, setActiveStatus] = useState('new');
    const [activeOrder, setActiveOrder] = useState<Order>();
    const dispatch = useDispatch();
    const loading = useSelector((state: any) => state.Loading.orders);

    const orders = useSelector((state: any) => state.OrdersSearch);

    const {search, id} = useParams();

    const doSearch = () => {
        dispatch({type: "ORDERS_LOADING_STATUS", data: "loading"});
        ApiOrders.searchOrders(search, 500).then(res => {
            if (res.success === 1) {
                // checkSearchResultEmpty(res.data);
                dispatch({type: 'ORDERS_SEARCH_FETCH_SUCCEEDED', data: res.data});

                let mapped = false;
                res.data.map((item: Order) => {
                    if (item.externalId === id) {
                        setActiveOrder(item);
                        mapped = true;
                    }
                });
                if (!mapped && res.data.length > 0) {
                    setActiveOrder(res.data[0]);
                }
            }
            dispatch({type: "ORDERS_LOADING_STATUS", data: "complete"});
        }).catch(e => {
            dispatch({type: "ORDERS_LOADING_STATUS", data: "complete"});
        });
    };

    useEffect(() => {
        doSearch();
    }, [search]);

    useEffect(() => {
        if (activeOrder && orders) {
            orders.map((item: Order) => {
                if (item.externalId === activeOrder.externalId) {
                    setActiveOrder(item);
                }
            });
        }
    }, [orders]);

    return (
        <Fragment>
            <PageContainer hasRightSidebar={true}>

                <FullSizeRow>
                    <h4 className={"mt-4 ml-2"}>Search: {search}</h4>

                    <div className="card mt-4">
                        <ListProvider>
                            <div className="card-body pt-0">
                                <Fragment>
                                    {loading === 'loading' ? <LoadingComponent/> : (orders.length === 0 &&
                                        <NoResultsSearch/>)}
                                    {activeOrder &&
                                    <OrdersList activeStatus={activeStatus} setOrder={setActiveOrder}
                                                activeOrder={activeOrder}/>
                                    }
                                </Fragment>
                            </div>
                        </ListProvider>
                    </div>
                </FullSizeRow>
            </PageContainer>
            {activeOrder &&
            <div className="right-sidebar show">
                <OrderCard order={activeOrder}/>
            </div>
            }

        </Fragment>
    )
}

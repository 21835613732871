import {call, put, takeEvery} from 'redux-saga/effects'
import {ContactFormFields} from "../../typings/Dropton";
import ApiContacts from "../../services/ApiContacts";

interface UpdateContactsActionInterface {
    id: number;
    orderId: number;
    formData: ContactFormFields;
}

function* updateContact(action: UpdateContactsActionInterface) {
    try {
        const data = yield call(ApiContacts.update, action.id, action.formData);

        yield put({type: "CONTACT_UPDATE_SUCCEEDED", data: data.data, id: action.orderId})
    } catch (error) {
        yield put({type: "ORDERS_FETCH_FAILED", error})
    }
}

export const runUpdateContact = (id: number, orderId: number, formData: ContactFormFields) => {
    return {type: "CONTACTS_UPDATE_CONTACTS", id, orderId, formData};
};

export function* watchUpdateContact() {
    // @ts-ignore
    yield takeEvery("CONTACTS_UPDATE_CONTACTS", updateContact)
}
import React, {Fragment, useState} from "react";
import {useSelector} from "react-redux";
import {Order} from "../../../../../typings/Dropton";
import {UncontrolledTooltip} from "reactstrap";
import ApiPlaceOrder from "../../../../../services/ApiPlaceOrder";
import {BackendConfig} from "../../../../../config/BackendConfig";
import IgnoreErrorModal from "./IgnoreErrorModal";
import {useAuthNewAgeErp} from "../../../../../Providers/AuthNewAgeErpProvider";

interface Props {
    order: Order;
}

export default function PlaceOrderButton({order}: Props) {
    const [isErrorModal, setErrorModal] = useState(false);
    const toggleErrorModal = () => setErrorModal(!isErrorModal);
    const {isAuthenticated} = useAuthNewAgeErp();

    const extEnabled = useSelector((state: any) => state.Ext.enabled);
    const extId = useSelector((state: any) => state.Ext.id);
    const isUnmappedProducts = order.products.filter(i => i.map === 0).length > 0;
    let globalErrors: string[] = [];
    const keys = Object.keys(order.contact.errors);
    keys.map(field => {
        if (field in order.contact.errors) {
            // @ts-ignore
            const errors = order.contact.errors[field];
            if (errors && errors.length > 0) {
                globalErrors = [...globalErrors, ...errors];
            }
        }
    });
    const isContactDetailsError = globalErrors.length > 0;

    let btnTooltip = 'Place order';
    let btnTitle = 'Place order';
    let fullDisabled = false;
    let buttonClass = 'btn-light disabled';

    if (!extEnabled) {
        btnTooltip = 'Dropton Extension is missing';
        fullDisabled = true;
    } else if (isUnmappedProducts) {
        btnTooltip = 'Please map products';
        fullDisabled = true;
    } else if (isContactDetailsError) {
        btnTitle = 'Ignore error';
        buttonClass = 'btn-danger';
        btnTooltip = globalErrors.join(', ');
    } else {
        buttonClass = 'btn-primary';
    }

    const placeOrder = (force?: boolean) => {
        if (isContactDetailsError && !force) {
            toggleErrorModal();
        } else {
            ApiPlaceOrder.placeAliexpress(order.id).then(res => {
                if (res.success === 1) {
                    console.log('res', res);
                    // @ts-ignore
                    chrome.runtime.sendMessage(extId, {
                        data: res.data,
                        type: 'fillOrder',
                        url: res.url,
                        resolveCaptcha: res.resolveCaptcha,
                        shippings: res.shippings,
                        backendUrl: BackendConfig.backendUrl,
                        version: 2
                    });
                }
            });
        }
    }

    if (!isAuthenticated) {
        return <Fragment/>;
    }

    return (
        <Fragment>
            {!order.aliexpressId &&
            <Fragment>
                <button className={"btn  " + buttonClass} id="TooltipTop" data-placement="top" disabled={fullDisabled}
                        onClick={() => placeOrder(false)}>
                    {btnTitle}
                </button>
                <UncontrolledTooltip placement="top" target="TooltipTop">
                    {btnTooltip}
                </UncontrolledTooltip>
                <IgnoreErrorModal toggle={toggleErrorModal} isOpen={isErrorModal} errors={globalErrors}
                                  onPress={() => placeOrder(true)}/>
            </Fragment>
            }
        </Fragment>
    )
}

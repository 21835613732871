// @ts-nocheck
import React, {Fragment} from "react";

import {ContactFormFields} from "../../../../typings/Dropton";
import FullSizeRow from "../../../shared/grid/FullSizeRow";
import {FormProvider} from "../../../shared/form/FormProvider";
import ProviderFormGroupInput from "../../../shared/form/provider-fields/ProviderFormGroupInput";


interface Props {
    contactForm: ContactFormFields;
    setContactForm: (val: ContactFormFields) => void;
    setIsDataChange: (val: boolean) => void;
}

interface Field {
    title: string;
    key: string;
}

export default function ContactEditForm({contactForm, setContactForm, setIsDataChange}: Props) {

    const fields = [
        {key: 'name', title: 'Name'},
        {key: 'country', title: 'Country'},
        {key: 'state', title: 'State'},
        {key: 'city', title: 'City'},
        {key: 'address', title: 'Address'},
        {key: 'zip', title: 'Zip'},
        {key: 'email', title: 'Email'},
        {key: 'phone', title: 'Phone'}
    ];

    return (
        <Fragment>
            <FormProvider formData={contactForm} setFormData={setContactForm} setIsDataChange={setIsDataChange}>
                {fields.map((item: Field) => {
                    return (<FullSizeRow>
                        <ProviderFormGroupInput title={item.title} inputKey={item.key} size={"sm"}/>
                    </FullSizeRow>)
                })}
            </FormProvider>
        </Fragment>
    )
}

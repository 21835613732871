import React, {Fragment, useEffect, useState} from "react";
import {Collapse} from "reactstrap";
import {SettingsStepProps} from "../types";
import CardHeaderCollapse from "../../../../page/card/CardHeaderCollapse";
import ListToolbar from "./ListToolbar";
import {ListProvider} from "../../../../../Providers/ListProvider";
import {ShipicaProduct} from "../../../../../typings/Dropton";
import ShipicaProductsList from "./ShipicaProductsList"
import ShipicaProductModal from "./ShipicaProductModal";

import {toast} from "react-toastify";
import ApiFunnels from "../../../../../services/ApiFunnels";
import {FormProvider} from "../../../../shared/form/FormProvider";
import ActivatedCheckbox from "../../Components/ActivatedCheckbox";
import SaveRow from "../../Components/SaveRow";
import {ShipicaSettings} from "../../../../../typings/Settings";
import ProviderFormGroupInput from "../../../../shared/form/provider-fields/ProviderFormGroupInput";
import SettingsAfterTitle from "../../Components/SettingsAfterTitle";

const texts = {
    title: "Shipica"
}

export default function FunnelSettingsShipicaProducts({index, opened, onCollapse, id}: SettingsStepProps) {
    const isOpened = index === opened;
    const [isDataChange, setIsDataChange] = useState(false);
    const [settings, setSettings] = useState<ShipicaSettings>();

    const onActivatedChange = (val: boolean) => {
        if (settings) {
            let f = {...settings};
            f.activated = val;
            setSettings(f);
            setIsDataChange(true);
        }
    };

    const getData = () => {
        ApiFunnels.getFunnelShipicaSettings(id).then(res => {
            if (res.success === 1) {
                setSettings(res.data);
            }
        })
    };

    const saveData = () => {
        ApiFunnels.setFunnelShipicaSettings(settings, id).then(res => {
            if (res.success === 1) {
                setIsDataChange(false);
                toast.success("Settings saved.");
            } else {
                toast.error("Error!");
            }
        })
    };

    const [selectedProduct, setSelectedProduct] = useState<ShipicaProduct | null>();
    const toggleUpdateModalOpen = () => {
        setSelectedProduct(null);
    }

    useEffect(getData, []);

    const afterTitle = settings ?
        <SettingsAfterTitle activated={settings.activated} isDataChange={isDataChange}/> : null;

    return (
        <Fragment>
            <div className={"card"}>
                <CardHeaderCollapse title={texts.title} onCollapse={() => onCollapse(isOpened ? -1 : index)}
                                    isOpened={isOpened}
                                    afterTitle={afterTitle}/>
                <Collapse isOpen={opened === index}>
                    <div className="card-body">
                        {settings &&
                        <FormProvider formData={settings} setFormData={setSettings}
                                      setIsDataChange={setIsDataChange}>
                            <ActivatedCheckbox activated={settings.activated} onActivatedChange={onActivatedChange}
                                               inputKey={"shipica"}
                            />

                            <ProviderFormGroupInput title={"Api key"} inputKey={"apiKey"}/>

                            <ProviderFormGroupInput title={"Customer id"} inputKey={"customerId"}/>

                            <SaveRow isDataChange={isDataChange} saveData={saveData}/>
                        </FormProvider>

                        }

                        {!!settings && settings.activated &&
                        <ListProvider>
                            <div className={"mt-4"}>
                                <ListToolbar id={id}/>

                                <div className="pb-2 pt-4">
                                    <ShipicaProductsList onSelect={setSelectedProduct} id={id}/>
                                </div>
                            </div>
                        </ListProvider>
                        }
                    </div>
                </Collapse>
            </div>
            {selectedProduct &&
            <ShipicaProductModal isOpen={true} id={id} toggle={toggleUpdateModalOpen} product={selectedProduct}/>
            }
        </Fragment>
    )
}

import React, {useState} from "react";

export default function HowToIntegrateComponent() {
    const [more, setMore] = useState(false);
    return (
        <div className="alert alert-secondary color-black" role="alert" style={{color: '#000', backgroundColor: '#d3eafd'}}>
            <i className="fa fa-exclamation-circle mr-2" style={{color: '#2196F3'}}></i>
            How to integrate your Funnel with Dropton <a className="ml-4" style={{color: "#2196F3"}} href={"#"} onClick={(e) => {
            e.preventDefault();
            setMore(!more);
        }}>{more ? "Show less" : "Show more"}</a>
            {more &&
            <div id="collapseInfo" className="pl-8 mt-4" role="tabpanel">
                1. Go to your ClickFunnels account<br/>
                2. Go to "Click Funnels" Tab at the top menu of your account<br/>
                3. Select the Funnel from the list that you want to integrate with Dropton<br/>
                4. Go to Settings<br/>
                5. Scroll to Webhooks integration<br/>
                6. Click on Manage Your Funnel Webhooks<br/>
                7. Click On New Webhook<br/>
                8. Copy URL from this window (it's below)<br/>
                9. Enter a URL into URL box at your ClickFunnels account<br/>
                10. In the Event box, select All events<br/>
                11. Click on Create Funnel Webhook
            </div>
            }
        </div>
    );
}

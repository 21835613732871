import React from "react";
import {Pagination, PaginationItem, PaginationLink} from "reactstrap";
import {useListProvider} from "../../../../../Providers/ListProvider";

export default function OrderPagination() {
    const {page, setPage} = useListProvider();
    return (
        <Pagination aria-label="Page navigation" className="pagination-primary">

            <PaginationItem disabled={page === 1}>
                <button onClick={() => {
                    setPage(1)
                }} className="page-link">
                    <span aria-hidden="true">«</span>
                </button>
            </PaginationItem>

            <PaginationItem disabled={page === 1}>
                <button onClick={() => {
                    setPage(page - 1)
                }} className="page-link">
                    <span aria-hidden="true">‹</span>
                </button>
            </PaginationItem>
            <PaginationItem active>
                <PaginationLink>
                    {page}
                </PaginationLink>
            </PaginationItem>

            <PaginationItem>
                <button onClick={() => {
                    setPage(page + 1)
                }} className="page-link">
                    <span aria-hidden="true">›</span>
                </button>
            </PaginationItem>
        </Pagination>
    )
}
export default function OrdersSearch(state = [], action) {
    switch (action.type) {
        case 'ORDERS_SEARCH_FETCH_SUCCEEDED':
            return action.data;
        case  'ORDERS_UPDATE_SUCCEEDED':
            let order = action.data;
            return state.map(item => {
                if (item.id === order.id) {
                    const updatedData = {
                        aliexpressId: order.aliexpressId,
                        trackingId: order.trackingId,
                        archived: order.archived,
                        manualProducts: order.manualProducts
                    };
                    return {...item, ...updatedData};
                }
                return item;
            });
        case 'CONTACT_UPDATE_SUCCEEDED':
            let contact = action.data;
            return state.map(item => {
                if (item.id === action.id) {
                    return {...item, contact};
                }
                return item;
            });
        default:
            return state
    }
}

import React, {useState} from "react";
import DroptonAssets from "../../../../assets/images/dropton/DroptonAssets";
import StepContactText from "./StepContactText";
import StepTitle from "./StepTitle";
import StepText from "./StepText";
import {StepWizardChildProps} from "react-step-wizard";
import StepNavigation from "./StepNavigation";
import CopyToClipboard from 'react-copy-to-clipboard';

interface Props extends StepWizardChildProps {
    url: string;
}

export default function Step5(props: Props) {
    const [copied, setCopied] = useState(false);


    const texts = {
        title: "Paste Webhook URL"
    };

    return (
        <div className="get-started" data-step="5">
            <StepTitle title={texts.title}/>
            <p className="text-center">
                Copy URL from this window (it's below)
            </p>
            <div className="row">
                <div className="col">
                    <div className="form-group">
                        <input className="form-control" type="text" id="new-funnel-url"
                               value={props.url}
                               placeholder="WebHook URL" readOnly/>
                    </div>
                </div>
                <div className="col-auto">
                    <CopyToClipboard text={props.url}
                                     onCopy={() => setCopied(true)}>
                        <button type="button"
                                className={"btn " + (copied ? "btn-outline-success" : "btn-outline-primary")}>
                            COPY URL
                        </button>
                    </CopyToClipboard>
                </div>
            </div>
            <p className="text-center">
                Now paste copied URL into URL box at your ClickFunnels account
            </p>
            <div className="text-center">
                <img src={DroptonAssets.getStarted["5"]} style={{width: "50%"}} alt={texts.title}/>
            </div>

            <StepNavigation {...props}/>

            <StepText currentStep={props.currentStep} allSteps={props.totalSteps} text={texts.title}/>
            <StepContactText/>
        </div>
    )
}
import React from "react";
import FormService from "../../service/FormService";
import {useForm} from "../FormProvider";
import FormGroupTextarea from "../fields/FormGroupTextarea";

interface Props {
    title: string;
    inputKey: string;
    rows?: number;
}

export default function ProviderFormGroupTextarea({title, inputKey, rows}: Props) {

    const {formData, setFormData, setIsDataChange} = useForm();

    return (<FormGroupTextarea title={title} value={formData[inputKey]}
                               rows={rows}
                               onChange={(val: string) => {
                                   FormService.onStringChange(inputKey, val, formData, setFormData);
                                   setIsDataChange(true);
                               }}/>);
}

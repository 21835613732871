import React from "react";

interface FormGroupInputProps {
    title: string;
    value: string;
    onChange: (val: string) => void;
    size?: string;
}

export default function FormGroupInput(props: FormGroupInputProps) {

    const size = props.size ? props.size : "";

    let inputClassName = ["form-control"];
    if (size) {
        inputClassName.push("form-control-" + size);
    }

    return (<div className="form-group">
        <label>{props.title}:</label>
        <input className={inputClassName.join(" ")}
               onChange={(e) => props.onChange(e.target.value)}
               value={props.value} type="text"/>
    </div>)
}

import React, {Fragment, useEffect, useState} from "react";
import {Card, CardBody, CardHeader} from "reactstrap";
import {Order, OrderProduct} from "../../../../typings/Dropton";
import moment from "moment";
import {isBrowser} from 'react-device-detect';
import {useParams} from "react-router-dom";

interface Props {
    orders: Order[],
    dateFrom: string,
    dateTo: string,
}

interface StatsByDate {
    totalSum: number,
    totalQuantity: number,
    emails: []
}

interface StatsProduct {
    id: string,
    name: string
}

interface StatsByProduct {
    totalQuantity: number,
    totalSum: number,
    sourceId: number,
    children: OrderProduct[]
}

export default function Stats(props: Props) {
    const {id} = useParams();

    const {orders, dateFrom, dateTo} = props;
    const [distinctProducts, setDistinctProducts] = useState<StatsProduct[]>([]);
    const [distinctGroups, setDistincGroups] = useState<StatsProduct[]>([]);
    const [distinctSoldSource, setDistinctSoldSource] = useState<string[]>([]);

    const [dateStats, setDateStats] = useState<any>({});
    const [dateGroupStats, setDateGroupStats] = useState<any>({});
    const [shippingStats, setShippingStats] = useState<any>({});

    const [dates, setDates] = useState<string[]>([]);

    const calcStats = () => {
        let currentDate = dateFrom;
        let Tdates: string[] = [];
        while (currentDate <= dateTo) {
            Tdates.push(currentDate);
            currentDate = moment(currentDate, "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD");
        }
        setDates(Tdates);

        let TdistinctProducts: StatsProduct[] = [];
        orders.map((order: Order) => {
            const products = order.products.map((p: OrderProduct) => {
                return {
                    id: p.sku,
                    name: p.sku
                }
            });
            products.map((i: StatsProduct) => {
                const isInArray = TdistinctProducts.filter((p: StatsProduct) => p.id === i.id).length > 0;
                if (!isInArray) {
                    TdistinctProducts.push(i);
                }
            });
        });
        setDistinctProducts(TdistinctProducts);


        let TdistinctGroups: StatsProduct[] = [];
        orders.map((order: Order) => {
            const products = order.products.map((p: OrderProduct) => {
                return {
                    id: p.type,
                    name: p.type
                }
            });
            products.map((i: StatsProduct) => {
                const isInArray = TdistinctGroups.filter((p: StatsProduct) => p.id === i.id).length > 0;
                if (!isInArray) {
                    TdistinctGroups.push(i);
                }
            });
        });
        setDistincGroups(TdistinctGroups);

        let TdistinctSoldSource: string[] = [];
        orders.map((order: Order) => {
            const isInArray = TdistinctSoldSource.indexOf(order.soldSource) >= 0;
            if (!isInArray) {
                TdistinctSoldSource.push(order.soldSource);
            }
        });
        setDistinctSoldSource(TdistinctSoldSource);


        let TstatsByDate: any = {};
        let TstatsByGroupDate: any = {};
        let TstatsBySoldSourceDate: any = {};

        orders.map((order: Order) => {
            const d = order.ajCreationDateTimezone;
            if (!(d in TstatsByDate)) {
                TstatsByDate[d] = {}
                TstatsByGroupDate[d] = {}
                TstatsBySoldSourceDate[d] = {}
            }

            if (!(order.soldSource in TstatsBySoldSourceDate[d])) {
                TstatsBySoldSourceDate[d][order.soldSource] = 0;
            }
            TstatsBySoldSourceDate[d][order.soldSource] += order.shippingCost;

            order.products.map((p: OrderProduct) => {
                const sSourceId = p.sku;
                if (!(sSourceId in TstatsByDate[d])) {
                    TstatsByDate[d][sSourceId] = {
                        totalQuantity: 0,
                        totalSum: 0,
                        emails: []
                    }
                }
                TstatsByDate[d][sSourceId].totalQuantity += (p.quantity * p.sourceQuantity);
                TstatsByDate[d][sSourceId].totalSum += p.amount;
                const isEmailExist = TstatsByDate[d][sSourceId].emails.filter((e: string) => e === order.contact.email).length > 0;
                if (!isEmailExist) {
                    TstatsByDate[d][sSourceId].emails.push(order.contact.email);
                }


                const sGroupSourceId = p.type;
                if (!(sGroupSourceId in TstatsByGroupDate[d])) {
                    TstatsByGroupDate[d][sGroupSourceId] = {
                        totalQuantity: 0,
                        totalSum: 0,
                        emails: []
                    }
                }
                TstatsByGroupDate[d][sGroupSourceId].totalQuantity += (p.quantity * p.sourceQuantity);
                TstatsByGroupDate[d][sGroupSourceId].totalSum += p.amount;
                const isEmailGroupExist = TstatsByGroupDate[d][sGroupSourceId].emails.filter((e: string) => e === order.contact.email).length > 0;
                if (!isEmailGroupExist) {
                    TstatsByGroupDate[d][sGroupSourceId].emails.push(order.contact.email);
                }
            });
        });

        setDateStats(TstatsByDate);
        setDateGroupStats(TstatsByGroupDate);
        setShippingStats(TstatsBySoldSourceDate);
    }

    useEffect(calcStats, [orders]);

    let allTotalSum = 0;
    // let allTotalQuantity = 0;
    let allTotalUnique = 0;

    let allTotalGroupSum = 0;
    // let allTotalQuantity = 0;
    let allTotalGroupUnique = 0;

    let mainGroupUnique = 0;

    let allShippingSum = 0;

    const showBySky = isBrowser || id === '1523';


    return (<Fragment>
        {showBySky &&
            <Card>
                <CardHeader><h5>SKU</h5></CardHeader>
                <CardBody>
                    <div style={{overflow: 'auto', width: '100%'}}>
                        <table className={"table"} style={{width: '100%'}}>
                            <tr>
                                <th style={{width: 200, minWidth: 200}}>Product</th>
                                {isBrowser &&
                                    <th style={{width: 100, minWidth: 100}}></th>
                                }
                                {dates.map((date) => {
                                    return (<th key={'th-' + date} style={{width: 80, minWidth: 80}}
                                                className={"text-right"}>{date}</th>)
                                })}
                                <th style={{width: 80, minWidth: 80}} className={"text-right"}>Total</th>
                            </tr>
                            {distinctProducts.map((p: StatsProduct) => {
                                let totalQuantity = 0;
                                let totalUnique = 0;
                                let totalSum = 0;
                                return (
                                    <Fragment key={"stats-p-" + p.id}>
                                        <tr className={"hard-top-border"}>
                                            <td rowSpan={isBrowser ? 2 : 1}><strong>{p.name}</strong></td>
                                            {/*<td>Revenue</td>*/}
                                            {/*{dates.map((date) => {*/}
                                            {/*    let dateStat: StatsByDate = {totalSum: 0, totalQuantity: 0, emails: []};*/}
                                            {/*    if ((date in dateStats) && (p.id in dateStats[date])) {*/}
                                            {/*        dateStat = dateStats[date][p.id];*/}
                                            {/*    }*/}

                                            {/*    totalSum += dateStat.totalSum;*/}
                                            {/*    return (<td key={'td-s-' + p.id + '-' + date}*/}
                                            {/*                className={"text-right"}>{dateStat.totalSum.toFixed(2)}</td>)*/}
                                            {/*})}*/}
                                            {/*<td className={"text-right"}><strong>{totalSum.toFixed(2)}</strong>*/}
                                            {/*</td>*/}
                                            {/*</tr>*/}
                                            {/*<tr>*/}
                                            {isBrowser &&
                                                <td>Amount</td>
                                            }
                                            {dates.map((date) => {
                                                let dateStat: StatsByDate = {totalSum: 0, totalQuantity: 0, emails: []};
                                                if ((date in dateStats) && (p.id in dateStats[date])) {
                                                    dateStat = dateStats[date][p.id];
                                                }

                                                totalQuantity += dateStat.totalQuantity;
                                                return (<td key={'td-q-' + p.id + '-' + date}
                                                            className={"text-right"}>{dateStat.totalQuantity.toFixed(0)}</td>)
                                            })}

                                            <td className={"text-right"}>
                                                <strong>{totalQuantity.toFixed(0)}</strong></td>
                                        </tr>
                                        {isBrowser &&
                                            <tr>
                                                {isBrowser &&
                                                    <td>Unique</td>
                                                }
                                                {dates.map((date) => {
                                                    let dateStat: StatsByDate = {
                                                        totalSum: 0,
                                                        totalQuantity: 0,
                                                        emails: []
                                                    };
                                                    if ((date in dateStats) && (p.id in dateStats[date])) {
                                                        dateStat = dateStats[date][p.id];
                                                    }

                                                    const q = dateStat.emails.length;
                                                    totalUnique += q;
                                                    return (<td key={'td-u-' + p.id + '-' + date}
                                                                className={"text-right"}>{q.toFixed(0)}</td>)
                                                })}

                                                <td className={"text-right"}>
                                                    <strong>{totalUnique.toFixed(0)}</strong></td>
                                            </tr>
                                        }
                                        {/*<tr>*/}
                                        {/*    <td>Average</td>*/}
                                        {/*    {dates.map((date) => {*/}
                                        {/*        let dateStat: StatsByDate = {totalSum: 0, totalQuantity: 0, emails: []};*/}
                                        {/*        if ((date in dateStats) && (p.id in dateStats[date])) {*/}
                                        {/*            dateStat = dateStats[date][p.id];*/}
                                        {/*        }*/}

                                        {/*        const q = dateStat.emails.length;*/}
                                        {/*        const av = q > 0 ? dateStat.totalSum / q : 0;*/}
                                        {/*        return (<td key={'td-av-' + p.id + '-' + date}*/}
                                        {/*                    className={"text-right"}>{av.toFixed(2)}</td>)*/}
                                        {/*    })}*/}

                                        {/*    <td className={"text-right"}>*/}
                                        {/*        <strong>{(totalUnique > 0?(totalSum / totalUnique):0).toFixed(2)}</strong></td>*/}
                                        {/*</tr>*/}
                                    </Fragment>
                                )
                            })}
                            <tr className={"hard-top-border"}>
                                <td rowSpan={2}><strong>TOTAL</strong></td>
                                {isBrowser &&
                                    <td>Revenue</td>
                                }
                                {dates.map((date) => {
                                    let totalSum = 0;
                                    let totalUnique = 0;
                                    if ((date in dateStats)) {
                                        const keys = Object.keys(dateStats[date]);
                                        keys.map(k => {
                                            totalSum += dateStats[date][k].totalSum;
                                            totalUnique += dateStats[date][k].emails.length;
                                        })
                                    }

                                    allTotalSum += totalSum;
                                    allTotalUnique += totalUnique;

                                    return (<td key={'td-tot-' + date}
                                                className={"text-right"}>
                                        <strong>{totalSum.toFixed(2)}</strong>
                                    </td>)
                                })}
                                <td
                                    className={"text-right"}>
                                    <strong>{allTotalSum.toFixed(2)}</strong>
                                </td>
                            </tr>

                            {/*<tr>*/}
                            {/*    <td>Average</td>*/}
                            {/*    {dates.map((date) => {*/}
                            {/*        let totalQuantity = 0;*/}
                            {/*        let totalSum = 0;*/}
                            {/*        if ((date in dateStats)) {*/}
                            {/*            const keys = Object.keys(dateStats[date]);*/}
                            {/*            keys.map(k => {*/}
                            {/*                totalQuantity += dateStats[date][k].emails.length;*/}
                            {/*                totalSum += dateStats[date][k].totalSum;*/}
                            {/*            })*/}
                            {/*        }*/}

                            {/*        return (<td key={'td-tot-' + date}*/}
                            {/*                    className={"text-right"}>*/}
                            {/*            <strong>{(totalQuantity > 0 ? (totalSum / totalQuantity) : 0).toFixed(2)}</strong>*/}
                            {/*        </td>)*/}
                            {/*    })}*/}
                            {/*    <td*/}
                            {/*        className={"text-right"}>*/}
                            {/*        <strong>{(allTotalUnique > 0 ? (allTotalSum / allTotalUnique) : 0).toFixed(2)}</strong>*/}
                            {/*    </td>*/}
                            {/*</tr>*/}
                        </table>
                    </div>
                </CardBody>
            </Card>
        }
        <Card>
            <CardHeader><h5>Type</h5></CardHeader>
            <CardBody>
                <div style={{overflow: 'auto', width: '100%'}}>
                    <table className={"table"} style={{width: '100%'}}>
                        <tr>
                            {isBrowser &&
                                <th style={{width: 200, minWidth: 200}}>Product</th>
                            }
                            <th style={{width: 100, minWidth: 100}}></th>
                            {dates.map((date) => {
                                return (<th key={'th-' + date} style={{width: 80, minWidth: 80}}
                                            className={"text-right"}>{date}</th>)
                            })}
                            <th style={{width: 80, minWidth: 80}} className={"text-right"}>Total</th>
                            {isBrowser &&
                                <th style={{width: 80, minWidth: 80}} className={"text-right"}>Part</th>
                            }
                        </tr>
                        {isBrowser &&
                            <Fragment>
                                {distinctGroups.map((p: StatsProduct, groupIndex: number) => {
                                    let totalQuantity = 0;
                                    let totalUnique = 0;
                                    let totalSum = 0;
                                    return (
                                        <Fragment key={"stats-p-" + p.id}>
                                            <tr className={"hard-top-border"}>
                                                <td rowSpan={2}><strong>{p.name}</strong></td>
                                                <td>Revenue</td>
                                                {dates.map((date) => {
                                                    let dateStat: StatsByDate = {
                                                        totalSum: 0,
                                                        totalQuantity: 0,
                                                        emails: []
                                                    };
                                                    if ((date in dateGroupStats) && (p.id in dateGroupStats[date])) {
                                                        dateStat = dateGroupStats[date][p.id];
                                                    }

                                                    totalSum += dateStat.totalSum;
                                                    return (<td key={'td-s-' + p.id + '-' + date}
                                                                className={"text-right"}>{dateStat.totalSum.toFixed(2)}</td>)
                                                })}
                                                <td className={"text-right"}><strong>{totalSum.toFixed(2)}</strong>
                                                </td>
                                                <td></td>
                                            </tr>
                                            {/*<tr>*/}
                                            {/*    <td>Amount</td>*/}
                                            {/*    {dates.map((date) => {*/}
                                            {/*        let dateStat: StatsByDate = {totalSum: 0, totalQuantity: 0, emails: []};*/}
                                            {/*        if ((date in dateGroupStats) && (p.id in dateGroupStats[date])) {*/}
                                            {/*            dateStat = dateGroupStats[date][p.id];*/}
                                            {/*        }*/}

                                            {/*        totalQuantity += dateStat.totalQuantity;*/}
                                            {/*        return (<td key={'td-q-' + p.id + '-' + date}*/}
                                            {/*                    className={"text-right"}>{dateStat.totalQuantity.toFixed(0)}</td>)*/}
                                            {/*    })}*/}

                                            {/*    <td className={"text-right"}>*/}
                                            {/*        <strong>{totalQuantity.toFixed(0)}</strong></td>*/}
                                            {/*</tr>*/}
                                            <tr>
                                                <td>Unique</td>
                                                {dates.map((date) => {
                                                    let dateStat: StatsByDate = {
                                                        totalSum: 0,
                                                        totalQuantity: 0,
                                                        emails: []
                                                    };
                                                    if ((date in dateGroupStats) && (p.id in dateGroupStats[date])) {
                                                        dateStat = dateGroupStats[date][p.id];
                                                    }

                                                    const q = dateStat.emails.length;
                                                    totalUnique += q;


                                                    if (groupIndex === 0) {
                                                        mainGroupUnique = totalUnique;
                                                    }

                                                    return (<td key={'td-u-' + p.id + '-' + date}
                                                                className={"text-right"}>{q.toFixed(0)}</td>)
                                                })}

                                                <td className={"text-right"}>
                                                    <strong>{totalUnique.toFixed(0)}</strong></td>
                                                <td className={"text-right"}>{(mainGroupUnique > 0 ? (totalUnique / mainGroupUnique) * 100 : 0).toFixed(0)}%</td>
                                            </tr>
                                            {/*<tr>*/}
                                            {/*    <td>Average</td>*/}
                                            {/*    {dates.map((date) => {*/}
                                            {/*        let dateStat: StatsByDate = {totalSum: 0, totalQuantity: 0, emails: []};*/}
                                            {/*        if ((date in dateGroupStats) && (p.id in dateGroupStats[date])) {*/}
                                            {/*            dateStat = dateGroupStats[date][p.id];*/}
                                            {/*        }*/}

                                            {/*        const q = dateStat.emails.length;*/}
                                            {/*        const av = q > 0 ? dateStat.totalSum / q : 0;*/}
                                            {/*        return (<td key={'td-av-' + p.id + '-' + date}*/}
                                            {/*                    className={"text-right"}>{av.toFixed(2)}</td>)*/}
                                            {/*    })}*/}

                                            {/*    <td className={"text-right"}>*/}
                                            {/*        <strong>{(totalSum / totalUnique).toFixed(2)}</strong></td>*/}
                                            {/*</tr>*/}
                                        </Fragment>
                                    )
                                })}
                            </Fragment>
                        }

                            <Fragment>
                                {distinctGroups.map((p: StatsProduct, groupIndex: number) => {
                                    let totalQuantity = 0;
                                    let totalUnique = 0;
                                    let totalSum = 0;
                                    return (
                                        <Fragment key={"stats-p-" + p.id}>

                                            <tr className={"hard-top-border"} style={isBrowser?{}:{display: 'none'}}>
                                                <td rowSpan={2}><strong>{p.name}</strong></td>
                                                <td>Revenue</td>
                                                {dates.map((date) => {
                                                    let dateStat: StatsByDate = {
                                                        totalSum: 0,
                                                        totalQuantity: 0,
                                                        emails: []
                                                    };
                                                    if ((date in dateGroupStats) && (p.id in dateGroupStats[date])) {
                                                        dateStat = dateGroupStats[date][p.id];
                                                    }

                                                    totalSum += dateStat.totalSum;
                                                    return (<td key={'td-s-' + p.id + '-' + date}
                                                                className={"text-right"}>{dateStat.totalSum.toFixed(2)}</td>)
                                                })}
                                                <td className={"text-right"}><strong>{totalSum.toFixed(2)}</strong>
                                                </td>
                                                <td></td>
                                            </tr>
                                            {/*<tr>*/}
                                            {/*    <td>Amount</td>*/}
                                            {/*    {dates.map((date) => {*/}
                                            {/*        let dateStat: StatsByDate = {totalSum: 0, totalQuantity: 0, emails: []};*/}
                                            {/*        if ((date in dateGroupStats) && (p.id in dateGroupStats[date])) {*/}
                                            {/*            dateStat = dateGroupStats[date][p.id];*/}
                                            {/*        }*/}

                                            {/*        totalQuantity += dateStat.totalQuantity;*/}
                                            {/*        return (<td key={'td-q-' + p.id + '-' + date}*/}
                                            {/*                    className={"text-right"}>{dateStat.totalQuantity.toFixed(0)}</td>)*/}
                                            {/*    })}*/}

                                            {/*    <td className={"text-right"}>*/}
                                            {/*        <strong>{totalQuantity.toFixed(0)}</strong></td>*/}
                                            {/*</tr>*/}
                                            <tr style={isBrowser?{}:{display: 'none'}}>
                                                <td>Unique</td>
                                                {dates.map((date) => {
                                                    let dateStat: StatsByDate = {
                                                        totalSum: 0,
                                                        totalQuantity: 0,
                                                        emails: []
                                                    };
                                                    if ((date in dateGroupStats) && (p.id in dateGroupStats[date])) {
                                                        dateStat = dateGroupStats[date][p.id];
                                                    }

                                                    const q = dateStat.emails.length;
                                                    totalUnique += q;


                                                    if (groupIndex === 0) {
                                                        mainGroupUnique = totalUnique;
                                                    }

                                                    return (<td key={'td-u-' + p.id + '-' + date}
                                                                className={"text-right"}>{q.toFixed(0)}</td>)
                                                })}

                                                <td className={"text-right"}>
                                                    <strong>{totalUnique.toFixed(0)}</strong></td>
                                                <td className={"text-right"}>{(mainGroupUnique > 0 ? (totalUnique / mainGroupUnique) * 100 : 0).toFixed(0)}%</td>
                                            </tr>
                                            {/*<tr>*/}
                                            {/*    <td>Average</td>*/}
                                            {/*    {dates.map((date) => {*/}
                                            {/*        let dateStat: StatsByDate = {totalSum: 0, totalQuantity: 0, emails: []};*/}
                                            {/*        if ((date in dateGroupStats) && (p.id in dateGroupStats[date])) {*/}
                                            {/*            dateStat = dateGroupStats[date][p.id];*/}
                                            {/*        }*/}

                                            {/*        const q = dateStat.emails.length;*/}
                                            {/*        const av = q > 0 ? dateStat.totalSum / q : 0;*/}
                                            {/*        return (<td key={'td-av-' + p.id + '-' + date}*/}
                                            {/*                    className={"text-right"}>{av.toFixed(2)}</td>)*/}
                                            {/*    })}*/}

                                            {/*    <td className={"text-right"}>*/}
                                            {/*        <strong>{(totalSum / totalUnique).toFixed(2)}</strong></td>*/}
                                            {/*</tr>*/}
                                        </Fragment>
                                    )
                                })}
                            </Fragment>

                        <tr className={"hard-top-border"} style={{fontWeight: 'bold'}}>
                            {isBrowser &&
                                <td></td>
                            }
                            <td>Revenue</td>
                            {dates.map((date) => {
                                let totalSum = 0;
                                let totalUnique = 0;
                                if ((date in dateGroupStats)) {
                                    const keys = Object.keys(dateGroupStats[date]);
                                    keys.map(k => {
                                        totalSum += dateGroupStats[date][k].totalSum;
                                        totalUnique += dateGroupStats[date][k].emails.length;
                                    })
                                }

                                allTotalGroupSum += totalSum;
                                allTotalGroupUnique += totalUnique;


                                return (<td key={'td-tot-' + date}
                                            className={"text-right"}>
                                    <strong>{totalSum.toFixed(2)}</strong>
                                </td>)
                            })}
                            <td
                                className={"text-right"}>
                                <strong>{allTotalGroupSum.toFixed(2)}</strong>
                            </td>
                            {isBrowser &&
                                <td></td>
                            }
                        </tr>

                        <tr>
                            {isBrowser &&
                                <td></td>
                            }
                            <td>Orders</td>
                            {dates.map((date) => {
                                let totalQuantity = 0;
                                let totalSum = 0;
                                let mainQuantity = 0;
                                if ((date in dateGroupStats)) {
                                    const keys = Object.keys(dateGroupStats[date]);
                                    keys.map((k: string, i: number) => {
                                        if (i === 0) {
                                            mainQuantity = dateGroupStats[date][k].emails.length;
                                        }
                                        totalQuantity += dateGroupStats[date][k].emails.length;
                                        totalSum += dateGroupStats[date][k].totalSum;
                                    })
                                }

                                return (<td key={'td-tot-' + date}
                                            className={"text-right"}>
                                    <strong>{(mainQuantity > 0 ? (mainQuantity) : 0).toFixed(2)}</strong>
                                </td>)
                            })}
                            <td
                                className={"text-right"}>
                                <strong>{(allTotalGroupUnique > 0 ? (mainGroupUnique) : 0).toFixed(2)}</strong>
                            </td>
                            {isBrowser &&
                                <td></td>
                            }
                        </tr>

                        <tr>
                            {isBrowser &&
                                <td></td>
                            }
                            <td>AOV</td>
                            {dates.map((date) => {
                                let totalQuantity = 0;
                                let totalSum = 0;
                                let mainQuantity = 0;
                                if ((date in dateGroupStats)) {
                                    const keys = Object.keys(dateGroupStats[date]);
                                    keys.map((k: string, i: number) => {
                                        if (i === 0) {
                                            mainQuantity = dateGroupStats[date][k].emails.length;
                                        }
                                        totalQuantity += dateGroupStats[date][k].emails.length;
                                        totalSum += dateGroupStats[date][k].totalSum;
                                    })
                                }

                                return (<td key={'td-tot-' + date}
                                            className={"text-right"}>
                                    <strong>{(mainQuantity > 0 ? (totalSum / mainQuantity) : 0).toFixed(2)}</strong>
                                </td>)
                            })}
                            <td
                                className={"text-right"}>
                                <strong>{(allTotalGroupUnique > 0 ? (allTotalGroupSum / mainGroupUnique) : 0).toFixed(2)}</strong>
                            </td>
                            {isBrowser &&
                                <td></td>
                            }
                        </tr>
                    </table>
                </div>
            </CardBody>
        </Card>

        {isBrowser &&
            <Card>
                <CardHeader><h5>Shipping</h5></CardHeader>
                <CardBody>
                    <div style={{overflow: 'auto', width: '100%'}}>
                        <table className={"table"} style={{width: '100%'}}>
                            <tr>
                                <th style={{width: 200, minWidth: 200}}>Fulfilment</th>
                                {dates.map((date) => {
                                    return (<th key={'th-' + date} style={{width: 80, minWidth: 80}}
                                                className={"text-right"}>{date}</th>)
                                })}
                                <th style={{width: 80, minWidth: 80}} className={"text-right"}>Total</th>
                            </tr>
                            {distinctSoldSource.map((p: string, groupIndex: number) => {
                                let totalSum = 0;
                                return (
                                    <Fragment key={"stats-f-" + p}>
                                        <tr className={"hard-top-border"}>
                                            <td><strong>{p}</strong></td>
                                            {dates.map((date) => {
                                                let cost = 0;
                                                if ((date in shippingStats) && (p in shippingStats[date])) {
                                                    cost = shippingStats[date][p];
                                                }

                                                totalSum += cost;
                                                return (<td key={'td-f-' + p + '-' + date}
                                                            className={"text-right"}>{cost.toFixed(2)}</td>)
                                            })}
                                            <td className={"text-right"}><strong>{totalSum.toFixed(2)}</strong>
                                            </td>
                                        </tr>

                                    </Fragment>
                                )
                            })}
                            <tr className={"hard-top-border"}>
                                <td><strong>TOTAL</strong></td>
                                {dates.map((date) => {
                                    let totalSum = 0;
                                    if ((date in shippingStats)) {
                                        const keys = Object.keys(shippingStats[date]);
                                        keys.map(k => {
                                            totalSum += shippingStats[date][k];
                                        })
                                    }

                                    allShippingSum += totalSum;

                                    return (<td key={'td-tot-' + date}
                                                className={"text-right"}>
                                        <strong>{totalSum.toFixed(2)}</strong>
                                    </td>)
                                })}
                                <td
                                    className={"text-right"}>
                                    <strong>{allShippingSum.toFixed(2)}</strong>
                                </td>
                            </tr>

                        </table>
                    </div>
                </CardBody>
            </Card>
        }
    </Fragment>)
}

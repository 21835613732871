import React, {Fragment, useEffect} from 'react'

import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import AppLayout from './AppLayout';
import PrivateRoute from './navigation/PrivateRoute';
import FeedbackPage from "./pages/Feedback/FeedbackPage";
import HelpDeskPage from "./pages/HelpDeskPage";
import AllFunnelsPage from "./pages/AllFunnels/AllFunnelsPage";
import FunnelOrdersPage from "./pages/FunnelOrders/FunnelOrdersPage";
import FunnelSettingsPage from "./pages/FunnelSettings/FunnelSettingsPage";
import LoginPage from "./auth/LoginPage";
import RegisterPage from "./auth/RegisterPage";
import {useAuthNewAgeErp} from "../Providers/AuthNewAgeErpProvider";
import FunnelOrdersSearchPage from "./pages/FunnelOrders/FunnelOrdersSearchPage";
import SocketService from "../services/SocketService";
import FunnelOrdersSearchSupportPage from "./pages/FunnelOrders/FunnelOrdersSearchSupportPage";
import FunnelStatsPage from "./pages/FunnelStats/FunnelStatsPage";
import ChangePasswordPage from "./pages/Settings/ChangePasswordPage";
import PaymentsPage from "./pages/Settings/PaymentsPage";
import FunnelMarketingPage from "./pages/FunnelStats/FunnelMarketingPage";
// ** Import custom components for redux**


const App = () => {
    const {isAuthenticated} = useAuthNewAgeErp();

    useEffect(() => {
        if (isAuthenticated) {
            SocketService.connect();
        }
    }, [isAuthenticated]);

    // const [token, setToken] = useState();
    // const {loading, user, isAuthenticated, loginWithRedirect, getIdTokenClaims} = useAuth0();

    // useEffect(() => {
    //     if (loading || isAuthenticated) {
    //         return;
    //     }
    //     const fn = async () => {
    //         await loginWithRedirect({
    //             appState: {targetUrl: window.location.pathname}
    //         });
    //     };
    //     fn();
    // }, [loading, isAuthenticated, loginWithRedirect]);
    //
    // if (loading || !user) {
    //     return <Loader/>;
    // }
    //
    // if (!token) {
    //     getIdTokenClaims().then((token) => {
    //         ApiFunnels.token = token.__raw;
    //         ApiOrders.token = token.__raw;
    //         ApiContacts.token = token.__raw;
    //         ApiPlaceOrder.token = token.__raw;
    //         setToken(token.__raw);
    //     });
    //
    //     return <Loader/>;
    // }

    return (
        <div className="App">

            <BrowserRouter basename={'/'}>
                {/*<ScrollContext>*/}
                <Switch>
                    <Route component={LoginPage} path={"/ds/login"} exact={true}/>

                    <Route component={RegisterPage} path={"/ds/register"} exact={true}/>

                    <Route
                        exact
                        path="/"
                        render={() => {
                            return (
                                isAuthenticated ?
                                    <Redirect to="/ds/funnels"/> :
                                    <Redirect to="/ds/login"/>
                            )
                        }}
                    />

                    <Route component={FunnelOrdersSearchSupportPage} path={"/ds/support/:token/:ids"}/>

                    <Route component={FunnelMarketingPage} path={"/ds/marketing/:id/:secret"}/>

                    {isAuthenticated ?
                        <Fragment>
                            <AppLayout>
                                <PrivateRoute component={AllFunnelsPage} path={"/ds/funnels"}/>

                                <PrivateRoute component={FunnelSettingsPage} path={"/ds/settings/:id"}/>

                                <PrivateRoute component={FunnelStatsPage} path={"/ds/stats/:id"}/>

                                <PrivateRoute component={FunnelOrdersPage} path={"/ds/orders/:id"}/>

                                <PrivateRoute component={FunnelOrdersSearchPage} path={"/ds/search/:search/:id"}/>

                                <PrivateRoute component={HelpDeskPage} path={"/ds/faq"}/>

                                <PrivateRoute component={FeedbackPage} path={"/ds/feedback/new"}/>

                                <PrivateRoute component={ChangePasswordPage} path={"/ds/user/change-password"}/>

                                <PrivateRoute component={PaymentsPage} path={"/ds/user/payments"}/>


                            </AppLayout>
                        </Fragment>
                        :
                        <Redirect to="/ds/login"/>
                    }


                </Switch>
                {/*</ScrollContext>*/}
            </BrowserRouter>

        </div>
    );
}

export default App

import React from "react";
import Select from 'react-select'
import {LctlogisticProduct} from "../../../../../typings/Settings";

interface Props {
    value: string;
    onChange: (value: string) => void;
    onlyActive?: boolean;
    products: LctlogisticProduct[],
}

interface Option {
    value: string;
    label: string;
}

export default function LctlogisticProductsSelectComponent({value, onChange, products}: Props) {

    const options = [
        {
            value: '',
            label: 'Unmapped'
        },
        ...products.map((item: LctlogisticProduct) => {
            return {
                value: item.GoodsID.toString(),
                label: item.EnName + ' (' + item.SKU + ')'
            }
        })];
    const selected = options.filter((item: Option) => item.value === value);
    const defValue = selected.length > 0 ? selected[0] : options[0];

    return <Select options={options} value={defValue}
                   onChange={(item: any) => onChange(item && item.value ? item.value : '')}/>;
}

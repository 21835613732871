import React, {Fragment, useEffect, useState} from 'react';
import OrderCard from "./Order/OrderCard/OrderCard";
import {ListProvider} from "../../../Providers/ListProvider";
import {Funnel, Order} from "../../../typings/Dropton";
import TrackingSyncModal from "./TrackingSync/TrackingSyncModal";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import PageContainer from "../../shared/page/PageContainer";
import FullSizeRow from "../../shared/grid/FullSizeRow";
import Api from "../../../services/ApiFunnels";
import {
    EmailNotificationsSettings,
    LctlogisticSettings,
    ShipbobSettings,
    ShipicaSettings
} from "../../../typings/Settings";
import FunnelOrdersPageToolbar from "./FunnelOrdersPageToolbar";
import SocketService from "../../../services/SocketService";

export default function FunnelOrdersPage() {
    const [activeOrder, setActiveOrder] = useState<Order>();

    const [shipbobSettings, setShipbobSettings] = useState<ShipbobSettings>();
    const [mailSettings, setMailSettings] = useState<EmailNotificationsSettings>();
    const [shipicaSettings, setShipicaSettings] = useState<ShipicaSettings>();
    const [lctlogisticSettings, setLctlogisticSettings] = useState<LctlogisticSettings>();

    const {id} = useParams();

    const [syncTrackingModalOpen, setSyncTrackingModalOpen] = useState(false);
    const toggleSyncTrackingModalOpen = () => setSyncTrackingModalOpen(!syncTrackingModalOpen);

    const syncTracking = () => {
        toggleSyncTrackingModalOpen();
    };

    const getSettingsData = () => {
        Api.getFunnelEmailNotificationsSettings(id).then((res: any) => {
            if (res.success === 1) {
                setMailSettings(res.data);
            }
        })
        Api.getFunnelShipbobSettings(id).then((res: any) => {
            if (res.success === 1) {
                setShipbobSettings(res.data);
            }
        })
        Api.getFunnelShipicaSettings(id).then((res: any) => {
            if (res.success === 1) {
                setShipicaSettings(res.data);
            }
        })
        Api.getFunnelLctlogisticSettings(id).then((res: any) => {
            if (res.success === 1) {
                setLctlogisticSettings(res.data);
            }
        })
    };

    useEffect(() => {
        getSettingsData();
        if (id) {
            subscribeToSocket();
        }
    }, [id]);

    const subscribeToSocket = () => {
        if (SocketService.connected) {
            SocketService.subscribe('funnel-orders-' + id);
        } else {
            setTimeout(() => {
                subscribeToSocket();
            }, 1000);
        }
    }

    const funnels = useSelector((state: any) => state.Funnels);
    if (funnels.length === 0) {
        return <Fragment/>
    }
    const funnel = funnels.filter((f: Funnel) => f.id === parseInt(id, 10))[0];

    return (
        <Fragment>
            <PageContainer hasRightSidebar={true}>

                <FullSizeRow>
                    <div className="card mt-4">
                        <ListProvider>
                            <div className="card-body pt-0">

                                <FunnelOrdersPageToolbar funnel={funnel} syncTracking={syncTracking}
                                                         setActiveOrder={setActiveOrder}/>

                            </div>
                        </ListProvider>
                    </div>
                </FullSizeRow>
            </PageContainer>
            {activeOrder && funnel.stats.allOrdersCount > 0 &&
            <div className="right-sidebar show">
                <OrderCard order={activeOrder} shipbobSettings={shipbobSettings} mailSettings={mailSettings}
                           shipicaSettings={shipicaSettings} lctlogisticSettings={lctlogisticSettings}/>
            </div>
            }

            <TrackingSyncModal funnelId={id} isOpen={syncTrackingModalOpen} toggle={toggleSyncTrackingModalOpen}/>
        </Fragment>
    )
}

import React, {useState} from "react";
import StepContactText from "./StepContactText";
import StepText from "./StepText";
import StepTitle from "./StepTitle";
import StepNavigation from "./StepNavigation";
import {StepWizardChildProps} from "react-step-wizard";

interface Props extends StepWizardChildProps {
    funnelName: string;
    setFunnelName: (funnelName: string) => void;
    isInstall: boolean;
    toggle: () => void;
}

export default function Step1(props: Props) {
    const err = <div className="alert alert-danger fade show">
        Please enter Funnel name
    </div>;

    const [isError, setIsError] = useState(false);

    const texts = {
        title: props.isInstall ? "Connect first funnel" : "Connect funnel"
    };

    return (
        <div className="get-started" data-step="1">
            <StepTitle title={texts.title}/>
            <p className="text-center">Just some information first and we we'll start</p>

            {isError && err}
            <div className="form-group">
                <input type="text" name="name" id="name" className="form-control form-control-lg"
                       value={props.funnelName}
                       onChange={(e) => props.setFunnelName(e.target.value)}
                       placeholder="Enter title" required/>
            </div>

            <StepNavigation {...props} nextStep={() => {
                if (props.funnelName.length === 0) {
                    setIsError(true);
                } else {
                    props.nextStep();
                }
            }}/>

            <StepText currentStep={props.currentStep} allSteps={props.totalSteps} text={texts.title}/>
            <StepContactText/>
        </div>
    )
}
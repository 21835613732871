import React from "react";

interface Props {
    expand: boolean;
    setExpand: (val: boolean) => void;
}

export default function ShowHideComponent({expand, setExpand}: Props) {
    return (
        <div className="col-auto ml-4 text-right">
            {expand ?

                <button className={"btn btn-link txt-secondary p-0"}
                        onClick={() => setExpand(false)}><i
                    className="icon s-4 icon-eye mr-1 txt-secondary"></i>
                </button>


                :
                <button className={"btn btn-link txt-secondary p-0"}
                        onClick={() => setExpand(true)}><i
                    className="icon s-4 icon-eye mr-1 txt-gray"></i>
                </button>
            }
        </div>
    )
}
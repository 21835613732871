import React, {useState} from "react";
import CopyToClipboard from "react-copy-to-clipboard";

interface Props {
    url: string;
}

export default function WebhookUrl({url}: Props) {
    const [copied, setCopied] = useState(false);

    return (
        <div className="row">
            <div className="col-10">
                <div className="form-group">
                    <label>WebHook URL:</label>
                    <input className="form-control" type="text"
                           value={url}
                           placeholder="WebHook URL" readOnly/>
                </div>
            </div>
            <div className="col-2 justify-content-center">
                <label>&nbsp;</label>
                <CopyToClipboard text={url} onCopy={() => {
                    setCopied(true);
                    setTimeout(() => {
                        setCopied(false)
                    }, 1000);
                }}>
                    <button type="button"
                            className={"btn " + (copied ? "btn-outline-success" : "btn-outline-primary")}>
                        COPY URL
                    </button>
                </CopyToClipboard>
            </div>
        </div>

    )
}
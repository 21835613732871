import React, {Fragment, useState} from "react";
import {Collapse} from "reactstrap";
import {SettingsStepProps} from "../types";
import CardHeaderCollapse from "../../../../page/card/CardHeaderCollapse";
import ListToolbar from "./ListToolbar";
import ProductsList from "./ProductsList";
import {ListProvider} from "../../../../../Providers/ListProvider";
import UpdateProductModal from "./UpdateProductModal";
import {Product} from "../../../../../typings/Dropton";

const texts = {
    title: "Aliexpress products"
}

export default function FunnelSettingsAliexpressProducts({index, opened, onCollapse, id}: SettingsStepProps) {
    const isOpened = index === opened;
    const [selectedProduct, setSelectedProduct] = useState<Product | null>();
    const toggleUpdateModalOpen = () => {
        setSelectedProduct(null);
    }

    return (
        <Fragment>
            <div className={"card"}>
                <CardHeaderCollapse title={texts.title} onCollapse={() => onCollapse(isOpened ? -1 : index)}
                                    isOpened={isOpened}/>
                <Collapse isOpen={opened === index}>
                    <div className="card-body">
                        <ListProvider>
                            <Fragment>
                                <ListToolbar id={id}/>

                                <div className="pb-2 pt-4">
                                    <ProductsList onSelect={setSelectedProduct} id={id}/>
                                </div>
                            </Fragment>
                        </ListProvider>
                    </div>
                </Collapse>
            </div>
            {selectedProduct &&
            <UpdateProductModal isOpen={true} id={id} toggle={toggleUpdateModalOpen} product={selectedProduct}/>
            }
        </Fragment>
    )
}
import React, { Fragment } from 'react';
import UserAvatar from '../../users/UserAvatar';
import UserName from '../../users/UserName';

const UserPanel = () => {
    return (
        <Fragment>
            <div className="sidebar-user text-center">
                <div>
                    <UserAvatar classList={["img-60", "rounded-circle", "lazyloaded", "blur-up"]} />
                    {/* <div className="profile-edit">
                        <Link to="#">
                            <Edit />
                        </Link>
                    </div> */}
                </div>
                <UserName />
                {/* <p>general manager.</p> */}
            </div>
        </Fragment >
    );
};

export default UserPanel;
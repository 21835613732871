import React, {Fragment, useEffect, useState} from 'react'
import {MenuItem} from '../../../../typings/Menu'
import {useDispatch, useSelector} from "react-redux";
import {Funnel} from "../../../../typings/Dropton";
import SidebarMenuItems from "./SidebarMenuItems";
import {useHistory, useParams} from "react-router-dom";
import {runFunnelsFetchData} from "../../../../store/sagas/FunnelsSaga";

interface Props {
    menuItem: MenuItem;
    onPress: () => void;
}

export default function SidebarMenuItemFunnelsSub({menuItem, onPress}: Props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const funnels = useSelector((state: any) => state.Funnels);
    const [filteredData, setFilteredData] = useState<Funnel[]>([]);
    const {id} = useParams();

    const filterData = () => {
        let data = funnels;
        data = data.filter((item: Funnel) => !item.archived)
        setFilteredData(data);
    };

    useEffect(() => {
        dispatch(runFunnelsFetchData())
    }, []);

    useEffect(() => {
        filterData();
    }, [funnels]);

    const funnelMenuItems = filteredData.map((funnel: Funnel) => {
        return {
            title: funnel.name,
            type: 'link',
            path: "/ds/orders/" + funnel.id,
            active: funnel.id === id,
            badge: funnel.stats ? funnel.stats.newOrdersCount : 0
        }
    });

    const funnelsOnPress = (e: any) => {
        e.preventDefault();

        history.push('/ds/funnels');
        // onPress();
    };

    return (
        <Fragment>
            <a className="sidebar-header" href="#allfunnels" onClick={funnelsOnPress}>
                {menuItem.icon && <menuItem.icon/>}
                <span>{menuItem.title}</span>
                <i className="fa fa-angle-right pull-right"></i>
            </a>
            {funnelMenuItems.length > 0 &&
            <ul
                className={`sidebar-submenu menu-open`}
                style={{opacity: 1, transition: 'opacity 500ms ease-in'}}
            >
                <SidebarMenuItems menuItems={funnelMenuItems} toggletNavActive={() => {
                }}/>
            </ul>
            }
        </Fragment>

    )
}

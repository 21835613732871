const InitialState = {
    paging: localStorage.getItem('paging') ? parseInt(localStorage.getItem('paging'), 10) : 25,
    ordering: localStorage.getItem('ordering') ? localStorage.getItem('ordering') : 'ASC',
};

export default function Settings(state = InitialState, action) {
    switch (action.type) {
        case 'SET_PAGING':
            return {...state, paging: action.payload};
        case 'SET_ORDERING':
            return {...state, ordering: action.payload};
        default:
            return state
    }
}

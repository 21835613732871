import React, {useState} from "react";
import {Card, CardBody, CardFooter} from "reactstrap";

import DatePicker from 'react-datepicker'
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import {isBrowser} from 'react-device-detect';

interface Props {
    dateFrom: string,
    dateTo: string,
    setDateFrom: (val: string) => void,
    setDateTo: (val: string) => void,
    paymentType: string,
    setPaymentType: (val: string) => void,
    getStatsData: () => void,
    downloadStatsData: () => void,
}

export default function Filter(props: Props) {
    // const [localDateFromVal, setLocalDateFromVal] = useState(props.dateFrom);
    // const [localDateToVal, setLocalDateToVal] = useState(props.dateTo);
    // const [localPaymentType, setLocalPaymentType] = useState(props.paymentType);

    let dateFromVal, dateToVal = null;
    if (props.dateFrom) {
        dateFromVal = moment(props.dateFrom).toDate();
    }
    if (props.dateTo) {
        dateToVal = moment(props.dateTo).toDate();
    }

    const doFilter = () => {
        props.getStatsData();

    }

    const doExport = () => {
        props.downloadStatsData();
    }

    return <Card>
        <CardBody>
            <div className={"row"}>
                <div className={"col-auto"}>
                    <div className="form-group">
                        <label>Date from:</label>
                        <DatePicker selected={dateFromVal}
                                    onChange={(date: any) => {
                                        if (!!date) {
                                            // @ts-ignore
                                            props.setDateFrom(moment(date).format('YYYY-MM-DD'));
                                        } else {
                                            // @ts-ignore
                                            props.setDateTo(null)
                                        }
                                    }}
                                    className={"form-control"}
                                    dateFormat="yyyy-MM-dd"

                        />
                    </div>
                </div>
                <div className={"col-auto"}>
                    <div className="form-group">
                        <label>Date to:</label>
                        <DatePicker selected={dateToVal}
                                    onChange={(date: any) => {
                                        if (!!date) {
                                            // @ts-ignore
                                            props.setDateTo(moment(date).format('YYYY-MM-DD'));
                                        } else {
                                            // @ts-ignore
                                            props.setDateTo(null)
                                        }
                                    }}
                                    className={"form-control"}
                                    dateFormat="yyyy-MM-dd"

                        />
                    </div>
                </div>
                <div className={"col-auto"}>
                    <div className="form-group">
                        <label>Payment:</label>
                        <select className={"form-control"} value={props.paymentType}
                                onChange={(e) => props.setPaymentType(e.target.value)}>
                            <option value={""}>All</option>
                            <option value={"stripe"}>Stripe</option>
                            <option value={"paypal"}>Paypal</option>
                        </select>
                    </div>
                </div>
            </div>
        </CardBody>
        <CardFooter>
            <button className={"btn btn-primary"} onClick={doFilter}>Filter</button>

            {isBrowser &&
                <button className={"btn btn-secondary ml-2"} onClick={doExport}>Export</button>
            }
        </CardFooter>
    </Card>
}

import React, {useState} from 'react'
import {useListProvider} from "../../../Providers/ListProvider";

interface Props {
    liveSearch?: boolean;
}

export default function ToolbarSearch({liveSearch}: Props) {
    const [search, setSearch] = useState('');
    const {setSearchString} = useListProvider();

    const onChange = (val: string) => {
        setSearch(val);
        if (liveSearch) {
            setSearchString(val);
        }
    }

    return (
        <div className="col-auto">
            <form className="search-form contact-search" onSubmit={(e) => {
                e.preventDefault();
                setSearchString(search);
            }}>
                <div className="form-group mb-0">
                    <input
                        className="form-control"
                        type="text"
                        placeholder="Search"
                        defaultValue={search}
                        onChange={(e) => onChange(e.target.value)}
                    />
                </div>
            </form>
        </div>
    )
}

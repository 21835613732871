// @ts-nocheck
import {store} from "../store";

class ExtService {
    public isExtensionInstalled: boolean;
    public extId: '';

    constructor() {
        this.isExtensionInstalled = false;
        this.checkExtensionService();
    }

    checkExtensionService = () => {
        window.addEventListener("message", (event) => {
            if (event.source == window &&
                event.data.sender &&
                event.data.sender === "dropton-extension" &&
                event.data.message_name &&
                event.data.message_name === "version") {

                this.extId = event.data.id;
                this.isExtensionInstalled = true;

                store.dispatch({type: "EXT_ENABLED", payload: true, id: event.data.id});
            }

            if (event.source == window &&
                event.data.sender &&
                event.data.sender === "dropton-extension" &&
                event.data.message_name &&
                event.data.message_name === "reload") {

                window.location.reload();
            }
        });
    };


    getAttributes = (url: string) => {
        return new Promise((resolve => {
            window.chrome.runtime.sendMessage(this.extId, {
                type: 'getProductAttributesV2',
                url: url,
            }, {}, (response: any) => {
                resolve(response);
            });
        }));
    }
}

export default new ExtService();

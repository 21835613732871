// @ts-nocheck
import React, {useEffect, useState} from "react";
import {Modal, ModalBody} from 'reactstrap';
import StepWizard from 'react-step-wizard';
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import Step4 from "./Steps/Step4";
import Step5 from "./Steps/Step5";
import Step6 from "./Steps/Step6";
import Step7 from "./Steps/Step7";

import {v4 as uuidv4} from 'uuid';
import {BackendConfig} from "../../../config/BackendConfig";
import Step8 from "./Steps/Step8";


interface Props {
    isOpen: boolean;
    toggle: () => void;
}

export default function NewFunnelModal({isOpen, toggle}: Props) {
    const isInstall = false;
    const [uuid, setUuid] = useState('');
    const [funnelName, setFunnelName] = useState('');
    // const uuid = uuidv4();
    const hookAddress = BackendConfig.hookUrl + '/' + uuid;
    const [finish, setFinish] = useState(false);

    useEffect(() => {
        setUuid(uuidv4());
        setFunnelName('')
    }, [isOpen])

    const onFinish = () => {
        setFinish(false);
        toggle();
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg" style={{overflow: "hidden"}}
               backdrop={isInstall ? "static" : false} keyboard={!isInstall}>
            {/*<ModalHeader toggle={toggle}>Connect funnel</ModalHeader>*/}
            <ModalBody>
                <div className={"p-4"}>
                    {finish ? <Step8 url={uuid} funnelName={funnelName} toggleModal={onFinish}/> :
                        <StepWizard>
                            <Step1 toggle={toggle} funnelName={funnelName} setFunnelName={setFunnelName}
                                   isInstall={isInstall}/>
                            <Step2 isInstall={isInstall}/>
                            <Step3/>
                            <Step4/>
                            <Step5 url={hookAddress}/>
                            <Step6/>
                            <Step7 toggle={toggle} onNext={() => setFinish(true)}/>
                        </StepWizard>
                    }
                </div>
            </ModalBody>
        </Modal>
    )
}

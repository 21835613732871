import React, { Fragment } from 'react'
import { useAuth0 } from '../../Providers/Auth0Provider';

export default function UserName() {
    const { user } = useAuth0();
    if (!user || !user.name) {
        return <Fragment />;
    }

    return (
        <h6 className="mt-3 f-14">{user.name}</h6>
    )
}

import React from "react";
import Select from 'react-select'
import {useSelector} from "react-redux";
import {Product} from "../../../../typings/Dropton";

interface Props {
    value: string;
    onChange: (value: string) => void;
    onlyActive?: boolean;
}

interface Option {
    value: string;
    label: string;
}

export default function ProductsSelectComponent({value, onChange, onlyActive}: Props) {
    const products = useSelector((state: any) => state.Products);
    const productsForRender = onlyActive ? products.filter((i: Product) => !i.archived) : products;
    const options = [
        {
            value: '0',
            label: 'Unmapped'
        },
        {
            value: '-1',
            label: 'Digital'
        },
        {
            value: '-2',
            label: 'FF'
        },
        ...productsForRender.map((item: Product) => {
            return {
                value: item.id.toString(),
                label: item.name
            }
        })];
    const selected = options.filter((item: Option) => item.value === value);
    const defValue = selected.length > 0 ? selected[0] : options[0];

    return <Select options={options} value={defValue}
                   onChange={(item: any) => onChange(item && item.value ? item.value : '')}/>;
}

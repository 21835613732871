import {store} from "../store";
import {runUpdateOrder} from "../store/sagas/OrdersSaga";
import io from "socket.io-client";
import {SocketConfig} from "../config/SocketConfig";

class SocketService {
    private socket: any;

    constructor() {
        this._connected = false;
    }

    private _connected: boolean;

    get connected(): boolean {
        return this._connected;
    }

    connect = () => {
        this.socket = io.connect(SocketConfig.socketUrl, {
            transports: ["websocket"],
            reconnectionAttempts: 15,
            // pingTimeout: 30000,
            timeout: 10000,
            jsonp: false,
            autoConnect: true,
            agent: false,
            pfx: "-",
            key: "-",
            passphrase: "-",
            cert: "-",
            ca: "-",
            ciphers: "-",
            rejectUnauthorized: false
        });

        this.socket.on("connect", () => {
            this._connected = true;
        });

        this.socket.on("connect_error", (err: any) => {
            // console.log("SOCKET", "connect_error", err);
        });
        this.socket.on("reconnect", (attempt: number) => {
        });

        this.socket.on("reconnect_error", () => {
            // console.log("SOCKET", "reconnect_error");
            this._connected = false;
        });
        this.socket.on('disconnect', () => {
            this._connected = false;
        });

        this.socket.on("message", this.onMessage);
    }

    subscribe = (room: string) => {
        if (this.socket) {
            this.socket.emit("subscribe", room);
        }
    }

    onMessage = (data: any) => {
        console.log('onMessage', data);
        switch (data.action) {
            case 'funnel-order-updated':
                store.dispatch({type: "ORDERS_UPDATE_SUCCEEDED", data: data.data});
                break;
            default:
                break;
        }
    }
}

export default new SocketService();

import React, {Fragment, useState} from 'react';
import {Collapse} from 'reactstrap';
import FeatherComponent from "../common/featherComponent";
import ToolbarTitle from "../page/toolbar/ToolbarTitle";
import PageContainer from "../shared/page/PageContainer";
import AccordionRow from "../shared/grid/AccordionRow";

interface FaqElement {
    title: string;
    desc: React.ReactNode;
}

export default function HelpDeskPage() {
    const [active, setActive] = useState(0);

    const faqs: FaqElement[] = [
        {
            title: 'How to map ClickFunnels products with AliExpress URLs?', desc: <Fragment>
                <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/D5N_dwx1ztU"
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            </Fragment>
        },
        {
            title: 'How to integrate your Funnel into Dropton?', desc: <Fragment>
                1. Go to your ClickFunnels account<br/>
                2. Go to "Click Funnels" Tab at the top menu of your account<br/>
                3. Select the Funnel from the list that you want to integrate with Dropton<br/>
                4. Go to Settings<br/>
                5. Scroll to Webhooks integration<br/>
                6. Click on Manage Your Funnel Webhooks<br/>
                7. Click On New Webhook<br/>
                8. {"Copy URL from the Funnel Settings in Dropton (to find URL please go to All Funnels -> Choose the Funnel you want to integrate -> Click on three dots -> click on Edit -> Here you will see a Webhook URL -> Copy it)"}<br/>
                9. Enter a URL into URL box at your ClickFunnels account<br/>
                10. In the Event box, select All events<br/>
                11. Click on Create Funnel Webhook
            </Fragment>
        },
        {
            title: 'Do I need to integrate each funnel separately?', desc: <Fragment>
                Yes, each Funnel on ClickFunnels should be integrated into Dropton separately.<br/>
                To integrate new Funnel go to ALL FUNNELS on the Left side menu and click on the INTEGRATE NEW FUNNEL
                button
            </Fragment>
        },
        {
            title: 'Can I see old orders from my funnel on Dropton?', desc: <Fragment>
                No. Dropton imports only new orders from ClickFunnels.
            </Fragment>
        },
        {
            title: 'How to enable automatic email notification with tracking codes?', desc: <Fragment>
                By default feature is inactive. To activate automatic notifications please follow the steps
                below:<br/>
                <br/>
                {"1. Go to All Funnels -> Choose the Funnel -> Click on three dots -> click on Edit, Scroll to"}
                "Automatic Notifications by Email" section and Check the Activate checkbox<br/>
                2. Write the Sender’s Name (it will appear on the notification email)<br/>
                3. Write email where your customer would reply (it should be your funnel’s support email)<br/>
                4. Write down the subject<br/>
                5. Email text is default, but you can change it. (!) Please note: tracking code will appear instead
                of variable {"{tracking}"}. If variable is missed, tracking code would not be inserted into email.
            </Fragment>
        },
        {
            title: 'How to integrate PayPal to Dropton?', desc: <Fragment>
                Dropton offers one click automation to update tracking information for your PayPal orders. To
                configure this feature you need to create PayPal API keys. Please follow following steps:<br/><br/>

                Dropton offers one click automation to update tracking information for your PayPal orders. To
                configure this feature you need to create PayPal API keys. Please follow following steps: <br/>
                1. Login to your PayPal account<br/>
                2. Open this link - <a href="https://developer.paypal.com/developer/applications/"
                                       target="_blank">https://developer.paypal.com/developer/applications/</a><br/>
                3. Select Live on Tab and Click on Create App<br/>
                4. Type the name for your App (recommended: Dropton Integration)<br/>
                5. Scroll to Live App Settings and make sure “Accept Payment” and “Transaction Search” boxes are
                checked only. (For security reasons, please uncheck all other checkboxes)<br/>
                6. Click SAVE, then Scroll back to Live API Credentials<br/>
                7. Copy the Client ID and Secret code, then paste them in Dropton settings.<br/>
                <br/>
                When integrated, Dropton will update tracking information to PayPal once per 24 hours.<br/>
                <br/>
                Please note: you need to update tracking information from AliExpress. To update them, please go to
                Funnel (on the left side), select Filled from the dropdown and click on Update tracking button (on
                the left of Items per page).
            </Fragment>
        },
        {
            title: 'How to add tracking information to PayPal manually?', desc: <Fragment>
                1. First of all, please make sure you integrate your API properly.<br/>
                2. Go to your Funnel orders and choose “Waiting PayPal tracking” status from the dropdown.<br/>
                3. You can update tracking information for 1 order by selecting the order from the list and clicking
                on the “Send tracking to PayPal” button on the right-side menu.
            </Fragment>
        },
        {
            title: 'How can I update all the tracking for my orders in Dropton?', desc: <Fragment>
                1. Make sure you are logged in to AliExpress<br/>
                2. Then go to your Funnel in Dropton<br/>
                3. Select Fulfiled status from dropdown<br/>
                4. Click on the Update tracking button (next to Items per page block)<br/>
                5. Please leave Dropton while it updates all the tracking for your Funnel.<br/>
                <br/><br/>
                Note: If you have several funnels, you need to repeat this action for each funnel.Also, if you
                enabled an option to send an email notification with tracking numbers to your customer. Email is
                sent in the moment when tracking is updated in Dropton.
            </Fragment>
        },
        {
            title: 'Is it possible to set a preferred shipping option?',
            desc: <Fragment>Sure, it is possible to define a preferred shipping method.<br/>
                <br/>
                1. Go to All Funnels in Dropton<br/>
                2. Choose the funnel<br/>
                3. Click on the 3 dots on the right and then Edit <br/>
                4. Scroll to "Shipping options" <br/>
                5. Set the preferred shipping methods (up to 3 methods)<br/>
                <br/>
                NOTE: Please check your product listing for available shipping methods before set them up.<br/>
                <br/>
                Also, preferred shipping methods are set on a Funnel level. In other words, you need to choose
                shipping methods for every funnel.</Fragment>
        },
        {
            title: 'How to delete an order?', desc:
                <Fragment>Dropton does not have option to delete an order.<br/>
                    However, there is an option to archive an order (at the right side menu, find Order details and
                    click Edit), then check the Archive checkbox and update the order.<br/>
                    Order will be moved from current its status to ARCHIVE.</Fragment>
        }
    ];

    return (
        <Fragment>
            <PageContainer>

                <ToolbarTitle title={"FAQ"} skipBack={true}/>

                <AccordionRow>

                    {faqs.map((item: FaqElement, index: number) => {
                        const isActive = index == active;
                        return (
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed pl-0"
                                                onClick={() => setActive(index)}
                                                data-toggle="collapse" data-target="#collapseicon"
                                                aria-expanded={isActive} aria-controls="collapseicon">
                                            <FeatherComponent dataFeather="help-circle"/>
                                            {item.title}
                                        </button>
                                    </h5>
                                </div>
                                <Collapse isOpen={isActive}>
                                    <div className="card-body">{item.desc}</div>
                                </Collapse>
                            </div>
                        )
                    })}
                </AccordionRow>

            </PageContainer>
        </Fragment>
    );
}

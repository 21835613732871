import React, {useEffect} from "react";
import LoadingComponent from "../../../common/LoadingComponent";
import {useDispatch} from "react-redux";

interface Props {
    funnelName: string;
    url: string;
    toggleModal: () => void;
}

export default function Step8({funnelName, url, toggleModal}: Props) {
    const dispatch = useDispatch();

    const createFunnel = () => {
        dispatch({type: "CREATE_FUNNEL", funnelName: funnelName, url: url});
        toggleModal();
    };

    useEffect(createFunnel, []);

    return (
        <div style={{height: 300}}>
            <LoadingComponent/>
        </div>
    )
}
import {applyMiddleware, compose, createStore} from 'redux';
// middlewares
import thunkMiddleware from 'redux-thunk'
import logger from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
// Import custom components
import reducers from '../reducers/index';

import rootSaga from "./sagas/rootSaga";

const sagaMiddleware = createSagaMiddleware()


function saveToLocalStorage(state) {
    try {
        const serializedState = JSON.stringify(state)
        localStorage.setItem('state', serializedState)
    } catch (e) {
        return undefined
    }
}

/**
 * Create a Redux store that holds the app state.
 */
const store = createStore(reducers, compose(
    // applyMiddleware(thunkMiddleware, logger, sagaMiddleware),
    applyMiddleware(thunkMiddleware, sagaMiddleware),

    //For working redux dev tools in chrome (https://github.com/zalmoxisus/redux-devtools-extension)
    window.devToolsExtension ? window.devToolsExtension() : function (f) {
        return f;
    }
));

// eslint-disable-next-line
const unsubscribe = store.subscribe(() => {
    const state = store.getState();
    saveToLocalStorage(state);
});

sagaMiddleware.run(rootSaga)

const action = type => store.dispatch({type})

export {store, action};
import React from 'react'

interface Props {
    title: string;
}

export default function ToolbarInlineTitle({ title }: Props) {
    return (
        <div className={"col-auto"} >
            <h5 className={"p-0 m-0"}>{title}</h5>
        </div>
    )
}

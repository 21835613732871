import React from "react";

interface Props {
    currentStep: number;
    allSteps: number;
    text: string;
}

export default function StepText({currentStep, allSteps, text}: Props) {
    return (
        <div className="txt-gray text-center mt-4">{currentStep} / {allSteps} step - {text}</div>
    )
}
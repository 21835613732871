import {combineReducers} from 'redux';
import Customizer from './customizer.reducer';
import Funnels from "./funnels.reducer"
import Products from "./products.reducer"
import Orders from "./orders.reducer"
import OrdersSearch from "./orderssearch.reducer"
import SourceProducts from "./sourceproducts.reducer"
import Ext from "./ext.reducer"
import Settings from "./settings.reducer"
import Loading from "./loading.reducer"
import Auth from "./auth.reducer";
import ShipicaProducts from "./shipicaproducts.reducer";

const reducers = combineReducers({
    Customizer,
    Funnels,
    Products,
    SourceProducts,
    Orders,
    OrdersSearch,
    Ext,
    Settings,
    Loading,
    Auth,
    ShipicaProducts,
});

export default reducers;

import React, {Fragment, useState} from "react";
import PageContainer from "../../shared/page/PageContainer";
import ToolbarTitle from "../../page/toolbar/ToolbarTitle";
import {useDispatch} from "react-redux";
import {useHistory} from 'react-router-dom';
import ApiUser from "../../../services/ApiUser";
import {toast} from "react-toastify";

export default function ChangePasswordPage() {
    const history = useHistory();
    const dispatch = useDispatch();

    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [passwordR, setPasswordR] = useState('');
    const [error, setError] = useState('');

    const doChangePassword = () => {
        setError('');
        ApiUser.changePassword(oldPassword, password, passwordR).then(res => {
            if (res.success === 0) {
                setError(res.error.description);
            } else {
                dispatch({type: 'SET_AUTH_TOKEN', token: res.token});
                toast.success('Password changed');
            }
        }).catch(e => {
            setError('Server error');
        })
    }

    return (
        <Fragment>
            <PageContainer>

                <ToolbarTitle title={"My profile"} skipBack={true}/>

                <div className={"row"}>
                    <div className={"col-3"}></div>
                    <div className={"col"}>

                        <div className={"card"}>
                            <div className={"card-header pb-2 pt-4"}>
                                <h6>Change password</h6>
                            </div>
                            <div className={"card-body"}>
                                <form className="theme-form">
                                    {error && <div className={"row mt-2 mb-2"}>
                                        <div className={"col-12 text-center txt-danger"}>
                                            {error}
                                        </div>
                                    </div>}

                                    <div className="form-group">
                                        <label className="col-form-label pt-0">Current password</label>
                                        <input className="form-control" type="password" required={true}
                                               value={oldPassword} onChange={e => setOldPassword(e.target.value)}/>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label">Password</label>
                                        <input className="form-control" type="password"
                                               value={password}
                                               onChange={e => setPassword(e.target.value)}
                                               required={true}/>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label">Repeat password</label>
                                        <input className="form-control" type="password"
                                               value={passwordR}
                                               onChange={e => setPasswordR(e.target.value)}
                                               required={true}/>
                                    </div>
                                    <div className="form-group form-row mt-3 mb-0">
                                        <button className="btn btn-primary btn-block"
                                                type="button" onClick={doChangePassword}>Change password
                                        </button>
                                    </div>


                                </form>
                            </div>
                        </div>
                    </div>
                    <div className={"col-3"}></div>
                </div>

            </PageContainer>
        </Fragment>
    )
}

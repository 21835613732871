import React, {useState} from 'react'
import ToolbarSearch from "../../page/toolbar/ToolbarSearch";
import ToolbarInlineTitle from "../../page/toolbar/ToolbarInlineTitle";
import ListArchivedDropdown from "../../page/list/ListArchivedDropdown";
import PrimaryButton from "../../navigation/components/PrimaryButton";
import NewFunnelModal from "./NewFunnelModal";
import OnBoardingPopup from "../../onboarding/OnBoardingPopup";
import {useSelector} from "react-redux";
import {isBrowser} from 'react-device-detect';

interface Props {
    title?: string;
}

export default function ListToolbar({title}: Props) {
    const isFunnelsLoaded = useSelector((state: any) => state.Loading.funnels);

    const [newModalOpen, setNewModalOpen] = useState(false);
    const toggleNewModalOpen = () => setNewModalOpen(!newModalOpen);

    return (
        <div className="pb-2 pt-4">

            <div className="row" style={{alignItems: 'center'}}>
                {title && <ToolbarInlineTitle title={title}/>}

                {isBrowser &&
                    <div className={"col"}>
                        <PrimaryButton onClick={() => setNewModalOpen(true)} title={"Connect"}/>
                    </div>
                }

                <ToolbarSearch liveSearch={true}/>

                {isBrowser &&
                    <div className={"col-auto"}>
                        <ListArchivedDropdown/>
                    </div>
                }
            </div>

            <NewFunnelModal isOpen={newModalOpen} toggle={toggleNewModalOpen}/>

            {isFunnelsLoaded === 'loaded' &&
                <OnBoardingPopup onPress={() => setNewModalOpen(true)}/>
            }
        </div>
    )
}

import React, {Fragment} from "react";
import {Collapse} from "reactstrap";
import {SettingsStepProps} from "../types";
import CardHeaderCollapse from "../../../../page/card/CardHeaderCollapse";
import {ListProvider} from "../../../../../Providers/ListProvider";
import ListToolbar from "./ListToolbar";
import ProductsList from "./ProductsList";

const texts = {
    title: "Source products"
}

export default function FunnelSettingsSourceProducts({index, opened, onCollapse, id}: SettingsStepProps) {
    const isOpened = index === opened;
    return (
        <Fragment>
            <div className={"card"}>
                <CardHeaderCollapse title={texts.title} onCollapse={() => onCollapse(isOpened ? -1 : index)}
                                    isOpened={isOpened}/>
                <Collapse isOpen={opened === index}>
                    <div className="card-body">
                        <ListProvider>
                            <Fragment>
                                <ListToolbar id={id}/>

                                <div className="pb-2 pt-4">
                                    <ProductsList id={id}/>
                                </div>
                            </Fragment>
                        </ListProvider>
                    </div>
                </Collapse>
            </div>
        </Fragment>
    )
}
import React from "react";

interface Props {
    title: string;
    onCollapse: () => void;
    isOpened: boolean;
    afterTitle?: React.ReactNode
}

export default function CardHeaderCollapse({title, onCollapse, isOpened, afterTitle}: Props) {
    return (
        <div className={"card-header pb-2 pt-4"}>
            <h6>
                <button className="btn collapsed btn-link p-0" onClick={onCollapse} aria-expanded={!isOpened}
                        data-toggle="collapse" data-target="#collapseicon5">
                    {title} {afterTitle && afterTitle}
                </button>
            </h6>
        </div>
    )
}
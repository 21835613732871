import React, {useState} from "react";

interface Props {
    value: string,
    onChange: (val: string) => void,
}

export default function ProductsListSkuValue(props: Props) {
    const [value, setValue] = useState(props.value);
    return (
        <input
            value={value}
            className={"form-control"}
            onChange={(e) => {
                setValue(e.target.value)
            }}
            onBlur={() => {
                props.onChange(value)
            }}
        />
    )
}

// @ts-nocheck
import React from "react";
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import PrimaryButton from "../../../../navigation/components/PrimaryButton";


interface Props {
    isOpen: boolean;
    toggle: () => void;
    onPress: () => void;
    errors: string[]
}


export default function IgnoreErrorModal({isOpen, toggle, onPress, errors}: Props) {
    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Ignore error</ModalHeader>
            <ModalBody>

                <div className={"row"}>
                    <div className={"col"}>
                        {errors.map((err, index) => {
                            return <p key={"errors-" + index}>{err}</p>
                        })}
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col"}></div>
                    <div className={"col-auto"}>
                        <button className={"btn btn-light btn-pill btn-air-light mr-4"}
                                onClick={toggle}>{"Cancel"}</button>

                        <PrimaryButton title={"Place order"}
                                       onClick={onPress}/>
                    </div>
                </div>

            </ModalBody>
        </Modal>
    )
}

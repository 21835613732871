import React from "react";
import Select from 'react-select'
import {AliAttributeValue} from "../../../../../typings/Aliexpress";

interface Props {
    value: number;
    onChange: (value: number) => void;
    options: AliAttributeValue[]
}

export default function AttributeValueComponent({value, onChange, options}: Props) {
    const selected = options.filter((item) => item.value === value);
    const defValue = selected.length > 0 ? selected[0] : options[0];
    return <Select options={options} defaultValue={defValue} onChange={(item: any) => {
        onChange(item.value)
    }
    }/>
        ;
}
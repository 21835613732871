import React from 'react'

interface Props {
    title: string;
    onClick: () => void;
    disabled?: boolean;
}

export default function PrimaryButton({onClick, title, disabled}: Props) {
    return (
        <button className={"btn btn-primary btn-pill btn-air-primary"}
                onClick={onClick} disabled={!!disabled}>{title}</button>
    )
}

import {call, put, select, takeEvery} from 'redux-saga/effects'
import ApiOrders from "../../services/ApiOrders";
import {ContactFormFields, OrderFormFields} from "../../typings/Dropton";

interface FetchOrdersActionInterface {
    funnelId: number;
    page: number;
    activeStatus: string;
    search: string;
}

interface UpdateOrdersActionInterface {
    id: number;
    formData: OrderFormFields
}

interface CreateOrdersActionInterface {
    id: number;
    contact: ContactFormFields;
    products: number[];
}

interface AddManualProductsOrdersActionInterface {
    id: number;
    products: number[],
    shipbobProducts: number[],
    shipicaProducts: number[],
    lctlogisticProducts: number[],
}

interface RemoveManualProductOrdersActionInterface {
    id: number;
    productId: number;
}

function* fetchOrders(action: FetchOrdersActionInterface) {
    try {
        yield put({type: "ORDERS_LOADING_STATUS", data: "loading"});

        const paging = yield select(state => state.Settings.paging);
        const ordering = yield select(state => state.Settings.ordering);

        const data = yield call(ApiOrders.fetchOrders, action.funnelId, action.page, action.activeStatus, action.search, paging, ordering);

        yield put({type: "ORDERS_FETCH_SUCCEEDED", data: data.data});

        yield put({type: "FUNNELS_FETCH_SUCCEEDED", data: data.funnels});

        yield put({type: "ORDERS_LOADING_STATUS", data: "complete"});

    } catch (error) {
        yield put({type: "ORDERS_FETCH_FAILED", error})

        yield put({type: "ORDERS_LOADING_STATUS", data: "complete"});

    }
}

function* updateOrder(action: UpdateOrdersActionInterface) {
    try {
        const data = yield call(ApiOrders.update, action.id, action.formData);

        yield put({type: "ORDERS_UPDATE_SUCCEEDED", data: data.data})

    } catch (error) {
        yield put({type: "ORDERS_FETCH_FAILED", error})
    }
}


function* createOrder(action: CreateOrdersActionInterface) {
    try {
        const data = yield call(ApiOrders.createOrder, action.id, action.contact, action.products);

        yield put({type: "ORDERS_CREATE_SUCCEEDED", data: data.data});

        yield put({type: "FUNNELS_FETCH_SUCCEEDED", data: data.funnels});
    } catch (error) {
        yield put({type: "ORDERS_FETCH_FAILED", error})
    }
}


function* addManualProductsOrder(action: AddManualProductsOrdersActionInterface) {
    try {
        const data = yield call(ApiOrders.addManualProducts, action.id, action.products, action.shipbobProducts, action.shipicaProducts, action.lctlogisticProducts);

        yield put({type: "ORDERS_UPDATE_SUCCEEDED", data: data.data})
    } catch (error) {
        yield put({type: "ORDERS_FETCH_FAILED", error})
    }
}

function* removeManualProductsOrder(action: RemoveManualProductOrdersActionInterface) {
    try {
        const data = yield call(ApiOrders.removeManualProduct, action.id, action.productId);

        yield put({type: "ORDERS_UPDATE_SUCCEEDED", data: data.data})
    } catch (error) {
        yield put({type: "ORDERS_FETCH_FAILED", error})
    }
}

export const runFetchOrders = (funnelId: number, page: number, activeStatus: string, search: string) => {
    return {type: "ORDERS_FETCH_ORDERS", funnelId, page, activeStatus, search};
};
export const runUpdateOrder = (id: number, formData: OrderFormFields) => {
    return {type: "ORDERS_UPDATE_ORDERS", id, formData};
};
export const runAddManualProductsOrder = (id: number, products: number[], shipbobProducts: number[], shipicaProducts: number[], lctlogisticProducts: number[]) => {
    return {type: "ORDERS_ADD_MANUAL_PRODUCTS_ORDERS", id, products, shipbobProducts, shipicaProducts, lctlogisticProducts};
};
export const runRemoveManualProductsOrder = (id: number, productId: number) => {
    return {type: "ORDERS_REMOVE_MANUAL_PRODUCT_ORDERS", id, productId};
};
export const runCreateOrder = (id: number, products: number[], contact: ContactFormFields) => {
    return {type: "ORDERS_CREATE_ORDER", id, products, contact};
};

export function* watchFetchOrders() {
    // @ts-ignore
    yield takeEvery("ORDERS_FETCH_ORDERS", fetchOrders)
}

export function* watchUpdateOrder() {
    // @ts-ignore
    yield takeEvery("ORDERS_UPDATE_ORDERS", updateOrder)
}

export function* watchAddManualProductsOrder() {
    // @ts-ignore
    yield takeEvery("ORDERS_ADD_MANUAL_PRODUCTS_ORDERS", addManualProductsOrder)
}

export function* watchRemoveManualProductsOrder() {
    // @ts-ignore
    yield takeEvery("ORDERS_REMOVE_MANUAL_PRODUCT_ORDERS", removeManualProductsOrder)
}

export function* watchCreateOrder() {
    // @ts-ignore
    yield takeEvery("ORDERS_CREATE_ORDER", createOrder)
}

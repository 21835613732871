import React, {Fragment, useState} from "react";
import {Order} from "../../../../../typings/Dropton";
import ShowHideComponent from "./ShowHideComponent";
import TargetProduct from "./TargetProduct";
import Separator from "./Separator";

interface Props {
    order: Order;
    onAddProduct: () => void;
}

export default function TargetProducts({order, onAddProduct}: Props) {
    const [expand, setExpand] = useState(true);

    return (
        <Fragment>
            <Separator/>
            <div className="row no-gutters">
                <div className="col">
                    <strong>Target products</strong>
                </div>


                <div className="col-4 text-right">
                    <button className="btn btn-link txt-secondary p-0" onClick={onAddProduct}><i
                        className="icon s-4 icon-plus mr-1 txt-secondary"></i>Add product
                    </button>
                </div>

                <ShowHideComponent expand={expand} setExpand={setExpand}/>
            </div>
            {expand &&
            <Fragment>
                {order.targetProducts.map((item, index) => {
                    return (
                        <TargetProduct key={"target-product-" + index + "-" + item.id} item={item} index={index} order={order}/>
                    )
                })}
                {order.manualProducts.map((item, index) => {
                    return (
                        <TargetProduct key={"target-product-" + index + "-" + item.id} item={item} index={index} canRemove order={order}/>
                    )
                })}
            </Fragment>
            }
        </Fragment>
    )
}
import React from "react";
import DroptonAssets from "../../../../assets/images/dropton/DroptonAssets";
import StepContactText from "./StepContactText";
import StepTitle from "./StepTitle";
import StepText from "./StepText";
import {StepWizardChildProps} from "react-step-wizard";
import StepNavigation from "./StepNavigation";

export default function Step4(props: StepWizardChildProps) {
    const texts = {
        title: "Create Funnel Webhook"
    };
    return (
        <div className="get-started" data-step="4">
            <StepTitle title={texts.title}/>
            <div className="text-center">
                <img src={DroptonAssets.getStarted["3"]} alt={texts.title}/>
            </div>
            <div className="text-center">
                <img src={DroptonAssets.getStarted["4"]} style={{width: "80%"}} alt={texts.title}/>
            </div>
            <p className="text-center">
                Go to Manage Your Funnel Webhooks page and click on "New Webhook" button
            </p>

            <StepNavigation {...props}/>

            <StepText currentStep={props.currentStep} allSteps={props.totalSteps} text={texts.title}/>
            <StepContactText/>
        </div>
    )
}
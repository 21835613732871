import {call, put, select, takeEvery, takeLatest} from 'redux-saga/effects'
import ApiFunnels from "../../services/ApiFunnels";

export function* fetchData(action) {
    try {
        yield put({type: "FUNNELS_LOADING_STATUS", data: "loading"});

        const data = yield call(ApiFunnels.fetchFunnels);

        yield put({type: "FUNNELS_LOADING_STATUS", data: "loaded"});

        yield put({type: "FUNNELS_FETCH_SUCCEEDED", data: data.data})
    } catch (error) {
        yield put({type: "FUNNELS_FETCH_FAILED", error})
    }
}

export function* fetchProductsData(action) {
    try {
        const data = yield call(ApiFunnels.fetchFunnelProducts, action.id);

        yield put({type: "FUNNEL_PRODUCTS_FETCH_SUCCEEDED", data: data.data})
    } catch (error) {
        yield put({type: "FUNNELS_FETCH_FAILED", error})
    }
}

export function* fetchShipicaProductsData(action) {
    try {
        const data = yield call(ApiFunnels.fetchFunnelShipicaProducts, action.id);

        yield put({type: "FUNNEL_SHIPICA_PRODUCTS_FETCH_SUCCEEDED", data: data.data})
    } catch (error) {
        yield put({type: "FUNNELS_FETCH_FAILED", error})
    }
}


export function* fetchSourceProductsData(action) {
    try {
        const data = yield call(ApiFunnels.fetchSourceProducts, action.id);

        yield put({type: "FUNNEL_SOURCE_PRODUCTS_FETCH_SUCCEEDED", data: data.data})
    } catch (error) {
        yield put({type: "FUNNELS_FETCH_FAILED", error})
    }
}

function* createFunnelProduct(action) {
    try {
        const data = yield call(ApiFunnels.createFunnelProduct, action.funnelId, action.name, action.products);

        if (action.success) {
            action.success(data);
        }

        yield put({type: "FUNNELS_PRODUCT_CREATE_SUCCEEDED", data: data.data});
        yield put({type: "FUNNEL_PRODUCTS_FETCH_REQUESTED", id: action.funnelId});
    } catch (error) {
        yield put({type: "FUNNELS_CREATE_FAILED", error})
    }
}

function* updateFunnelProduct(action) {
    try {
        const data = yield call(ApiFunnels.updateFunnelProduct, action.funnelId, action.id, action.name, action.products);

        if (action.success) {
            action.success(data);
        }

        yield put({type: "FUNNELS_PRODUCT_CREATE_SUCCEEDED", data: data.data});
        yield put({type: "FUNNEL_PRODUCTS_FETCH_REQUESTED", id: action.funnelId});
    } catch (error) {
        yield put({type: "FUNNELS_CREATE_FAILED", error})
    }
}


function* createFunnelShipicaProduct(action) {
    try {
        const data = yield call(ApiFunnels.createFunnelShipicaProduct, action.funnelId, action.name, action.sku);

        if (action.success) {
            action.success(data);
        }

        yield put({type: "FUNNELS_SHIPICA_PRODUCT_CREATE_SUCCEEDED", data: data.data});
        yield put({type: "FUNNEL_SHIPICA_PRODUCTS_FETCH_REQUESTED", id: action.funnelId});
    } catch (error) {
        yield put({type: "FUNNELS_CREATE_FAILED", error})
    }
}

function* updateFunnelShipicaProduct(action) {
    try {
        const data = yield call(ApiFunnels.updateFunnelShipicaProduct, action.funnelId, action.id, action.name, action.sku);

        if (action.success) {
            action.success(data);
        }

        yield put({type: "FUNNELS_SHIPICA_PRODUCT_CREATE_SUCCEEDED", data: data.data});
        yield put({type: "FUNNEL_SHIPICA_PRODUCTS_FETCH_REQUESTED", id: action.funnelId});
    } catch (error) {
        yield put({type: "FUNNELS_CREATE_FAILED", error})
    }
}


function* createFunnel(action) {
    try {
        const funnels = yield select(getFunnels);
        let newFunnels = [...funnels];
        newFunnels.push({
            name: action.funnelName,
            archived: false,
            id: 0,
            lastOrderReceiveTime: null
        });
        yield put({type: "FUNNELS_FETCH_SUCCEEDED", data: newFunnels});

        const data = yield call(ApiFunnels.createFunnel, action.funnelName, action.url);

        yield put({type: "FUNNELS_CREATE_SUCCEEDED", data: data.data});
        yield put({type: "FUNNELS_FETCH_REQUESTED"});
    } catch (error) {
        yield put({type: "FUNNELS_CREATE_FAILED", error})
    }
}

const getFunnels = (state) => state.Funnels;

function* archiveFunnel(action) {
    try {
        const funnels = yield select(getFunnels);
        let newFunnels = [...funnels];
        const funnel = newFunnels.filter(f => f.id === action.id)[0];
        let funnelIndex = newFunnels.indexOf(funnel);
        newFunnels[funnelIndex] = {...newFunnels[funnelIndex], archived: action.flag};
        yield put({type: "FUNNELS_FETCH_SUCCEEDED", data: newFunnels});

        const data = yield call(ApiFunnels.archiveFunnel, action.id, action.flag);

        yield put({type: "FUNNELS_ARCHIVE_SUCCEEDED", data: data.data});
        // yield put({type: "FUNNELS_FETCH_REQUESTED"});
    } catch (error) {
        yield put({type: "FUNNELS_CREATE_FAILED", error})
    }
}

function* archiveProduct(action) {
    try {
        const data = yield call(ApiFunnels.archiveProduct, action.id, action.flag);

        yield put({type: "FUNNELS_PRODUCT_ARCHIVE_SUCCEEDED", data: data.data});
        yield put({type: "FUNNEL_PRODUCTS_FETCH_REQUESTED", id: data.data.funnelId});
    } catch (error) {
        yield put({type: "FUNNELS_CREATE_FAILED", error})
    }
}


function* archiveSourceProduct(action) {
    try {
        const data = yield call(ApiFunnels.archiveSourceProduct, action.id, action.flag);

        yield put({type: "FUNNELS_SOURCE_PRODUCT_ARCHIVE_SUCCEEDED", data: data.data});
        yield put({type: "FUNNEL_SOURCE_PRODUCTS_FETCH_REQUESTED", id: data.data.funnelId});
    } catch (error) {
        yield put({type: "FUNNELS_CREATE_FAILED", error})
    }
}


function* mapSourceProduct(action) {
    try {
        const data = yield call(ApiFunnels.mapSourceProduct, action.id, action.productId);

        yield put({type: "FUNNELS_SOURCE_PRODUCT_MAP_SUCCEEDED", data: data.data});
        yield put({type: "FUNNEL_SOURCE_PRODUCTS_FETCH_REQUESTED", id: data.data.funnelId});
    } catch (error) {
        yield put({type: "FUNNELS_CREATE_FAILED", error})
    }
}

function* mapSourceProductShipbob(action) {
    try {
        const data = yield call(ApiFunnels.mapSourceProductShipbob, action.id, action.productId, action.index);

        yield put({type: "FUNNELS_SOURCE_PRODUCT_MAP_SUCCEEDED", data: data.data});
        yield put({type: "FUNNEL_SOURCE_PRODUCTS_FETCH_REQUESTED", id: data.data.funnelId});
    } catch (error) {
        yield put({type: "FUNNELS_CREATE_FAILED", error})
    }
}

function* mapSourceProductShipica(action) {
    try {
        const data = yield call(ApiFunnels.mapSourceProductShipica, action.id, action.productId, action.index);

        yield put({type: "FUNNELS_SOURCE_PRODUCT_MAP_SUCCEEDED", data: data.data});
        yield put({type: "FUNNEL_SOURCE_PRODUCTS_FETCH_REQUESTED", id: data.data.funnelId});
    } catch (error) {
        yield put({type: "FUNNELS_CREATE_FAILED", error})
    }
}

function* mapSourceProductLctlogistic(action) {
    try {
        const data = yield call(ApiFunnels.mapSourceProductLctlogistic, action.id, action.productId, action.index);

        yield put({type: "FUNNELS_SOURCE_PRODUCT_MAP_SUCCEEDED", data: data.data});
        yield put({type: "FUNNEL_SOURCE_PRODUCTS_FETCH_REQUESTED", id: data.data.funnelId});
    } catch (error) {
        yield put({type: "FUNNELS_CREATE_FAILED", error})
    }
}

function* mapSourceProductSku(action) {
    try {
        const data = yield call(ApiFunnels.mapSourceProductSku, action.id, action.sku);

        yield put({type: "FUNNELS_SOURCE_PRODUCT_MAP_SUCCEEDED", data: data.data});
        yield put({type: "FUNNEL_SOURCE_PRODUCTS_FETCH_REQUESTED", id: data.data.funnelId});
    } catch (error) {
        yield put({type: "FUNNELS_CREATE_FAILED", error})
    }
}

function* mapSourceProductType(action) {
    try {
        const data = yield call(ApiFunnels.mapSourceProductType, action.id, action.typeVal);

        yield put({type: "FUNNELS_SOURCE_PRODUCT_MAP_SUCCEEDED", data: data.data});
        yield put({type: "FUNNEL_SOURCE_PRODUCTS_FETCH_REQUESTED", id: data.data.funnelId});
    } catch (error) {
        yield put({type: "FUNNELS_CREATE_FAILED", error})
    }
}

function* mapSourceProductQuantity(action) {
    try {
        const data = yield call(ApiFunnels.mapSourceProductQuantity, action.id, action.quantity, action.index);

        yield put({type: "FUNNELS_SOURCE_PRODUCT_MAP_SUCCEEDED", data: data.data});
        yield put({type: "FUNNEL_SOURCE_PRODUCTS_FETCH_REQUESTED", id: data.data.funnelId});
    } catch (error) {
        yield put({type: "FUNNELS_CREATE_FAILED", error})
    }
}

export function runFunnelsFetchData() {
    return {type: "FUNNELS_FETCH_REQUESTED"};
}

export function* watchFetchShipicaProducts() {
    yield takeEvery('FUNNEL_SHIPICA_PRODUCTS_FETCH_REQUESTED', fetchShipicaProductsData)
}

export function* watchFetchData() {
    yield takeEvery('FUNNELS_FETCH_REQUESTED', fetchData)
}

export function* watchProductsFetchData() {
    yield takeLatest('FUNNEL_PRODUCTS_FETCH_REQUESTED', fetchProductsData)
}

export function* watchSourceProductsFetchData() {
    yield takeLatest('FUNNEL_SOURCE_PRODUCTS_FETCH_REQUESTED', fetchSourceProductsData)
}

export function* watchCreateFunnelProduct() {
    yield takeEvery('CREATE_FUNNEL_PRODUCT', createFunnelProduct)
}

export function* watchUpdateFunnelProduct() {
    yield takeEvery('UPDATE_FUNNEL_PRODUCT', updateFunnelProduct)
}

export function* watchCreateFunnel() {
    yield takeEvery('CREATE_FUNNEL', createFunnel)
}

export function* watchArchiveFunnel() {
    yield takeEvery('ARCHIVE_FUNNEL', archiveFunnel)
}

export function* watchArchiveProduct() {
    yield takeEvery('ARCHIVE_PRODUCT', archiveProduct)
}

export function* watchArchiveSourceProduct() {
    yield takeEvery('ARCHIVE_SOURCE_PRODUCT', archiveSourceProduct)
}

export function* watchMapSourceProduct() {
    yield takeEvery('MAP_SOURCE_PRODUCT', mapSourceProduct)
}

export function* watchMapSourceProductShipbob() {
    yield takeEvery('MAP_SOURCE_PRODUCT_SHIPBOB', mapSourceProductShipbob)
}

export function* watchMapSourceProductShipica() {
    yield takeEvery('MAP_SOURCE_PRODUCT_SHIPICA', mapSourceProductShipica)
}

export function* watchMapSourceProductLctlogistic() {
    yield takeEvery('MAP_SOURCE_PRODUCT_LCTLOGISTIC', mapSourceProductLctlogistic)
}

export function* watchMapSourceProductSku() {
    yield takeEvery('MAP_SOURCE_PRODUCT_SKU', mapSourceProductSku)
}


export function* watchMapSourceProductType() {
    yield takeEvery('MAP_SOURCE_PRODUCT_TYPE', mapSourceProductType)
}


export function* watchMapSourceProductQuantity() {
    yield takeEvery('MAP_SOURCE_PRODUCT_QUANTITY', mapSourceProductQuantity)
}


export function* watchCreateFunnelShipicaProduct() {
    yield takeEvery('CREATE_FUNNEL_SHIPICA_PRODUCT', createFunnelShipicaProduct)
}

export function* watchUpdateFunnelShipicaProduct() {
    yield takeEvery('UPDATE_FUNNEL_SHIPICA_PRODUCT', updateFunnelShipicaProduct)
}

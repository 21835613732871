import {Api} from "./Api";
import {BackendConfig} from "../config/BackendConfig";
import {ContactFormFields, OrderFormFields} from "../typings/Dropton";

class ApiContacts extends Api {

    update = (contactId: number, details: ContactFormFields) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: contactId, ...details})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/contacts/update', requestOptions).then(res => res.json());
    }
}

export default new ApiContacts();

import React, {Fragment, useEffect, useState} from "react";
import moment from "moment";
import RowAction from "./RowAction";
import {useDispatch, useSelector} from "react-redux";
import {Funnel} from "../../../../typings/Dropton";
import {useListProvider} from "../../../../Providers/ListProvider";
import {Link} from "react-router-dom";
import {runFunnelsFetchData} from "../../../../store/sagas/FunnelsSaga";
import Thead from "../../../shared/table/Thead";
import Table from "../../../shared/table/Table";
import {isBrowser, isMobile} from 'react-device-detect';

const FunnelsList = () => {
    const dispatch = useDispatch();

    const {archiveMode, searchString} = useListProvider();
    const funnels = useSelector((state: any) => state.Funnels);
    const [filteredData, setFilteredData] = useState<Funnel[]>([]);

    useEffect(() => {
        dispatch(runFunnelsFetchData())
    }, []);

    const filterData = () => {
        let data = funnels;
        if (archiveMode === 0) {
            data = data.filter((item: Funnel) => !item.archived);
        } else if (archiveMode === 1) {
            data = data.filter((item: Funnel) => item.archived);
        }
        if (searchString.length > 0) {
            data = data.filter((item: Funnel) => {
                if (item.name.toLowerCase().indexOf(searchString.toLowerCase()) >= 0) {
                    return true;
                }
                return false;
            });
        }
        setFilteredData(data);
    };


    useEffect(() => {
        filterData();
    }, [funnels, archiveMode, searchString]);

    const columns = isMobile ? ["ID", "NAME"] : ["ID", "Name", "Orders", "Revenue", "Contacts", "Item sold", "Last order received", ""]

    return (
        <Table>
            <Thead columns={columns}/>

            <tbody>
            {filteredData.map((funnel: Funnel) => {
                return (
                    <tr key={"funnel-key-" + funnel.id}>
                        <th scope={"row"}>{funnel.id > 0 && funnel.id}</th>
                        {isMobile &&
                            <td>
                                <Link to={'/ds/stats/' + funnel.id}>{funnel.name}</Link>
                            </td>
                        }
                        {isBrowser &&
                            <Fragment>
                                <td>
                                    <Link to={'/ds/orders/' + funnel.id}>{funnel.name}</Link>
                                    {funnel.archived && <Fragment>
                                        <br/><span
                                        className="badge badge-light badge-archived">archived</span></Fragment>}
                                </td>
                                <td>{!!funnel.stats && funnel.stats.newOrdersCount > 0 &&
                                    <span className={"badge badge-success badge-new-orders mr-2 badge-outline"}>
                            New {!!funnel.stats && funnel.stats.newOrdersCount}
                        </span>}{!!funnel.stats && funnel.stats.allOrdersCount}</td>
                                <td>N/A</td>
                                <td>N/A</td>
                                <td>N/A</td>
                                <td>{funnel.lastOrderReceiveTime ? moment(funnel.lastOrderReceiveTime).format("MM-DD-YYYY HH:mm") : '---'}</td>
                                <td>
                                    <RowAction id={funnel.id} archived={funnel.archived}/>
                                </td>
                            </Fragment>
                        }
                    </tr>
                );
            })}

            </tbody>
        </Table>
    )
};
export default FunnelsList;

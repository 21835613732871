import React, {useEffect, useState} from 'react'
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from 'reactstrap';
import {ordersStatuses} from "../../../typings/Orders";


interface Props {
    activeStatus: string;
    setActiveStatus: (val: string) => void;
}


export default function OrdersStatusDropdown({activeStatus, setActiveStatus}: Props) {
    const [statusTitle, setStatusTitle] = useState('New');

    useEffect(() => {
        const ss = ordersStatuses.filter(item => item.key === activeStatus);
        if (ss.length > 0) {
            setStatusTitle(ss[0].title);
        }
    }, [activeStatus]);

    return (
        <UncontrolledDropdown>
            <DropdownToggle caret
                            className="btn btn-light btn-pill">
                {statusTitle}
            </DropdownToggle>
            <DropdownMenu>
                {ordersStatuses.map(item => {
                    return (<DropdownItem
                            key={"status-" + item.key}
                            onClick={() => {
                                setActiveStatus(item.key)
                            }}>{item.title}</DropdownItem>
                    )
                })}

            </DropdownMenu>
        </UncontrolledDropdown>
    )
}

import React from "react";
import PrimaryButton from "../../../navigation/components/PrimaryButton";

interface Props {
    isDataChange: boolean;
    saveData: () => void;
}

export default function SaveRow({isDataChange, saveData}: Props) {
    return (
        <div className={"row"}>
            <div className={"col"}></div>
            <div className={"col-auto"}>
                <PrimaryButton title={"Save"} disabled={!isDataChange}
                               onClick={saveData}/>
            </div>
        </div>
    )
}

import React, {ChangeEvent, useEffect, useState} from "react";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import PrimaryButton from "../../../../navigation/components/PrimaryButton";
import {useDispatch} from "react-redux";
import ExtService from "../../../../../services/ExtService";
import Api from "../../../../../services/ApiFunnels";
import {AliAttribute, AliProduct} from "../../../../../typings/Aliexpress";
import ChildItem from "./ProductModal/ChildItem";


interface Props {
    isOpen: boolean;
    toggle: () => void;
    id: number;
}

const emptyProduct = {
    url: '',
    fetched: false,
    attributes: [],
    quantity: 0
};

export default function NewProductModal({isOpen, toggle, id}: Props) {
    const dispatch = useDispatch();

    const [canSave, setCanSave] = useState(false);
    const [name, setName] = useState('');
    const [products, setProducts] = useState<AliProduct[]>([{...emptyProduct}]);

    const resetState = () => {
        setName('');
        setProducts([]);
        toggle();
    };


    useEffect(() => {
        let isError = false;
        if (name.length < 0) {
            isError = true;
        }
        if (products.length < 1) {
            isError = true;
        }
        products.map(product => {
            if (!product.fetched) {
                isError = true;
            }
            if (!product.quantity || product.quantity === 0) {
                isError = true;
            }
            product.attributes.map(attr => {
                if (attr.selectedValue < 0) {
                    isError = true;
                }
            })
        });
        setCanSave(!isError);
    }, [name, products]);


    const setAliUrl = (index: number, val: string) => {
        let product = products[index];
        product.url = val;
        let newProducts = [...products];
        setProducts(newProducts);
    };

    const setQuantity = (index: number, quantity: number) => {
        let product = products[index];
        product.quantity = quantity;
        let newProducts = [...products];
        setProducts(newProducts);
    };

    const setAttributes = (index: number, attributes: AliAttribute[]) => {
        let product = products[index];
        product.attributes = attributes;
        product.fetched = true;
        let newProducts = [...products];
        setProducts(newProducts);
    };

    const addProduct = () => {
        let newProducts = [...products];
        newProducts.push({...emptyProduct});
        setProducts(newProducts);
    };

    const removeProduct = (index: number) => {
        let newProducts = [...products.filter((item, i) => index !== i)];
        setProducts(newProducts);
    };

    const fetchUrl = (index: number) => {
        let url = products[index].url;
        Api.prettyUrl(url).then(response => {
            url = response.url;
            setAliUrl(index, url);
            ExtService.getAttributes(url).then(response => {
                Api.parseAliexpressResponse(response).then(res => {
                    if (res.success === 1) {
                        setAttributes(index, res.data);
                    }
                });
            });
        })

    };

    const createProduct = () => {

        dispatch({
            type: 'CREATE_FUNNEL_PRODUCT',
            name: name,
            products: products,
            funnelId: id,
            success: () => {
                resetState();
            }
        })
    };

    const setAttributeValue = (index: number, attrIndex: number, value: number) => {
        let newProducts = [...products];
        newProducts[index].attributes[attrIndex].selectedValue = value;
        setProducts(newProducts);
    };

    const isCanAdd = products.filter(item => !item.fetched).length === 0;

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle}>New product</ModalHeader>
            <ModalBody>
                <div className="form-group">
                    <label htmlFor="name">Title</label>
                    <input type="text" className="form-control"
                           value={name}
                           onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}/>

                </div>

                {products.map((item, index) => <ChildItem key={"product-row-" + index} fetchUrl={fetchUrl} index={index}
                                                          item={item}
                                                          removeProduct={removeProduct} setAliUrl={setAliUrl}
                                                          setAttributeValue={setAttributeValue}
                                                          setQuantity={setQuantity}/>)}

                {isCanAdd &&
                <div>
                    <a className="txt-secondary" href={"#"} onClick={(e) => {
                        e.preventDefault();
                        addProduct();
                    }}>Add another Aliexpress URL</a>
                </div>
                }

            </ModalBody>
            <ModalFooter>
                <PrimaryButton title={"Save"} disabled={!canSave} onClick={() => {
                    createProduct();
                }}/>
            </ModalFooter>
        </Modal>
    )
}
import React, {useEffect, useState} from "react";
import RowAction from "./RowAction";
import {useDispatch, useSelector} from "react-redux";
import {useListProvider} from "../../../../../Providers/ListProvider";
import {Product} from "../../../../../typings/Dropton";

interface Props {
    id: number;
    onSelect: (val: Product) => void;
}

export default function ProductsList({id, onSelect}: Props) {
    const {archiveMode, searchString} = useListProvider();
    const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);

    const products = useSelector((state: any) => state.Products);
    const dispatch = useDispatch();

    const getData = () => {
        dispatch({type: "FUNNEL_PRODUCTS_FETCH_REQUESTED", id: id})
    };

    const filterProducts = () => {
        let data = products;
        if (archiveMode === 0) {
            data = data.filter((item: Product) => !item.archived);
        } else if (archiveMode === 1) {
            data = data.filter((item: Product) => item.archived);
        }
        if (searchString.length > 0) {
            data = data.filter((item: Product) => {
                if (item.name.toLowerCase().indexOf(searchString.toLowerCase()) >= 0) {
                    return true;
                }
                return false;
            });
        }
        setFilteredProducts(data);
    };

    useEffect(() => {
        if (id) {
            getData();
        }
    }, [id]);

    useEffect(() => {
        filterProducts();
    }, [products, archiveMode, searchString]);

    return (<table className="table table-striped no-table-th-border">
        <thead>
        <tr>
            <th scope="col">ID</th>
            <th scope="col">Name</th>
            <th scope="col">Urls</th>
            <th scope={"col"} style={{width: 50}}></th>
        </tr>
        </thead>
        <tbody>
        {filteredProducts.map((product: Product) => {
            return (
                <tr key={"product-key-" + product.id}>
                    <th scope={"row"}>{product.id}</th>
                    <td>
                        {product.name}
                    </td>
                    <td>{product.urls.length}</td>
                    <td>
                        <RowAction id={product.id} onSelect={() => onSelect(product)} archived={product.archived}/>
                    </td>
                </tr>
            );
        })}

        </tbody>
    </table>)
}
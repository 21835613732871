// @ts-nocheck
import React, {useEffect, useState} from "react";
import {Modal, ModalBody, ModalHeader} from 'reactstrap';

import {Order, OrderFormFields} from "../../../../typings/Dropton";
import SaveRow from "../../FunnelSettings/Components/SaveRow";
import {runUpdateOrder} from "../../../../store/sagas/OrdersSaga";
import {useDispatch} from "react-redux";
import FullSizeRow from "../../../shared/grid/FullSizeRow";


interface Props {
    isOpen: boolean;
    toggle: () => void;
    order: Order
}


export default function OrderEditModal({isOpen, toggle, order}: Props) {
    const dispatch = useDispatch();
    const [isDataChange, setIsDataChange] = useState(false);
    const [orderForm, setOrderForm] = useState<OrderFormFields>({
        aliexpressId: '',
        trackingId: '',
        archived: false,
    });

    useEffect(() => {
        setOrderForm({
            aliexpressId: order.aliexpressId,
            trackingId: order.trackingId,
            archived: order.archived,
        })
    }, [order]);


    const onArchivedChange = (val: boolean) => {
        if (orderForm) {
            let f = {...orderForm};
            f.archived = val;
            setOrderForm(f);
            setIsDataChange(true);
        }
    };

    const onAliexpressIdChange = (val: string) => {
        if (orderForm) {
            let f = {...orderForm};
            f.aliexpressId = val;
            setOrderForm(f);
            setIsDataChange(true);
        }
    };


    const onTrackingIdChange = (val: string) => {
        if (orderForm) {
            let f = {...orderForm};
            f.trackingId = val;
            setOrderForm(f);
            setIsDataChange(true);
        }
    };

    const saveData = (e) => {
        e.preventDefault();
        dispatch(runUpdateOrder(order.id, orderForm));
        toggle();
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle}>Order details</ModalHeader>
            <ModalBody>
                <form>
                    <FullSizeRow>
                        <div className="form-group">
                            <label>AliExpress Order ID</label>
                            <input className="form-control" onChange={(e) => onAliexpressIdChange(e.target.value)}
                                   value={orderForm.aliexpressId} type="text"/>
                        </div>
                    </FullSizeRow>
                    <FullSizeRow>
                        <div className="form-group">
                            <label>Tracking id</label>
                            <input className="form-control" onChange={(e) => onTrackingIdChange(e.target.value)}
                                   value={orderForm.trackingId} type="text"/>
                        </div>
                    </FullSizeRow>
                    <div className="form-group m-checkbox-inline ml-1">
                        <div
                            className={"checkbox " + (orderForm.archived ? "checkbox-success" : "checkbox-primary")}>
                            <input type="checkbox" id={'archived'} checked={orderForm.archived}
                                   onChange={() => onArchivedChange(!orderForm.archived)}
                                   onClick={() => onArchivedChange(!orderForm.archived)}/>
                            <label className="mb-0" htmlFor={'archived'}>Archive</label>
                        </div>
                    </div>

                    <SaveRow isDataChange={isDataChange} saveData={saveData}/>
                </form>
            </ModalBody>
        </Modal>
    )
}

import React, {Fragment, useEffect, useState} from "react";
import {Collapse} from "reactstrap";
import {SettingsStepProps} from "./types";
import CardHeaderCollapse from "../../../page/card/CardHeaderCollapse";
import SaveRow from "../Components/SaveRow";
import Api from "../../../../services/ApiFunnels";
import {toast} from "react-toastify";
import SettingsAfterTitle from "../Components/SettingsAfterTitle";
import ActivatedCheckbox from "../Components/ActivatedCheckbox";
import {FormProvider} from "../../../shared/form/FormProvider";
import ProviderFormGroupInput from "../../../shared/form/provider-fields/ProviderFormGroupInput";
import ProviderFormGroupTextarea from "../../../shared/form/provider-fields/ProviderFormGroupTextarea";
import {EmailNotificationsSettings} from "../../../../typings/Settings";



const texts = {
    title: "Automatic Notifications by Email"
};

export default function FunnelSettingsEmailNotifications({index, opened, onCollapse, id}: SettingsStepProps) {
    const isOpened = index === opened;
    const [isDataChange, setIsDataChange] = useState(false);
    const [settings, setSettings] = useState<EmailNotificationsSettings>();

    const getData = () => {
        Api.getFunnelEmailNotificationsSettings(id).then(res => {
            if (res.success === 1) {
                setSettings(res.data);
            }
        })
    };

    const saveData = () => {
        Api.setFunnelEmailNotificationsSettings(settings, id).then(res => {
            if (res.success === 1) {
                setIsDataChange(false);
                toast.success("Settings saved.");
            } else {
                toast.error("Error!");
            }
        })
    };


    useEffect(() => {
        getData();
    }, []);

    const afterTitle = settings ?
        <SettingsAfterTitle activated={settings.activated} isDataChange={isDataChange}/> : null;

    const onActivatedChange = (val: boolean) => {
        if (settings) {
            let f = {...settings};
            f.activated = val;
            setSettings(f);
            setIsDataChange(true);
        }
    };

    return (
        <Fragment>
            <div className={"card"}>
                <CardHeaderCollapse title={texts.title} onCollapse={() => onCollapse(isOpened ? -1 : index)}
                                    isOpened={isOpened}
                                    afterTitle={afterTitle}/>
                <Collapse isOpen={opened === index}>
                    <div className="card-body">
                        {settings &&
                        <FormProvider formData={settings} setFormData={setSettings}
                                      setIsDataChange={setIsDataChange}>
                            <ActivatedCheckbox activated={settings.activated} onActivatedChange={onActivatedChange}
                                               inputKey={"email-notifications"}
                                               helpText={"When activated your customers will get an email notification when tracking number appears in Dropton."}/>

                            <ProviderFormGroupInput title={"From name"} inputKey={"fromName"}/>

                            <ProviderFormGroupInput title={"Reply to"} inputKey={"replyTo"}/>

                            <ProviderFormGroupInput title={"Subject"} inputKey={"subject"}/>


                            <div className="row">
                                <div className="col">
                                    <ProviderFormGroupTextarea title={"Tracking text"} inputKey={"trackingText"}
                                                               rows={12}/>
                                </div>
                                <div className="col-2">
                                    <p className="text-grey pt-4">
                                        {"Use {tracking} for insert tracking number in text"}
                                    </p>

                                </div>
                            </div>
                            <SaveRow isDataChange={isDataChange} saveData={saveData}/>
                        </FormProvider>

                        }
                    </div>
                </Collapse>
            </div>
        </Fragment>
    )
}

import React, {Fragment, useState} from 'react';
import logo from '../../../assets/images/logo.png';
import UserMenu from './userMenu';

import SearchHeader from './searchHeader';
import {Link} from 'react-router-dom';
import {AlignLeft, Maximize, MoreHorizontal} from 'react-feather';

const Header = () => {
    const [sidebar, setSidebar] = useState(false);
    const [headerbar, setHeaderbar] = useState(true);

    const openCloseSidebar = () => {
        if (sidebar) {
            setSidebar(!sidebar)
            document.querySelector(".page-main-header").classList.remove('open');
            document.querySelector(".page-sidebar").classList.remove('open');
        } else {
            setSidebar(!sidebar)
            document.querySelector(".page-main-header").classList.add('open');
            document.querySelector(".page-sidebar").classList.add('open');
        }
    }

    //full screen function
    function goFull(e) {
        e.preventDefault();

        if ((document.fullScreenElement && document.fullScreenElement !== null) ||
            (!document.mozFullScreen && !document.webkitIsFullScreen)) {
            if (document.documentElement.requestFullScreen) {
                document.documentElement.requestFullScreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullScreen) {
                document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    return (
        <Fragment>
            <div className="page-main-header">
                <div className="main-header-right row">
                    <div className="main-header-left d-lg-none">
                        <div className="logo-wrapper">
                            <Link to="/dashboard/default">
                                <img className="img-fluid" src={logo} alt=""/>
                            </Link>
                        </div>
                    </div>
                    <div className="mobile-sidebar d-block">
                        <div className="media-body text-right switch-sm">
                            <label className="switch">
                                <a href="#javascript" onClick={() => openCloseSidebar()}>
                                    <AlignLeft/>
                                </a>
                            </label>
                        </div>
                    </div>
                    <div className="nav-right col p-0">
                        <ul className={`nav-menus ${headerbar ? '' : 'open'}`}>
                            <li>
                                <SearchHeader/>
                            </li>
                            <li>
                                <a onClick={goFull} className="text-dark" href="#">
                                    <Maximize/>
                                </a>
                            </li>
                            <UserMenu/>
                        </ul>
                        <div className="d-lg-none mobile-toggle pull-right" onClick={() => setHeaderbar(!headerbar)}>
                            <MoreHorizontal/></div>
                    </div>

                    <script id="empty-template" type="text/x-handlebars-template">
                        <div className="EmptyMessage">Your search turned up 0 results. This most likely means the
                            backend is down, yikes!
                        </div>
                    </script>
                </div>
            </div>
        </Fragment>
    )
};
export default Header;

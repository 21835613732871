import React, {useContext, useState} from "react";

interface ProviderValue {
    formData: any;
    setFormData: (val: any) => void;
    setIsDataChange: (val: boolean) => void;
    isDataChange: boolean;
}

export const FormContext = React.createContext<ProviderValue>({
    formData: {},
    setFormData: (val: any) => {
    },
    setIsDataChange: (val: boolean) => {
    },
    isDataChange: false,
});

export const useForm = () => useContext(FormContext);

interface Props {
    children: any;
    formData: any;
    setFormData: (val: any) => void;
    setIsDataChange?: (val: boolean) => void;
}

export const FormProvider = ({children, formData, setFormData, setIsDataChange}: Props) => {
    const [isDataChange, setIsDataChangeLocal] = useState(false);
    return (
        <FormContext.Provider value={{
            formData, setFormData, setIsDataChange: () => {
                setIsDataChangeLocal(true);
                if (setIsDataChange) {
                    setIsDataChange(true);
                }
            },
            isDataChange
        }}>

            {children}

        </FormContext.Provider>
    )
};

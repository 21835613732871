import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {MENUITEMS} from '../../../config/Menu';
import {useHistory} from 'react-router-dom';
import {Search} from 'react-feather';
import ApiOrders from "../../../services/ApiOrders";
import {Order} from "../../../typings/Dropton";
import {useDispatch} from "react-redux";

const SearchHeader = () => {
    const mainmenu = MENUITEMS;

    const [searchString, setSearchString] = useState('');
    const [searchOpen, setsearchOpen] = useState(false);
    const [searchResults, setSearchResults] = useState<Order[]>([]);
    const [timer, setTimer] = useState(0);
    const history = useHistory();

    const dispatch = useDispatch();

    const escFunction = useCallback((event) => {
        if (!searchString || searchString.length === 0) {
            return;
        }
        if (event.keyCode === 27) {
            //Do whatever when esc is pressed
            setSearchResults([]);
            removeFix();
            // setsearchValue('')
        } else if (event.keyCode === 13) {
            // doSearch();
            if (searchResults.length > 0) {
                history.push('/ds/search/' + searchString + '/' + searchResults[0].externalId);
            } else {
                history.push('/ds/search/' + searchString + '/-');
            }
            setSearchResults([]);
            removeFix();
            event.preventDefault();
        }
    }, [searchString]);

    const doSearch = () => {
        ApiOrders.searchOrders(searchString, 5).then(res => {
            if (res.success === 1) {
                // checkSearchResultEmpty(res.data);
                setSearchResults(res.data);
            }
        });
    };

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
        //eslint-disable-next-line
    }, [searchString]);

    useEffect(() => {
        if (searchString) {
            if (timer) {
                window.clearTimeout(timer);
            }
            setTimer(window.setTimeout(() => {
                doSearch();
            }, 300));
        }
    }, [searchString]);

    const handleSearchKeyword = (keyword: string) => {
        keyword ? addFix() : removeFix();

        // const items = [];
        setSearchString(keyword);
        // eslint-disable-next-line

        // ApiOrders.searchOrders(keyword)

        // mainmenu.filter(menuItems => {
        //     if (menuItems.title.toLowerCase().includes(keyword) && menuItems.type === 'link') {
        //         items.push(menuItems);
        //     }
        //     if (!menuItems.children) return false
        //     // eslint-disable-next-line
        //     menuItems.children.filter(subItems => {
        //         if (subItems.title.toLowerCase().includes(keyword) && subItems.type === 'link') {
        //             subItems.icon = menuItems.icon
        //             items.push(subItems);
        //         }
        //         if (!subItems.children) return false
        //         // eslint-disable-next-line
        //         subItems.children.filter(suSubItems => {
        //             if (suSubItems.title.toLowerCase().includes(keyword)) {
        //                 suSubItems.icon = menuItems.icon
        //                 items.push(suSubItems);
        //             }
        //         })
        //     })
        //     checkSearchResultEmpty(items)
        //     setsearchValue(items);
        // });
    }
    //
    // const checkSearchResultEmpty = (items) => {
    //     if (!items.length) {
    //         document.querySelector(".empty-menu").classList.add('is-open');
    //     } else {
    //         document.querySelector(".empty-menu").classList.remove('is-open');
    //     }
    // }

    const addFix = () => {
        //@ts-ignore
        document.querySelector(".Typeahead-menu").classList.add('is-open');
        document.body.classList.add("offcanvas");
    }

    const removeFix = () => {
        // setsearchValue('');
        //@ts-ignore
        document.querySelector(".Typeahead-menu").classList.remove('is-open');
        document.body.classList.remove("offcanvas");
    }

    const toggleBtn = () => {
        if (searchOpen) {
            setsearchOpen(!searchOpen);
            //@ts-ignore
            document.querySelector('.searchIcon').classList.add('open');
        } else {
            setsearchOpen(!searchOpen);
            //@ts-ignore
            document.querySelector('.searchIcon').classList.remove('open');
        }
    };

    const onResultPress = (data: Order) => {
        history.push('/ds/search/' + searchString + '/' + data.externalId);
        removeFix();
    };


    return (
        <Fragment>
            <div>
                <form className="form-inline search-form">
                    <div className="form-group">
                        <input
                            className="form-control-plaintext searchIcon"
                            type="text"
                            placeholder="Search"
                            defaultValue={searchString}
                            onChange={(e) => handleSearchKeyword(e.target.value)}
                        />
                        <span className="d-sm-none mobile-search" onClick={toggleBtn}>
                            <Search/>
                        </span>

                        <div className="Typeahead-menu custom-scrollbar" id="search-outer">
                            {searchResults && searchResults.length > 0 ?
                                searchResults.map((data, index) => {
                                    return (
                                        <div className="ProfileCard u-cf" key={index}>
                                            <div className="ProfileCard-avatar">
                                                {/*<data.icon/>*/}
                                            </div>
                                            <div className="ProfileCard-details">
                                                <div className="ProfileCard-realName">
                                                    <button
                                                        className={"btn-search"}
                                                        onClick={(e) => {
                                                            onResultPress(data);
                                                            e.preventDefault();
                                                        }}
                                                    >
                                                        {data.funnelName} · {data.externalId} · {data.contact.name} · {data.contact.email}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) : ''
                            }
                        </div>
                        <div className="Typeahead-menu empty-menu">
                            <div className="tt-dataset tt-dataset-0">
                                <div className="EmptyMessage">
                                    Opps!! There are no result found.
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </Fragment>
    );
};

export default SearchHeader;

import React from 'react'
import {MenuItem} from '../../../../typings/Menu'

interface Props {
    menuItem: MenuItem;
    onPress: () => void;
}

export default function SidebarMenuItemExternalLink({menuItem, onPress}: Props) {
    return (
        <a
            target={"_blank"}
            href={`${menuItem.path}`}
            className={`sidebar-header ${menuItem.active ? 'active' : ''}`}
            onClick={onPress}
        >
            {menuItem.icon && <menuItem.icon/>}<span>{menuItem.title}</span>
            {menuItem.children ?
                <i className="fa fa-angle-right pull-right"></i> : ''}
        </a>
    )
}

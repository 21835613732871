// @ts-nocheck
import React, {useState} from "react";
import {Modal, ModalBody, ModalHeader} from 'reactstrap';

import {ContactFormFields, ProductAddOption} from "../../../../typings/Dropton";
import SaveRow from "../../FunnelSettings/Components/SaveRow";
import {useDispatch} from "react-redux";
import ContactEditForm from "../Forms/ContactEditForm";
import ProductsForm from "../Forms/ProductsForm";
import {useParams} from "react-router-dom";
import {runCreateOrder} from "../../../../store/sagas/OrdersSaga";


interface Props {
    isOpen: boolean;
    toggle: () => void;

}

const clearContact = {
    name: '',
    address: '',
    city: '',
    country: '',
    state: '',
    zip: '',
    email: '',
    phone: '',
};

export default function NewOrderModal({isOpen, toggle}: Props) {
    const dispatch = useDispatch();
    const {id} = useParams();

    const [products, setProducts] = useState<ProductAddOption[]>([]);
    const [isDataChange, setIsDataChange] = useState(false);
    const [contactForm, setContactForm] = useState<ContactFormFields>(clearContact);
    const saveData = (e) => {
        e.preventDefault();
        dispatch(runCreateOrder(id, products.map(item => item.value), contactForm));
        toggle();
        setContactForm(clearContact);
        setProducts([]);
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle}>New order</ModalHeader>
            <ModalBody>

                <ContactEditForm contactForm={contactForm} setContactForm={setContactForm}
                                 setIsDataChange={setIsDataChange}/>

                <ProductsForm funnelId={id} products={products} setProducts={setProducts}
                              setIsDataChange={setIsDataChange}/>

                <SaveRow isDataChange={isDataChange} saveData={saveData}/>
            </ModalBody>
        </Modal>
    )
}
const InitialState = {
    token: window.localStorage.getItem('token'),
    needPayment: false,
    needExt: false,
};

export default function Auth(state = InitialState, action) {
    switch (action.type) {
        case 'SET_AUTH_TOKEN':
            window.localStorage.setItem('token', action.token);
            return {...state, token: action.token};
        case 'SET_NEED_PAYMENT':
            return {...state, needPayment: true};
        case 'CLOSE_NEED_PAYMENT':
            return {...state, needPayment: false};
        case 'SET_NEED_EXT':
            return {...state, needExt: true};
        default:
            return state
    }
}

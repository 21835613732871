import React, {Fragment, useEffect, useState} from 'react';
import OrderCard from "./Order/OrderCard/OrderCard";
import OrdersList from "./Search/OrdersList";
import {ListProvider} from "../../../Providers/ListProvider";
import {Order} from "../../../typings/Dropton";
import PageContainer from "../../shared/page/PageContainer";
import FullSizeRow from "../../shared/grid/FullSizeRow";
import {useHistory, useLocation, useParams} from 'react-router-dom';
import ApiOrders from "../../../services/ApiOrders";
import {useDispatch, useSelector} from "react-redux";
import LoadingComponent from "../../common/LoadingComponent";
import NoResultsSearch from "./List/NoResultsSearch";
import ApiFunnels from "../../../services/ApiFunnels";
import ApiContacts from "../../../services/ApiContacts";

export default function FunnelOrdersSearchSupportPage() {
    const history = useHistory();
    const location = useLocation();
    const [queryParams, setQueryParams] = useState<any>({});

    const setQueryParam = (change: any) => {
        const params = {...queryParams, ...change};
        const query = location.pathname + '?' + Object.keys(params).map(key => key + '=' + params[key]).join('&')
        history.push(query);
    }

    const [activeStatus, setActiveStatus] = useState('new');
    const [activeOrder, setActiveOrder] = useState<Order>();
    const dispatch = useDispatch();
    const loading = useSelector((state: any) => state.Loading.orders);

    const orders = useSelector((state: any) => state.OrdersSearch);

    const {ids, token} = useParams();

    const setSearch = (val: string) => {
        setQueryParam({search: val});
    }
    const search = !!queryParams.search ? queryParams.search : '';
    const [tmpSearch, setTmpSearch] = useState('');
    useEffect(() => {
        if (location.search.length > 0) {
            const q = Object.fromEntries(new URLSearchParams(location.search));
            setQueryParams(q);
        } else {
            setQueryParams({});
        }
    }, [location.search]);
    const doSearch = () => {
        dispatch({type: "ORDERS_LOADING_STATUS", data: "loading"});
        ApiOrders.searchSupportOrders(search, ids, 500).then(res => {
            if (res.success === 1) {
                // checkSearchResultEmpty(res.data);
                dispatch({type: 'ORDERS_SEARCH_FETCH_SUCCEEDED', data: res.data});

                let mapped = false;
                res.data.map((item: Order) => {
                    // if (item.externalId === id) {
                    //     setActiveOrder(item);
                    //     mapped = true;
                    // }
                });
                if (!mapped && res.data.length > 0) {
                    setActiveOrder(res.data[0]);
                }
            }
            dispatch({type: "ORDERS_LOADING_STATUS", data: "complete"});
        }).catch(e => {
            dispatch({type: "ORDERS_LOADING_STATUS", data: "complete"});
        });
    };

    useEffect(() => {
        if (search.length > 0) {
            doSearch();
        }
    }, [search]);

    useEffect(() => {
        ApiFunnels.token = token;
        ApiOrders.token = token;
        ApiContacts.token = token;
    }, [token]);

    useEffect(() => {
        if (activeOrder && orders) {
            orders.map((item: Order) => {
                if (item.externalId === activeOrder.externalId) {
                    setActiveOrder(item);
                }
            });
        }
    }, [orders]);

    if (!token) {
        return (<Fragment/>);
    }

    return (
        <Fragment>
            <PageContainer hasRightSidebar={true}>

                <div className={"row mt-4"}>
                    <div className={"col"}>
                        <input type={"text"} className={"form-control"} value={tmpSearch}
                               onChange={e => setTmpSearch(e.target.value)}
                               onKeyPress={event => {
                                   if (event.key === 'Enter') {
                                       setSearch(tmpSearch);
                                   }
                               }}
                        />
                    </div>
                    <div className={"col-auto"}>
                        <button type={"button"} className={"btn btn-primary"}
                                onClick={() => setSearch(tmpSearch)}>Search
                        </button>
                    </div>
                </div>

                <FullSizeRow>
                    <h4 className={"mt-4 ml-2"}>Search: {search}</h4>

                    <div className="card mt-4">
                        <ListProvider>
                            <div className="card-body pt-0">
                                <Fragment>
                                    {loading === 'loading' ? <LoadingComponent/> : (orders.length === 0 &&
                                        <NoResultsSearch/>)}
                                    {activeOrder &&
                                    <OrdersList activeStatus={activeStatus} setOrder={setActiveOrder}
                                                activeOrder={activeOrder}/>
                                    }
                                </Fragment>
                            </div>
                        </ListProvider>
                    </div>
                </FullSizeRow>
            </PageContainer>
            {activeOrder &&
            <div className="right-sidebar show">
                <OrderCard order={activeOrder}/>
            </div>
            }

        </Fragment>
    )
}

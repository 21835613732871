import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {Route, withRouter} from "react-router-dom";
import {useAuthNewAgeErp} from "../../Providers/AuthNewAgeErpProvider";


const PrivateRoute = ({component: Component, path, location, ...rest}) => {
    const {isAuthenticated} = useAuthNewAgeErp();

    useEffect(() => {
        const fn = async () => {
            if (!isAuthenticated) {
                window.location.href = '/ds/login';
            }
        };
        fn();
    }, [isAuthenticated, path, location]);

    const render = props =>
        isAuthenticated === true ? <Component {...props} /> : null;

    return <Route path={path} render={render} {...rest} />;
};

PrivateRoute.propTypes = {
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.object])
        .isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired
    }).isRequired,
    path: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ]).isRequired
};

export default withRouter(PrivateRoute);

import React, {Fragment} from "react";
import {Modal, ModalBody} from "reactstrap";
import {useSelector} from "react-redux";
import {isBrowser} from 'react-device-detect';

export default function NoExtensionPopup() {
    const isExtService = useSelector((state: any) => state.Ext.enabled)
    const funnels = useSelector((state: any) => state.Funnels);

    const openLink = () => {
        window.open('https://chrome.google.com/webstore/detail/soro/iickooboldgnnjnjgdbmoddcmkjijhpf');
    }

    return <Fragment/>

    // if (!isBrowser) {
    //     return <Fragment/>
    // }
    //
    // return (
    //     <Modal isOpen={!isExtService && funnels.length > 0}>
    //         <ModalBody>
    //             <div style={{
    //                 fontSize: 16,
    //                 padding: 30,
    //                 color: '#6D717A'
    //             }}>
    //                 <h2 className="text-center">Get the Dropton Extension</h2>
    //                 <p className="text-center" style={{margin: '30px 0'}}>If you wish to order from AliExpress via
    //                     Dropton, you will need
    //                     our
    //                     free extension from
    //                     Chrome Web Store</p>
    //
    //                 <div className="text-center">
    //                     <button className="btn btn-secondary btn-dropton" onClick={openLink}>
    //                         Get the Dropton Extension
    //                     </button>
    //                 </div>
    //             </div>
    //         </ModalBody>
    //     </Modal>
    // )
}

import React from "react";
import Select from 'react-select'
import {useSelector} from "react-redux";
import {Product} from "../../../../../typings/Dropton";

interface Props {
    value: number;
    onChange: (value: string) => void;
}

interface Option {
    value: number;
    label: string;
}

export default function ProductsSelectComponent({value, onChange}: Props) {
    const defOption = {
        value: 0,
        label: 'Select product...'
    };
    const products = useSelector((state: any) => state.Products);
    const options = [
        defOption,
        ...products.filter((item: Product) => !item.archived).map((item: Product) => {
            return {
                value: item.id,
                label: item.name
            }
        })];
    const selected = options.filter((item: Option) => item.value === value);
    const defValue = selected.length > 0 ? selected[0] : options[0];
    return <Select options={options} defaultValue={defValue}
                   value={defOption}
                   onChange={(item: any) => onChange(item ? item : null)}/>;
}
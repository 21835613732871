// @ts-nocheck
import React, {useEffect, useState} from "react";
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import ApiOrders from "../../../../services/ApiOrders";
import {runFetchOrders} from "../../../../store/sagas/OrdersSaga";


interface Props {
    isOpen: boolean;
    toggle: () => void;
    funnelId: number;
}


export default function TrackingSyncModal({isOpen, toggle, funnelId}: Props) {
    const dispatch = useDispatch();
    const {id} = useParams();
    const [data, setData] = useState([]);
    const [activeIndex, setActiveIndex] = useState(-1);
    const [loginError, setLoginError] = useState(false);

    const extId = useSelector((state: any) => state.Ext.id);

    const getData = () => {
        ApiOrders.fetchFulfilledOrders(funnelId).then(res => {
            if (res.success === 1) {
                setData(res.data);
                setActiveIndex(0);
            }
        });
    };

    useEffect(() => {
        if (isOpen) {
            setLoginError(false);
            getData();
        } else {
            setData([]);
            setActiveIndex(-1);
        }
    }, [isOpen]);

    useEffect(() => {
        if (activeIndex >= 0) {
            if (activeIndex < data.length) {
                let currentOrder = {...data[activeIndex]};
                chrome.runtime.sendMessage(extId, {
                    'type': 'getTrackingIdResponse',
                    'action': 'trackingid',
                    'funnelId': funnelId,
                    'orderId': currentOrder.orderId,
                    'aliId': currentOrder.aliexpressId,
                }, (response) => {
                    if (response) {
                        if (response === 'login-error') {
                            setLoginError(true);
                        } else if (response !== '-') {
                            ApiOrders.update(currentOrder.id, {
                                aliexpressId: currentOrder.aliexpressId,
                                trackingId: response,
                                archived: false
                            }).then(res => {
                                currentOrder.trackingId = response;
                                let newData = JSON.parse(JSON.stringify(data));
                                newData[activeIndex] = currentOrder;
                                setData(newData);
                                setActiveIndex(activeIndex + 1);
                            }).catch(() => {
                                setActiveIndex(activeIndex + 1);
                            });
                        } else {
                            setActiveIndex(activeIndex + 1);
                        }
                    } else {
                        setActiveIndex(activeIndex + 1);
                    }
                });
            } else {
                dispatch(runFetchOrders(funnelId, 1, 'fulfilled', ''));
            }
        }
    }, [activeIndex]);


    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle}>Update tracking {activeIndex >= 0 && activeIndex === data.length ?
                <span className={"ml-4 badge badge-success"}>Finish</span> :
                <span className={"ml-4 badge badge-secondary"}>Processing</span>}</ModalHeader>
            <ModalBody>

                {loginError ?
                    <div className="alert alert-danger fade show">
                        Please login to AliExpress
                    </div> :
                    <div className="row no-gutters">
                        <table className="simple-table">
                            <thead>
                            <tr>
                                <th width={"25%"}>Order ID</th>
                                <th width={"25%"}>Aliexpress ID</th>
                                <th width={"50%"}>Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            {data.map(item => {
                                return (
                                    <tr key={"tr-" + item.orderId}>
                                        <td>{item.orderId}</td>
                                        <td>{item.aliexpressId}</td>
                                        <td>{item.trackingId}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                }

            </ModalBody>
        </Modal>
    )
}
import React, {Fragment, useEffect, useState} from 'react';
import ToolbarTitle from "../../page/toolbar/ToolbarTitle";
import FunnelSettingsGeneral from "./Plugins/FunnelSettingsGeneral";
import FunnelSettingsEmailNotifications from "./Plugins/FunnelSettingsEmailNotifications";
import FunnelSettingsPaypal from "./Plugins/FunnelSettingsPaypal";
import FunnelSettingsShipping from "./Plugins/FunnelSettingsShipping";
import FunnelSettingsCaptcha from "./Plugins/FunnelSettingsCaptcha";
import FunnelSettingsSourceProducts from "./Plugins/SourceProducts/FunnelSettingsSourceProducts";
import {useParams} from "react-router-dom";
import FunnelSettingsAliexpressProducts from "./Plugins/AliexpressProducts/FunnelSettingsAliexpressProducts";
import PageContainer from "../../shared/page/PageContainer";
import AccordionRow from "../../shared/grid/AccordionRow";
import FunnelSettingsShipbob from "./Plugins/Shipbob/FunnelSettingsShipbob";
import {FunnelGeneralInfo} from "../../../typings/Dropton";
import ApiFunnels from "../../../services/ApiFunnels";
import FunnelSettingsShipicaProducts from "./Plugins/Shipica/FunnelSettingsShipicaProducts";
import FunnelSettingsLctlogistic from "./Plugins/Lctlogistic/FunnelSettingsLctlogistic";

interface SettingsItem {
    title: string;
    component: React.ReactNode
}

export default function FunnelSettingsPage() {
    const [funnel, setFunnel] = useState<FunnelGeneralInfo>();

    const getData = () => {
        ApiFunnels.getFunnelGeneralInfo(id).then((res: any) => {
            if (res.success === 1) {
                setFunnel(res.data);
            }
        });
    };

    useEffect(() => {
        getData()
    }, []);

    const [opened, setOpened] = useState(-1);

    const {id} = useParams();

    return (
        <PageContainer>
            {funnel &&
            <Fragment>
                <ToolbarTitle title={funnel.name + " - Settings"}/>

                <AccordionRow>
                    <FunnelSettingsGeneral id={id} onCollapse={setOpened} index={0} opened={opened} funnel={funnel}
                                           setFunnel={setFunnel}/>
                    <FunnelSettingsEmailNotifications id={id} onCollapse={setOpened} index={1} opened={opened}/>
                    <FunnelSettingsPaypal id={id} onCollapse={setOpened} index={2} opened={opened}/>
                    <FunnelSettingsShipping id={id} onCollapse={setOpened} index={3} opened={opened}/>
                    <FunnelSettingsCaptcha id={id} onCollapse={setOpened} index={4} opened={opened}/>

                    <FunnelSettingsShipbob id={id} onCollapse={setOpened} index={7} opened={opened}/>

                    <FunnelSettingsShipicaProducts id={id} onCollapse={setOpened} index={8} opened={opened}/>

                    <FunnelSettingsLctlogistic id={id} onCollapse={setOpened} index={9} opened={opened}/>

                    <FunnelSettingsAliexpressProducts id={id} onCollapse={setOpened} index={5} opened={opened}/>
                    <FunnelSettingsSourceProducts id={id} onCollapse={setOpened} index={6} opened={opened}/>
                </AccordionRow>
            </Fragment>
            }
        </PageContainer>
    )
}

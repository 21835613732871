import React, {Fragment} from 'react';

import history from '../../../utils/history';

interface Props {
    skipBack?: boolean;
    rightActions?: any;
    title: string;
    subtitle?: string;
}

const ToolbarTitle = ({skipBack, rightActions, title, subtitle}: Props) => {

    const goBack = () => {
        history.goBack();
    };

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="page-header">

                    <div className={"row"}>
                        <div className="col">
                            <div className="page-header-left" style={{alignItems: 'center'}}>
                                <div className={"row"}>
                                    {!skipBack &&
                                    <div className={"col-auto"}>
                                        <button className="btn btn-light btn-air-light btn-pill btn-sm mr-4"
                                                onClick={goBack}>
                                            <i className="fa fa-arrow-left"></i>
                                        </button>
                                    </div>
                                    }
                                    <div className={"col"}>
                                        <h5 className={"mb-0 pb-0"}>{title}</h5>
                                    </div>
                                    {/* {archived &&
                                    <div className={"col-auto"}>
                                        <span className="badge badge-pill badge-danger ml-4">Archived</span></div>} */}
                                </div>
                            </div>


                        </div>

                        <div className={"col-auto"}>
                            {/* {extraActions && <ListExtraActions actions={extraActions} size={"small"}/>} */}
                            {rightActions}
                        </div>
                    </div>
                    {subtitle && <div className={"row mt-2"}>
                        <div className={"col"}>
                            <div className="page-header-left" style={{alignItems: 'center'}}>
                                <div className={"row"}>
                                    <div className={"col"}>
                                        <h6 className={"mb-0 pb-0 txt-secondary"}>{subtitle}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>

            </div>
        </Fragment>
    );

}

export default ToolbarTitle;

import React from "react";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";

interface Props {
    archived: boolean;
    id: number;
    onSelect: () => void;
}

export default function RowAction({id, archived, onSelect}: Props) {
    const dispatch = useDispatch();
    const history = useHistory()

    return (
        <UncontrolledDropdown>
            <DropdownToggle caret
                            className="btn btn-light  btn-pill btn-sm ml-2">
                ···
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem
                    onClick={onSelect}><i className="icon icon-pencil mr-2"></i>Edit</DropdownItem>
                <DropdownItem
                    onClick={() => {
                        dispatch({
                            type: "ARCHIVE_SHIPICA_PRODUCT",
                            id: id,
                            flag: archived ? 0 : 1
                        });
                    }}><i className="icon icon-archive mr-2"></i>{archived ? "Restore" : "Archive"}</DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    )
}

import React, {useState} from 'react'
import ToolbarInlineTitle from "../../../../page/toolbar/ToolbarInlineTitle";
import PrimaryButton from "../../../../navigation/components/PrimaryButton";
import ToolbarSearch from "../../../../page/toolbar/ToolbarSearch";
import ListArchivedDropdown from "../../../../page/list/ListArchivedDropdown";
import ShipicaProductModal from "./ShipicaProductModal";

interface Props {
    title?: string;
    id: number;
}

export default function ListToolbar({title, id}: Props) {
    const [newModalOpen, setNewModalOpen] = useState(false);
    const toggleNewModalOpen = () => setNewModalOpen(!newModalOpen);

    return (
        <div className="pb-2">

            <div className="row" style={{alignItems: 'center'}}>
                {title && <ToolbarInlineTitle title={title}/>}

                <div className={"col-auto"}>
                    <PrimaryButton onClick={() => setNewModalOpen(true)} title={"New"}/>
                </div>

                <div className={"col"}>
                </div>

                <ToolbarSearch/>

                <div className={"col-auto"}>
                    <ListArchivedDropdown/>
                </div>
            </div>

            <ShipicaProductModal isOpen={newModalOpen} id={id} toggle={toggleNewModalOpen}/>
        </div>
    )
}

export default function Loading(state = {
    orders: "complete",
    funnels: "notstarted",
}, action) {
    switch (action.type) {
        case "ORDERS_LOADING_STATUS":
            return {...state, orders: action.data};
        case "FUNNELS_LOADING_STATUS":
            return {...state, funnels: action.data};
        default:
            return state
    }
}

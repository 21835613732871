import React, {Fragment, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {runFetchOrders} from "../../../../store/sagas/OrdersSaga";
import {useDispatch, useSelector} from "react-redux";
import {Order} from "../../../../typings/Dropton";
import {useListProvider} from "../../../../Providers/ListProvider";
import LoadingComponent from "../../../common/LoadingComponent";
import Table from "../../../shared/table/Table";
import Thead from "../../../shared/table/Thead";
import NoResultsSearch from "../List/NoResultsSearch";

interface Props {
    setOrder: (val: Order) => void;
    activeStatus: string;
    activeOrder: Order
}

export default function SearchOrdersList({setOrder, activeOrder, activeStatus}: Props) {
    const orders = useSelector((state: any) => state.OrdersSearch);

    const columns = ["Order ID", "Order date", "Customer", "Quantity", "Total amount"]


    let grouped: any = {};
    orders.map((item: Order) => {
        if (!(item.funnelId in grouped)) {
            grouped[item.funnelId] = [];
        }
        grouped[item.funnelId].push(item);
    });

    // console.log('grouped', grouped);
    const keys = Object.keys(grouped);


    return (
        <Fragment>
            {keys.map((key: string) => {
                const funnelOrders = grouped[key];
                return <Fragment>
                    <div className="pb-2 pt-4">
                        <h5>{funnelOrders[0].funnelName}</h5>
                        <Table>
                            <Thead columns={columns}/>
                            <tbody>
                            {funnelOrders.map((item: Order, index: number) => {

                                const errorBadge = () => {
                                    const isFieldError = (field: string) => {
                                        // @ts-ignore
                                        return item.contact.errors[field] && item.contact.errors[field].length > 0;
                                    }
                                    if (item.products.filter(i => i.map === 0).length > 0) {
                                        return (<Fragment>
                                            <br/><span className="badge badge-pill badge-danger badge-outline">UNMAPPED</span>
                                        </Fragment>);
                                    } else if (isFieldError('country')) {
                                        return <Fragment><br/><span
                                            className="badge badge-pill badge-light">WRONG COUNTRY</span></Fragment>
                                    } else if (isFieldError('phone')) {
                                        return <Fragment><br/><span
                                            className="badge badge-pill badge-light">WRONG PHONE</span></Fragment>
                                    } else if (isFieldError('state')) {
                                        return <Fragment><br/><span
                                            className="badge badge-pill badge-light">WRONG STATE</span></Fragment>
                                    } else if (isFieldError('address')) {
                                        return <Fragment><br/><span
                                            className="badge badge-pill badge-light">WRONG ADDRESS</span></Fragment>
                                    } else if (isFieldError('zip')) {
                                        return <Fragment><br/><span
                                            className="badge badge-pill badge-light">WRONG ZIP</span></Fragment>
                                    }
                                    return null;
                                };
                                return (
                                    <tr key={"order-" + item.externalId + "-" + item.id} style={{cursor: 'pointer'}}
                                        onClick={() => setOrder(item)}
                                        className={item.externalId === activeOrder.externalId ? "active-order" : ""}>
                                        <th scope={"row"}># {item.externalId}
                                            {item.archived && (<Fragment>
                                                <br/><span
                                                className="badge badge-pill badge-danger badge-outline">ARCHIVED</span>
                                            </Fragment>)
                                            }
                                            {errorBadge()}
                                        </th>
                                        <td>{item.ajCreationTimeTimezone}</td>
                                        <td>{item.contact ? item.contact.name : 'N/A'}</td>
                                        <td>{item.productsCount} unit</td>
                                        <td>{item.productsAmount.toFixed(2)} {item.currency}</td>
                                    </tr>
                                )
                            })}

                            </tbody>
                        </Table>
                    </div>
                </Fragment>
            })}

            {/*<OrderFooter/>*/}
        </Fragment>
    )
}

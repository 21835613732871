import React from "react";

interface Props {
    children: any;
    hasRightSidebar?: boolean;
}

export default function PageContainer({children, hasRightSidebar}: Props) {

    let mainClassName = ["page-body"];
    if (hasRightSidebar) {
        mainClassName.push("with-right-sidebar");
    }
    return (
        <div className={mainClassName.join(' ')}>
            <div className="container-fluid">
                {children}
            </div>
        </div>
    )
}

import React from "react";
import Select from 'react-select'
import {useSelector} from "react-redux";
import {ShipicaProduct} from "../../../../typings/Dropton";

interface Props {
    value: string;
    onChange: (value: Option) => void;
    onlyActive?: boolean;
}

export interface Option {
    value: string;
    label: string;
}

export default function ShipicaProductsSelectComponent({value, onChange, onlyActive}: Props) {
    const products = useSelector((state: any) => state.ShipicaProducts);
    const productsForRender = onlyActive ? products.filter((i: ShipicaProduct) => !i.archived) : products;
    const options = [
        {
            value: '0',
            label: 'Unmapped'
        },
        ...productsForRender.map((item: ShipicaProduct) => {
            return {
                value: item.id.toString(),
                label: item.name + ' (' + item.sku + ')'
            }
        })];
    const selected = options.filter((item: Option) => item.value === value);
    const defValue = selected.length > 0 ? selected[0] : options[0];

    return <Select options={options} value={defValue}
                   onChange={(item: any) => onChange(item)}/>;
}

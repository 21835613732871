import React, {ChangeEvent} from "react";

interface FormGroupInputProps {
    title: string;
    value: string;
    onChange: (val: string) => void;
    size?: string;
    rows?: number;
}

export default function FormGroupTextarea(props: FormGroupInputProps) {

    const size = props.size ? props.size : "";

    let inputClassName = ["form-control"];
    if (size) {
        inputClassName.push("form-control-" + size);
    }

    const rows = props.rows ? props.rows : 3;

    return (<div className="form-group">
        <label>{props.title}:</label>
        <textarea className={inputClassName.join(" ")}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) => props.onChange(e.target.value)}
                  value={props.value}
                  rows={rows}/>
    </div>)
}

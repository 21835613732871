import React from "react";
import Select from 'react-select'

const options = [
    {value: '', label: 'None'},
    {value: 'CAINIAO_STANDARD', label: 'AliExpress Standard Shipping'},
    {value: 'CAINIAO_ECONOMY', label: 'AliExpress Saver Shipping'},
    {value: 'CAINIAO_CONSOLIDATION_SA', label: 'Aliexpress Direct'},
    {value: 'CPAM', label: 'China Post Registered Air Mail'},
    {value: 'YANWEN_JYT', label: 'China Post Ordinary Small Packet Plus'},
    {value: 'YANWEN_AM', label: 'Yanwen Economic Air Mail'},
    {value: 'YANWEN_ECONOMY', label: 'SunYou Economic Air Mail'},
    {value: 'SUNYOU_ECONOMY', label: 'SunYou Economic Air Mail'},
    {value: 'SF_EPARCEL', label: 'SF eParcel'},
    {value: 'POST_NL', label: 'PostNL'},
    {value: 'EMS', label: 'EMS'},
    {value: 'E_EMS', label: 'e-EMS'},
    {value: 'EMS_ZX_ZX_US', label: 'ePacket'},
    {value: 'DHL', label: 'DHL'},
    {value: 'FEDEX', label: 'FedEx'},
    {value: 'FEDEX_IE', label: 'Fedex IE'},
    {value: 'SGP', label: 'Singapore Post'},
    {value: 'TNT', label: 'TNT'},
    {value: 'UPS', label: 'UPS'},
    {value: 'USPS', label: 'USPS'},
    {value: 'CHP', label: 'Swiss Post'},
    {value: 'PTT', label: 'Turkey Post'},
    {value: 'UBI', label: 'UBI'},
    {value: 'TOLL', label: 'DPEX'},
    {value: 'ARAMEX', label: 'ARAMEX'},
    {value: 'GATI', label: 'GATI'},
    {value: 'Other', label: 'Seller\'s Shipping Method'},
];

interface Props {
    value: string;
    onChange: (value: string) => void;
}

export default function ShippingsComponent({value, onChange}: Props) {
    const selected = options.filter((item) => item.value === value);
    const defValue = selected.length > 0 ? selected[0] : options[0];
    return <Select options={options} defaultValue={defValue} onChange={(item : any) => onChange(item && item.value ? item.value : '')}/>;
}
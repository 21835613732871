import React, {Fragment, useEffect, useState} from "react";
import {Collapse} from "reactstrap";
import {SettingsStepProps} from "../types";
import CardHeaderCollapse from "../../../../page/card/CardHeaderCollapse";
import Api from "../../../../../services/ApiFunnels";
import SaveRow from "../../Components/SaveRow";
import {toast} from "react-toastify";
import SettingsAfterTitle from "../../Components/SettingsAfterTitle";
import ActivatedCheckbox from "../../Components/ActivatedCheckbox";
import {FormProvider} from "../../../../shared/form/FormProvider";
import {useSelector} from "react-redux";
import {FunnelGeneralInfo} from "../../../../../typings/Dropton";
import {LctlogisticProduct, LctlogisticSettings} from "../../../../../typings/Settings";
import ProviderFormGroupInput from "../../../../shared/form/provider-fields/ProviderFormGroupInput";
import {ShipbobProduct} from "../../../../../typings/Shipbob";

const texts = {
    title: "Lctlogistic settings"
};

export default function FunnelSettingsLctlogistic({index, opened, onCollapse, id}: SettingsStepProps) {
    const isOpened = index === opened;
    const [isDataChange, setIsDataChange] = useState(false);
    const [settings, setSettings] = useState<LctlogisticSettings>();
    const token = useSelector((state: any) => state.Auth.token);
    const [funnel, setFunnel] = useState<FunnelGeneralInfo>();


    const getData = () => {
        Api.getFunnelLctlogisticSettings(id).then(res => {
            if (res.success === 1) {
                setSettings(res.data);
            }
        })
        Api.getFunnelGeneralInfo(id).then(res => {
            if (res.success === 1) {
                setFunnel(res.data);
            }
        });
    };

    const saveData = () => {
        Api.setFunnelLctlogisticSettings(settings, id).then(res => {
            if (res.success === 1) {
                setIsDataChange(false);
                toast.success("Settings saved.");
            } else {
                toast.error("Error!");
            }
        })
    };

    useEffect(() => {
        getData();
    }, []);


    const onActivatedChange = (val: boolean) => {
        if (settings) {
            let f = {...settings};
            f.activated = val;
            setSettings(f);
            setIsDataChange(true);
        }
    };

    const afterTitle = settings ?
        <SettingsAfterTitle activated={settings.activated} isDataChange={isDataChange}/> : null;

    return (
        <Fragment>
            <div className={"card"}>
                <CardHeaderCollapse title={texts.title}
                                    onCollapse={() => onCollapse(isOpened ? -1 : index)}
                                    isOpened={isOpened}
                                    afterTitle={afterTitle}
                />
                <Collapse isOpen={opened === index}>
                    <div className="card-body">

                        {settings &&
                        <Fragment>
                            <FormProvider formData={settings} setFormData={setSettings}
                                          setIsDataChange={setIsDataChange}>

                                <div className="row">
                                    <div className="col-6">

                                        <ActivatedCheckbox activated={settings.activated}
                                                           onActivatedChange={onActivatedChange}
                                                           inputKey={"lctlogistic"}
                                        />

                                    </div>
                                    <div className="col-6">
                                        {/*<PaypalHelpText/>*/}
                                    </div>
                                </div>

                                <ProviderFormGroupInput title={"Token"} inputKey={"token"}/>

                                <SaveRow isDataChange={isDataChange} saveData={saveData}/>

                                {settings.products &&
                                <div className={"row mt-4 mb-4"}>
                                    <table className="table table-striped no-table-th-border">
                                        <thead>
                                        <tr>
                                            <th scope="col">ID</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">SKU</th>
                                            <th scope="col">Code</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {settings.products.map((product: LctlogisticProduct) => {
                                            return (
                                                <tr key={"product-key-" + product.GoodsID}>
                                                    <th scope={"row"}>{product.GoodsID}</th>
                                                    <td>
                                                        {product.EnName}
                                                    </td>
                                                    <td>
                                                        {product.SKU}
                                                    </td>
                                                    <td>
                                                        {product.GoodsCode}
                                                    </td>

                                                </tr>
                                            );
                                        })}

                                        </tbody>
                                    </table>
                                </div>
                                }

                            </FormProvider>
                        </Fragment>
                        }
                    </div>
                </Collapse>
            </div>
        </Fragment>
    )
}

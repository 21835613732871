const InitialState = {
    enabled: false,
    id: ''
};

export default function Ext(state = InitialState, action) {
    switch (action.type) {
        case 'EXT_ENABLED':
            return {...state, enabled: action.payload, id: action.id};
        default:
            return state
    }
}

import React from "react";
import {Modal, ModalBody} from "reactstrap";
import {useSelector} from "react-redux";

interface Props {
    onPress: () => void,
}

export default function OnBoardingPopup(props: Props) {
    const funnels = useSelector((state: any) => state.Funnels);


    return (
        <Modal isOpen={funnels.length === 0}>
            <ModalBody>

                <div style={{
                    fontSize: 16,
                    padding: 30,
                    color: '#6D717A'
                }}>

                    <h2 className="text-center">Welcome to Dropton</h2>
                    <p className="text-center" style={{margin: '30px 0'}}>Start placing ClickFunnel products via
                        Aliexpress in few
                        clicks</p>

                    <div className="text-center">
                        <button className="btn btn-secondary btn-dropton" onClick={props.onPress}>
                            Let's get started
                        </button>
                    </div>
                </div>

            </ModalBody>
        </Modal>
    )
}

import React, {Fragment, useEffect, useState} from "react";
import {Collapse} from "reactstrap";
import {SettingsStepProps} from "./types";
import CardHeaderCollapse from "../../../page/card/CardHeaderCollapse";
import SaveRow from "../Components/SaveRow";
import Api from "../../../../services/ApiFunnels";
import {toast} from "react-toastify";
import SettingsAfterTitle from "../Components/SettingsAfterTitle";
import ActivatedCheckbox from "../Components/ActivatedCheckbox";

interface Settings {
    activated: boolean;
}

const texts = {
    title: "Automatic aliexpress captcha resolver"
};

export default function FunnelSettingsCaptcha({index, opened, onCollapse, id}: SettingsStepProps) {
    const isOpened = index === opened;
    const [isDataChange, setIsDataChange] = useState(false);
    const [settings, setSettings] = useState<Settings>();

    const getData = () => {
        Api.getFunnelAliexpressCaptchaSettings(id).then(res => {
            if (res.success === 1) {
                setSettings(res.data);
            }
        })
    };

    const saveData = () => {
        Api.setFunnelAliexpressCaptchaSettings(settings, id).then(res => {
            if (res.success === 1) {
                setIsDataChange(false);
                toast.success("Settings saved.");
            } else {
                toast.error("Error!");
            }
        })
    };

    useEffect(() => {
        getData();
    }, []);

    const onActivatedChange = (val: boolean) => {
        console.log('onActivatedChange', val);
        if (settings) {
            let f = {...settings};
            f.activated = val;
            setSettings(f);
            setIsDataChange(true);
        }
    };

    const afterTitle = settings ?
        <SettingsAfterTitle activated={settings.activated} isDataChange={isDataChange}/> : null;

    return (
        <Fragment>
            <div className={"card"}>
                <CardHeaderCollapse title={texts.title} onCollapse={() => onCollapse(isOpened ? -1 : index)}
                                    isOpened={isOpened}
                                    afterTitle={afterTitle}/>
                <Collapse isOpen={opened === index}>
                    <div className="card-body">
                        {settings && <Fragment>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <ActivatedCheckbox activated={settings.activated}
                                                       onActivatedChange={onActivatedChange}
                                                       inputKey={"captcha"}
                                    />
                                    <SaveRow isDataChange={isDataChange} saveData={saveData}/>
                                </div>
                            </div>
                        </Fragment>}
                    </div>
                </Collapse>
            </div>
        </Fragment>
    )
}
import React, {Fragment} from "react";
import ListToolbar from "./ListToolbar";
import OrdersList from "./List/OrdersList";
import NoOrdersYet from "./List/NoOrdersYet";
import {Funnel, Order} from "../../../typings/Dropton";
import {useListProvider} from "../../../Providers/ListProvider";

interface Props {
    funnel: Funnel,
    syncTracking: () => void,
    setActiveOrder: (val?: Order) => void
}

export default function FunnelOrdersPageToolbar({funnel, syncTracking, setActiveOrder}: Props) {

    const {activeStatus, setActiveStatus} = useListProvider();

    return (
        <Fragment>
            <ListToolbar syncTracking={syncTracking} title={funnel.name}
                         activeStatus={activeStatus}
                         setActiveStatus={setActiveStatus}/>
            {funnel.stats.allOrdersCount > 0 ?
                <OrdersList activeStatus={activeStatus} setOrder={setActiveOrder}/>
                :
                <NoOrdersYet/>
            }
        </Fragment>
    )
}
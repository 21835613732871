import React, {Fragment} from 'react';
import Header from './common/header-component/header';
import Sidebar from './common/sidebar-component/sidebar';

import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from './common/loader';
import NoExtensionPopup from "./ext/NoExtensionPopup";
import {isBrowser} from 'react-device-detect';


const AppLayout = ({children}) => {
    return (
        <div>
            <Loader/>
            <div className="page-wrapper">
                <div className="page-body-wrapper">

                    {isBrowser && <Fragment>
                        <Header/>
                        <Sidebar/>
                    </Fragment>}


                    {children}

                    {/* <Footer /> */}
                </div>
            </div>
            <ToastContainer/>
            <NoExtensionPopup/>
        </div>
    );
}

export default AppLayout;

import {Api} from "./Api";
import {BackendConfig} from "../config/BackendConfig";
import {ContactFormFields, OrderFormFields} from "../typings/Dropton";

class ApiOrders extends Api {
    fetchOrders = (funnelId: number, page: number, activeStatus: string, search: string, paging: number, ordering: string) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({funnelId, page, activeStatus, search, paging, ordering})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/funnel/orders', requestOptions).then(res => res.json());
    };

    fetchFulfilledOrders = (funnelId: number) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({funnelId})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/orders/fulFilledOrders', requestOptions).then(res => res.json());
    };

    fetchStatsOrders = (funnelId: number, dateFrom: string, dateTo: string, paymentType: string) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({funnelId, dateFrom, dateTo, paymentType})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/orders/statsOrders', requestOptions).then(res => res.json());
    };

    downloadStatsOrders = (funnelId: number, dateFrom: string, dateTo: string, paymentType: string) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({funnelId, dateFrom, dateTo, paymentType})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/orders/downloadStatsOrders', requestOptions).then(res => res.json());
    };

    searchOrders = (search: string, pageSize: number) => {

        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({search, pageSize})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/orders/search', requestOptions).then(res => res.json());
    };

    searchSupportOrders = (search: string, ids: string, pageSize: number) => {

        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({search, pageSize, ids})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/ordersSupport/search', requestOptions).then(res => res.json());
    };

    getMailHistory = (orderId: number) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: orderId})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/orders/mailHistory', requestOptions).then(res => res.json());
    };

    getOrderShipbobDetails = (orderId: number) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: orderId})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/orders/shipbobDetails', requestOptions).then(res => res.json());
    };

    getOrderShipicaDetails = (orderId: number) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: orderId})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/orders/shipicaDetails', requestOptions).then(res => res.json());
    };

    getOrderLctlogisticDetails = (orderId: number) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: orderId})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/orders/lctlogisticDetails', requestOptions).then(res => res.json());
    };


    update = (orderId: number, details: OrderFormFields) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: orderId, ...details})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/orders/update', requestOptions).then(res => res.json());
    }

    addManualProducts = (orderId: number, products: number[], shipbobProducts: number[], shipicaProducts: number[], lctlogisticProducts: number[]) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: orderId, products, shipbobProducts, shipicaProducts, lctlogisticProducts})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/orders/addManualProducts', requestOptions).then(res => res.json());
    }

    createOrder = (funnelId: number, contact: ContactFormFields, products: number[]) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: funnelId, products, ...contact})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/orders/create', requestOptions).then(res => res.json());
    }

    removeManualProduct = (orderId: number, productId: number) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: orderId, productId})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/orders/removeManualProduct', requestOptions).then(res => res.json());
    }
}

export default new ApiOrders();

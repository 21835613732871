import React, {Fragment, useEffect, useState} from "react";
import {Order} from "../../../../../typings/Dropton";
import Separator from "./Separator";
import ShowHideComponent from "./ShowHideComponent";
import ApiOrders from "../../../../../services/ApiOrders";
import moment from "moment";

interface Props {
    order: Order;
}

interface Event {
    event: string;
    timestamp: number;
}

export default function TrackingMailCard({order}: Props) {
    const [expand, setExpand] = useState(false);
    const [data, setData] = useState<Event[]>([]);

    const getData = () => {
        ApiOrders.getMailHistory(order.id).then(res => {
            setData(res.data);
        });
    };

    useEffect(() => {
        getData();
    }, [expand, order]);

    if (!order.trackingId) {
        return <Fragment/>
    }

    return (
        <Fragment>
            <Separator/>

            <div className="row no-gutters">
                <div className="col">
                    <strong>Tracking mail details</strong>
                </div>

                <ShowHideComponent expand={expand} setExpand={setExpand}/>
            </div>
            {expand &&
            <div className="row no-gutters">
                <table className="simple-table">
                    <tbody>
                    {data.map((item, index) => {
                        return (
                            <tr>
                                <td>{item.event}:</td>
                                <td>{moment(item.timestamp * 1000).format()}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
            }

        </Fragment>
    )
}
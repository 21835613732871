import React from "react";
import DroptonAssets from "../../../../assets/images/dropton/DroptonAssets";
import StepContactText from "./StepContactText";
import StepTitle from "./StepTitle";
import StepText from "./StepText";
import {StepWizardChildProps} from "react-step-wizard";
import StepNavigation from "./StepNavigation";

export default function Step6(props: StepWizardChildProps) {
    const texts = {
        title: "Almost finished"
    };
    return (
        <div className="get-started" data-step="6">
            <StepTitle title={texts.title}/>

            <div className="text-center">
                <img src={DroptonAssets.getStarted["6"]} alt={texts.title}/>
            </div>
            <p className="text-center">
                In the Event box, select All events. After that click on button "Create Funnel
                Webhook"
            </p>

            <StepNavigation {...props}/>

            <StepText currentStep={props.currentStep} allSteps={props.totalSteps} text={texts.title}/>
            <StepContactText/>
        </div>
    )
}
import React from "react";
import PrimaryButton from "../../../navigation/components/PrimaryButton";
import {useForm} from "../FormProvider";

interface Props {
    title: string;
    onClick: () => void;
}

export default function FormPrimaryButton({title, onClick}: Props) {
    const {isDataChange} = useForm();

    return (<PrimaryButton title={title} onClick={onClick} disabled={!isDataChange}/>)
}

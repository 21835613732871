import React from "react";

interface Props {
    activated: boolean;
    onActivatedChange: (activated: boolean) => void;
    helpText?: string;
    inputKey: string;
}

export default function ActivatedCheckbox({activated, onActivatedChange, helpText, inputKey}: Props) {
    return (
        <div className="form-group m-checkbox-inline ml-1">
            <div
                className={"checkbox " + (activated ? "checkbox-success" : "checkbox-primary")}>
                <input type="checkbox" id={inputKey} checked={activated}
                       onChange={() => onActivatedChange(!activated)}
                       onClick={() => onActivatedChange(!activated)}/>
                <label className="mb-0" htmlFor={inputKey}>Activate</label>
            </div>
            {helpText && <div className="txt-grey">
                {helpText}
            </div>}
        </div>
    );
}
import React, {Fragment} from "react";
import AttributeValueComponent from "../AttributeValueComponent";
import {AliProduct} from "../../../../../../typings/Aliexpress";

interface Props {
    item: AliProduct,
    index: number,
    setAliUrl: (index: number, val: string) => void,
    fetchUrl: (index: number) => void,
    removeProduct: (index: number) => void,
    setQuantity: (index: number, val: number) => void,
    setAttributeValue: (index: number, attrIndex: number, val: number) => void,
}

export default function ChildItem({item, index, setAliUrl, fetchUrl, removeProduct, setAttributeValue, setQuantity}: Props) {

    const canRemove = index > 0;
    return (
        <Fragment>
            {index > 0 && <hr/>}
            <div className="row d-flex align-items-center" key={"product-row-" + index}>
                <div className="col">
                    <div className="form-group">
                        <label>AliExpress URL</label>
                        <input type="text"
                               className="form-control"
                               value={item.url}
                               onChange={(e) => setAliUrl(index, e.target.value)}
                        />
                    </div>
                </div>
                <div className="col-auto">
                    <button className="btn btn-light mt-2" type="button"
                            onClick={() => fetchUrl(index)}>
                        Fetch url
                    </button>
                </div>

                {canRemove &&
                <div className="col-auto pt-10">
                    <button onClick={() => removeProduct(index)}
                            className="btn btn-link txt-secondary">X Remove URL
                    </button>
                </div>
                }

            </div>
            {item.fetched &&
            (
                <div className="row">
                    <div className="col-3">
                        <div className="form-group">
                            <label htmlFor="name">Quantity</label>
                            <input className="form-control" type="number" min="0"
                                   value={item.quantity}
                                   onChange={(e) => setQuantity(index, parseInt(e.target.value, 10))}/>

                        </div>
                    </div>

                    {item.attributes.map((attr, attrIndex) => {
                        return (
                            <div className="col-3" key={"attr-" + index + "-" + attrIndex}>
                                <div className="form-group">
                                    <label htmlFor="name">{attr.title}</label>
                                    <AttributeValueComponent
                                        onChange={(value: number) => {
                                            setAttributeValue(index, attrIndex, value);
                                        }}
                                        value={attr.selectedValue}
                                        options={attr.children}
                                    />
                                </div>
                            </div>
                        )
                    })}

                </div>)}
        </Fragment>
    )
};

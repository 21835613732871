import React from 'react'
import {MenuItem} from '../../../../typings/Menu'
import {Link} from 'react-router-dom';

interface Props {
    menuItem: MenuItem;
    onPress: () => void;
}

export default function SidebarMenuItemLink({menuItem, onPress}: Props) {
    const hasBadge = (!!menuItem.badge && menuItem.badge > 0);
    const maxCharacters = hasBadge ? 10 : 15;
    const title = menuItem.title.length > maxCharacters ? menuItem.title.substr(0, maxCharacters) + '...'
        :
        menuItem.title;


    return (
        <Link
            to={`${process.env.PUBLIC_URL}${menuItem.path}?nonce=${new Date().getTime()}`}
            className={`sidebar-header ${menuItem.active ? 'active' : ''}`}
            onClick={onPress}
        >
            {!!menuItem.icon && <menuItem.icon/>}<span title={menuItem.title}>{title}</span>
            {!!menuItem.children ?
                <i className="fa fa-angle-right pull-right"></i> : ''}
            {hasBadge &&
            <span className="badge badge-light pull-right mt-2 ml-0">{menuItem.badge}</span>}
        </Link>
    )
}

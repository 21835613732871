import React, {Fragment, useEffect, useState} from "react";
import PageContainer from "../../shared/page/PageContainer";
import ToolbarTitle from "../../page/toolbar/ToolbarTitle";
import ApiUser from "../../../services/ApiUser";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {loadStripe} from "@stripe/stripe-js";
import {BackendConfig} from "../../../config/BackendConfig";
import {CardElement, Elements} from "@stripe/react-stripe-js";
import UpdateCardBtn from "./UpdateCardBtn";

interface Invoice {
    id: number,
    pdf: string,
    date: string,
    number: string,
    amount: number,
    status: string
}

interface Card {
    brand: string,
    last4: number,
    exp_month: number,
    exp_year: number
}

interface IData {
    invoices: Invoice[],
    cards: Card[],
    subscriptions: Subscription[],
    valid: boolean,
    validTill: string,
}

interface Subscription {
    id: number,
    endDate: string,
    amount: number,
    status: string,
    cancel_at_period_end: boolean,
}

const stripePromise = loadStripe(BackendConfig.stripePkKey);

export default function PaymentsPage() {
    const dispatch = useDispatch();
    const [data, setData] = useState<IData>();

    const getData = () => {
        ApiUser.getPayments().then(res => {
            setData(res.data);
        })
    }

    const cancelSubscription = (id: number) => {
        ApiUser.cancelSubscription(id).then((res) => {
            if (res.success === 1) {
                toast.success("Cancellation request sent");
            } else {
                toast.error("Error, please contact support");
            }
            getData();
        })
    }

    const doSubscribe = () => {
        dispatch({type: 'SET_NEED_PAYMENT'});
    }

    useEffect(getData, []);


    return (
        <PageContainer>
            <ToolbarTitle title={"Payments"} skipBack={true}/>

            {!!data && <Fragment>
                {(data.cards.length > 0 || data.subscriptions.length > 0) &&
                <div className={"row"}>
                    <div className={"col-6"}>
                        <div className={"card"}>
                            <div className={"card-header pb-2 pt-4"}>
                                <h6>Payment card</h6>
                            </div>
                            <div className={"card-body"}>
                                {data.cards.map((card: Card, index: number) => {
                                    return (
                                        <div key={"card-" + index}>
                                            <span
                                                style={{textTransform: 'capitalize'}}>{card.brand}</span> ending {card.last4}<br/>
                                            Expires on {card.exp_month} / {card.exp_year}
                                        </div>
                                    )
                                })}

                                <Elements stripe={stripePromise}>
                                    <Fragment>
                                        <h6 className={"mt-4 mb-4"}>Update payment method</h6>

                                        <CardElement
                                            options={{
                                                style: {
                                                    base: {
                                                        fontSize: '16px',
                                                        color: '#32325d',
                                                        '::placeholder': {
                                                            color: '#aab7c4',
                                                        },
                                                    },
                                                    invalid: {
                                                        color: '#fa755a',
                                                        iconColor: '#fa755a',
                                                    },
                                                },
                                            }}
                                        />

                                        <div className="text-right mt-4">
                                            <UpdateCardBtn/>
                                        </div>
                                    </Fragment>
                                </Elements>
                            </div>


                        </div>
                    </div>
                    <div className={"col-6"}>
                        <div className={"card"}>
                            <div className={"card-header pb-2 pt-4"}>
                                <h6>Next payment</h6>
                            </div>
                            <div className={"card-body"}>
                                {data.subscriptions.map((item: Subscription) => {
                                    return (
                                        <div key={"sub-" + item.id} className={"mb-2 row"}>
                                            <div className={"col"}>
                                                {item.status === 'active' && !item.cancel_at_period_end ? <Fragment>
                                                    Your plan will automatically renew on {item.endDate} and you'be
                                                    charged
                                                    ${item.amount.toFixed(2)}.
                                                </Fragment> : <Fragment>
                                                    Plan active till {item.endDate}
                                                </Fragment>}

                                            </div>
                                            <div className={"col-auto text-right"}>
                                                {item.status === 'active' && !item.cancel_at_period_end ?
                                                    <button className={"btn btn-outline-danger"}
                                                            onClick={() => cancelSubscription(item.id)}>Cancel
                                                        membership</button> : ''}
                                            </div>
                                        </div>
                                    )
                                })}

                            </div>
                        </div>
                    </div>
                </div>
                }
                {!(data.cards.length > 0 || data.subscriptions.length > 0) && data.valid &&
                <div className={"row"}>
                    <div className={"col"}>
                        <div className={"card"}>
                            <div className={"card-header pb-2 pt-4"}>
                                <h6>Dropton Free Trial</h6>
                            </div>
                            <div className={"card-body"}>
                                <div className={"row"}>
                                    <div className={"col"}>
                                        Your 14-day free trial lasts until midnight on {data.validTill}.<br/>
                                        After that, you will need to upgrade to a paid plan to continue placing orders.
                                    </div>
                                    <div className={"col-auto text-right"}>
                                        <button className={"btn btn-success"} onClick={doSubscribe}>Subscribe</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
                <div className={"row"}>
                    <div className={"col"}>
                        <div className={"card"}>
                            <div className={"card-header pb-2 pt-4"}>
                                <h6>Invoices</h6>
                            </div>
                            <div className={"card-body"}>
                                <table className={"table"}>
                                    <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Invoice no</th>
                                        <th className={"text-right"}>Amount</th>
                                        <th>Payment status</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data.invoices.map((invoice: Invoice) => {
                                        return (
                                            <tr>
                                                <td>{invoice.date}</td>
                                                <td><a href={invoice.pdf} target={"_blank"}>{invoice.number}</a></td>
                                                <td className={"text-right"}>{invoice.amount.toFixed(2)}</td>
                                                <td>{invoice.status}</td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>}

        </PageContainer>
    )
}

import React, {Fragment, useEffect, useState} from "react";
import {Order} from "../../../../../typings/Dropton";
import Separator from "./Separator";
import ShowHideComponent from "./ShowHideComponent";
import ApiPlaceOrder from "../../../../../services/ApiPlaceOrder";
import {toast} from "react-toastify";
import ApiOrders from "../../../../../services/ApiOrders";

interface Props {
    order: Order;
}

interface Event {
    event: string;
    timestamp: number;
}

export default function LctlogisticCard({order}: Props) {
    const [expand, setExpand] = useState(false);
    const [data, setData] = useState<any>([]);

    const getData = () => {
        ApiOrders.getOrderLctlogisticDetails(order.id).then(res => {
            setData(res.data);
        });
    };

    useEffect(() => {
        getData();
    }, [expand, order]);

    const sendToLctlogistic = () => {
        ApiPlaceOrder.placeLctlogistic(order.id).then(res => {
            if (res.success === 1) {
                toast.success("Success");
            } else {
                toast.error("Error");
            }
        });
    }

    const parseData = () => {
        const keys = Object.keys(data);
        return <div className={"row"}>
            <div className={"col-12"}>
                <pre>{JSON.stringify(data, null, 2)}</pre>
            </div>
        </div>

    }

    return (
        <Fragment>
            <Separator/>

            <div className="row no-gutters">
                <div className="col">
                    <strong>Lctlogistic details</strong>
                </div>

                <ShowHideComponent expand={expand} setExpand={setExpand}/>
            </div>
            {expand &&
            <div className="row no-gutters">
                <div className="col">
                    <button type={"button"} className={"btn btn-success"} onClick={sendToLctlogistic}>Send to
                        lctlogistic
                    </button>
                    {!!data && parseData()}
                </div>

            </div>
            }

        </Fragment>
    )
}

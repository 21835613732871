import React from "react";

interface Props {
    children: any;
}

export default function AccordionRow({children} : Props) {
    return (
        <div className="row default-according style-1 faq-accordion">
            <div className="col" style={{paddingBottom: 200}}>
                {children}
            </div>
        </div>
    )
}

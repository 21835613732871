import React, {Fragment, useEffect, useState} from 'react';

import UserPanel from './userPanel';
import {MENUITEMS} from '../../../config/Menu';
import configDB from '../../../config/DesignConfig';
import {MenuItem} from '../../../typings/Menu'
import SidebarLogoWrapper from './components/SidebarLogoWrapper';
import SidebarMenuItems from './components/SidebarMenuItems';

const Sidebar = () => {
    const [margin, setMargin] = useState(0);
    const [width, setWidth] = useState(0);
    const [mainmenu, setMainMenu] = useState<MenuItem[]>(MENUITEMS);
    const wrapper = configDB.data.settings.sidebar.wrapper;

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        handleResize();

        var currentUrl = window.location.pathname;

        // eslint-disable-next-line
        // mainmenu.filter(items => {
        //     if (items.path === currentUrl)
        //         setNavActive(items)
        //     if (!items.children) return false
        //     // eslint-disable-next-line
        //     items.children.filter(subItems => {
        //         if (subItems.path === currentUrl)
        //             setNavActive(subItems)
        //         if (!subItems.children) return false
        //         // eslint-disable-next-line
        //         subItems.children.filter(subSubItems => {
        //             if (subSubItems.path === currentUrl)
        //                 setNavActive(subSubItems)
        //         })
        //     })
        // });

        return () => {
            // eslint-disable-next-line
            window.addEventListener('resize', handleResize)
        }
        // eslint-disable-next-line
    }, []);

    const handleResize = () => {
        setWidth(window.innerWidth - 310);
    };

    const setNavActive = (item: MenuItem) => {
        // // eslint-disable-next-line
        // MENUITEMS.filter(menuItem => {
        //     // eslint-disable-next-line
        //     if (menuItem != item)
        //         menuItem.active = false
        //     if (menuItem.children && menuItem.children.includes(item))
        //         menuItem.active = true
        //     if (menuItem.children) {
        //         // eslint-disable-next-line
        //         menuItem.children.filter(submenuItems => {
        //             if (submenuItems.children && submenuItems.children.includes(item)) {
        //                 menuItem.active = true
        //                 submenuItems.active = true
        //             }
        //         })
        //     }
        // })
        // item.active = !item.active
        // setMainMenu({ mainmenu: MENUITEMS })

    }

    // Click Toggle menu
    const toggletNavActive = (item: MenuItem) => {
        // if (!item.active) {
        //     MENUITEMS.forEach(a => {
        //         // @ts-ignore
        //         if (MENUITEMS.includes(item))
        //             a.active = false
        //         if (!a.children) return false
        //         a.children.forEach(b => {
        //             // @ts-ignore
        //             if (a.children.includes(item)) {
        //                 // @ts-ignore
        //                 b.active = false
        //             }
        //             // @ts-ignore
        //             if (!b.children) return false
        //             // @ts-ignore
        //             b.children.forEach(c => {
        //                 // @ts-ignore
        //                 if (b.children.includes(item)) {
        //                     c.active = false
        //                 }
        //             })
        //         })
        //     });
        // }
        // item.active = !item.active
        // setMainMenu(MENUITEMS)
    }

    const scrollToRight = () => {
        const elmnt = document.getElementById("myDIV");
        const menuWidth = elmnt!.offsetWidth;
        const temp = menuWidth + margin;
        if (temp < menuWidth) {
            setMargin(-temp);
        } else {
            setMargin(margin => margin += (-width));
        }
    }

    const scrollToLeft = () => {
        // If Margin is reach between screen resolution
        if (margin >= -width) {
            setMargin(0)
        } else {
            setMargin(margin => margin += width);
        }
    }

    return (
        <Fragment>
            <div className="page-sidebar">
                <SidebarLogoWrapper/>
                <div className="sidebar custom-scrollbar">
                    <UserPanel/>
                    <ul
                        className="sidebar-menu"
                        id="myDIV"
                        style={wrapper === 'horizontal_sidebar' ? {'marginLeft': margin + 'px'} : {margin: '0px'}}
                    >
                        <li className={`left-arrow`}
                            onClick={scrollToLeft}><i className="fa fa-angle-left"></i></li>

                        <SidebarMenuItems menuItems={MENUITEMS} toggletNavActive={toggletNavActive}/>

                        <li className={`right-arrow`} onClick={scrollToRight}><i className="fa fa-angle-right"></i></li>
                    </ul>
                </div>
            </div>
        </Fragment>
    );
};

export default Sidebar;


import React, {Fragment, useEffect, useState} from "react";
import {Collapse} from "reactstrap";
import {SettingsStepProps} from "./types";
import CardHeaderCollapse from "../../../page/card/CardHeaderCollapse";
import PaypalHelpText from "../Components/PaypalHelpText";
import Api from "../../../../services/ApiFunnels";
import SaveRow from "../Components/SaveRow";
import {toast} from "react-toastify";
import SettingsAfterTitle from "../Components/SettingsAfterTitle";
import ActivatedCheckbox from "../Components/ActivatedCheckbox";
import {FormProvider} from "../../../shared/form/FormProvider";
import ProviderFormGroupTextarea from "../../../shared/form/provider-fields/ProviderFormGroupTextarea";

interface Settings {
    activated: boolean;
    clientId: string;
    secretCode: string;
}

const texts = {
    title: "PayPal settings"
};

export default function FunnelSettingsPaypal({index, opened, onCollapse, id}: SettingsStepProps) {
    const isOpened = index === opened;
    const [isDataChange, setIsDataChange] = useState(false);
    const [settings, setSettings] = useState<Settings>();

    const getData = () => {
        Api.getFunnelPaypalSettings(id).then(res => {
            if (res.success === 1) {
                setSettings(res.data);
            }
        })
    };

    const saveData = () => {
        Api.setFunnelPaypalSettings(settings, id).then(res => {
            if (res.success === 1) {
                setIsDataChange(false);
                toast.success("Settings saved.");
            } else {
                toast.error("Error!");
            }
        })
    };

    useEffect(() => {
        getData();
    }, []);


    const onClientIdChange = (val: string) => {
        if (settings) {
            let f = {...settings};
            f.clientId = val;
            setSettings(f);
            setIsDataChange(true);
        }
    };
    const onSecretCodeChange = (val: string) => {
        if (settings) {
            let f = {...settings};
            f.secretCode = val;
            setSettings(f);
            setIsDataChange(true);
        }
    };

    const onActivatedChange = (val: boolean) => {
        if (settings) {
            let f = {...settings};
            f.activated = val;
            setSettings(f);
            setIsDataChange(true);
        }
    };

    const afterTitle = settings ?
        <SettingsAfterTitle activated={settings.activated} isDataChange={isDataChange}/> : null;

    return (
        <Fragment>
            <div className={"card"}>
                <CardHeaderCollapse title={texts.title}
                                    onCollapse={() => onCollapse(isOpened ? -1 : index)}
                                    isOpened={isOpened}
                                    afterTitle={afterTitle}
                />
                <Collapse isOpen={opened === index}>
                    <div className="card-body">

                        {settings &&
                        <Fragment>
                            <FormProvider formData={settings} setFormData={setSettings}
                                          setIsDataChange={setIsDataChange}>

                                <div className="row">
                                    <div className="col-6">

                                        <ActivatedCheckbox activated={settings.activated}
                                                           onActivatedChange={onActivatedChange}
                                                           inputKey={"paypal"}
                                        />

                                        <ProviderFormGroupTextarea title={"Client ID"} inputKey={"clientId"} rows={5}/>

                                        <ProviderFormGroupTextarea title={"Secret code"} inputKey={"secretCode"}
                                                                   rows={5}/>

                                    </div>
                                    <div className="col-6">
                                        <PaypalHelpText/>
                                    </div>
                                </div>
                                <SaveRow isDataChange={isDataChange} saveData={saveData}/>
                            </FormProvider>
                        </Fragment>
                        }
                    </div>
                </Collapse>
            </div>
        </Fragment>
    )
}

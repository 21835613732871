import React from "react";

interface Props {
    columns: string[]
}

export default function Thead({columns}: Props) {
    return (
        <thead>
        <tr>
            {columns.map((col: string, index: number) => {
                return (<th key={"col-" + index} scope="col">{col}</th>)
            })}

        </tr>
        </thead>
    )
}

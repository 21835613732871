import React, {Fragment, useState} from "react";
import {Order} from "../../../../../typings/Dropton";
import Separator from "./Separator";
import ShowHideComponent from "./ShowHideComponent";
import {useAuthNewAgeErp} from "../../../../../Providers/AuthNewAgeErpProvider";

interface Props {
    order: Order;
    setContactEditMode: (val: boolean) => void;
}

export default function ContactDetailsCard({order, setContactEditMode}: Props) {
    const [expand, setExpand] = useState(true);
    // const {isAuthenticated} = useAuthNewAgeErp();

    const errorsForField = (field: string) => {
        if (field in order.contact.errors) {
            // @ts-ignore
            const errors = order.contact.errors[field];
            if (errors && errors.length > 0) {

                return (<i className="icon icon-alert txt-red mr-2"
                           title={errors.join(", ")}></i>);
            }
        }
        return null;
    };

    const errorForContact = () => {
        let globalErrors: string[] = [];
        const keys = Object.keys(order.contact.errors);
        keys.map(field => {
            if (field in order.contact.errors) {
                // @ts-ignore
                const errors = order.contact.errors[field];
                if (errors && errors.length > 0) {
                    globalErrors = [...globalErrors, ...errors];
                }
            }
        });
        globalErrors = globalErrors.filter((v, i, a) => a.indexOf(v) === i);

        if (globalErrors.length > 0) {
            return (<i className="icon icon-alert txt-red mr-2"
                       title={globalErrors.join(", ")}></i>);
        }

        return null;
    }

    return (
        <Fragment>
            <Separator/>

            <div className="row no-gutters">
                <div className="col">
                    <strong>Customer details {!expand && errorForContact()}</strong>
                </div>

                <div className="col-3 text-right">
                    {/*{isAuthenticated &&*/}
                    <button className={"btn btn-link txt-secondary p-0"}
                            onClick={() => setContactEditMode(true)}><i
                        className="icon s-4 icon-pencil mr-1 txt-secondary"></i>Edit
                    </button>
                    {/*}*/}
                </div>

                <ShowHideComponent expand={expand} setExpand={setExpand}/>
            </div>
            {expand &&
            <div className="row no-gutters">
                <table className="simple-table">
                    <tbody>
                    <tr>
                        <td>Name:</td>
                        <td>{errorsForField('name')}{order.contact.name}</td>
                    </tr>
                    <tr>
                        <td>Email:</td>
                        <td>{errorsForField('email')}{order.contact.email}</td>
                    </tr>
                    <tr>
                        <td>Phone:</td>
                        <td>
                            {errorsForField('phone')}{order.contact.phone}
                        </td>
                    </tr>
                    <tr>
                        <td>Country:</td>
                        <td>
                            {errorsForField('country')}{order.contact.country}
                        </td>
                    </tr>
                    <tr>
                        <td>State:</td>
                        <td>
                            {errorsForField('state')}{order.contact.state}
                        </td>
                    </tr>
                    <tr>
                        <td>City:</td>
                        <td>{errorsForField('city')}{order.contact.city}</td>
                    </tr>
                    <tr>
                        <td>ZIP:</td>
                        <td>
                            {errorsForField('zip')}{order.contact.zip}
                        </td>
                    </tr>
                    <tr>
                        <td>Address:</td>
                        <td>
                            {errorsForField('address')}{order.contact.address}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            }

        </Fragment>
    )
}

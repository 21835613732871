import {all} from "redux-saga/effects";
import {
    watchArchiveFunnel,
    watchArchiveProduct,
    watchArchiveSourceProduct,
    watchCreateFunnel,
    watchCreateFunnelProduct,
    watchCreateFunnelShipicaProduct,
    watchFetchData,
    watchFetchShipicaProducts,
    watchMapSourceProduct,
    watchMapSourceProductLctlogistic,
    watchMapSourceProductQuantity,
    watchMapSourceProductShipbob,
    watchMapSourceProductShipica,
    watchMapSourceProductSku,
    watchMapSourceProductType,
    watchProductsFetchData,
    watchSourceProductsFetchData,
    watchUpdateFunnelProduct,
    watchUpdateFunnelShipicaProduct
} from "./FunnelsSaga";
import {
    watchAddManualProductsOrder,
    watchCreateOrder,
    watchFetchOrders,
    watchRemoveManualProductsOrder,
    watchUpdateOrder
} from "./OrdersSaga";
import {watchUpdateContact} from "./ContactsSaga";

export default function* rootSaga() {
    yield all([
        watchFetchData(),
        watchProductsFetchData(),
        watchCreateFunnel(),
        watchArchiveFunnel(),
        watchCreateFunnelProduct(),
        watchUpdateFunnelProduct(),
        watchArchiveProduct(),
        watchSourceProductsFetchData(),
        watchArchiveSourceProduct(),
        watchMapSourceProduct(),
        watchMapSourceProductShipbob(),
        watchMapSourceProductQuantity(),
        watchFetchOrders(),
        watchUpdateOrder(),
        watchUpdateContact(),
        watchAddManualProductsOrder(),
        watchRemoveManualProductsOrder(),
        watchCreateOrder(),
        watchCreateFunnelShipicaProduct(),
        watchUpdateFunnelShipicaProduct(),
        watchFetchShipicaProducts(),
        watchMapSourceProductShipica(),
        watchMapSourceProductLctlogistic(),
        watchMapSourceProductType(),
        watchMapSourceProductSku(),
    ]);
}

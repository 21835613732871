import React, {Fragment} from 'react';
import {CreditCard, LogOut, Settings, User} from 'react-feather';
import {useHistory} from 'react-router-dom'
import {useDispatch} from "react-redux";


const UserMenu = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    // const {logout} = useAuth0();
    const logout = () => {
        dispatch({type: 'SET_AUTH_TOKEN', token: ''});
        history.push('/ds/login');
    }

    const goToPasswordChange = (e) => {
        e.preventDefault();
        history.push('/ds/user/change-password');
    }

    const goToPaymentsPage = (e) => {
        e.preventDefault();
        history.push('/ds/user/payments');
    }

    return (
        <Fragment>
            <li className="onhover-dropdown">
                <a onClick={() => {
                }} className="text-dark" href="#">
                    <Settings/>
                    {/*<UserAvatar*/}
                    {/*    classList={["align-self-center", "pull-right", "img-50", "rounded-circle", "blur-up", "lazyloaded"]}/>*/}
                </a>
                <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                    <li><a href="/profile" onClick={goToPasswordChange}><User/>My Profile</a></li>
                    <li><a href="/payments" onClick={goToPaymentsPage}><CreditCard/>Payments</a></li>
                    {/*<li><a href="#javascript"><Mail />Inbox</a></li>*/}
                    {/*<li><a href="#javascript"><Lock />Lock Screen</a></li>*/}
                    {/*<li><a href="#javascript"><Settings />Settings</a></li>*/}
                    <li><a onClick={(e) => {
                        e.preventDefault();
                        logout();
                    }} href="/logout"><LogOut/> Log out</a></li>
                </ul>
            </li>
        </Fragment>
    );
};


export default UserMenu;

import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';

import * as serviceWorker from './serviceWorker';

import App from "./components/App";
import {store} from "./store";
import {AuthNewAgeErpProvider} from "./Providers/AuthNewAgeErpProvider";
import {Provider} from "react-redux";

function Root() {
    return (
        <Provider store={store}>
            <AuthNewAgeErpProvider>
                <App/>
            </AuthNewAgeErpProvider>
        </Provider>
    )
}

ReactDOM.render(<Root/>, document.getElementById('root'));

serviceWorker.unregister();

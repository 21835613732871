import React, {Fragment, useState} from "react";
import {Order} from "../../../../../typings/Dropton";
import Separator from "./Separator";
import ShowHideComponent from "./ShowHideComponent";
import {useAuthNewAgeErp} from "../../../../../Providers/AuthNewAgeErpProvider";

interface Props {
    order: Order;
    setOrderEditMode: (val: boolean) => void;
}

export default function OrderDetailsCard({order, setOrderEditMode}: Props) {
    const [expand, setExpand] = useState(true);
    // const {isAuthenticated} = useAuthNewAgeErp();

    return (
        <Fragment>
            <Separator/>

            <div className="row no-gutters">
                <div className="col">
                    <strong>Order details</strong>
                </div>

                <div className="col-3 text-right">
                    {/*{isAuthenticated &&*/}
                    <button className="btn btn-link txt-secondary p-0" onClick={() => setOrderEditMode(true)}>
                        <i className="icon s-4 icon-pencil mr-1 txt-secondary"></i>Edit
                    </button>
                    {/*}*/}
                </div>

                <ShowHideComponent expand={expand} setExpand={setExpand}/>
            </div>
            {expand &&
            <div className="row no-gutters">
                <table className="simple-table">
                    <tbody>
                    <tr>
                        <td>Status:</td>
                        <td>{order.status}</td>
                    </tr>
                    <tr>
                        <td>Aliexpress ID:</td>
                        <td>{order.aliexpressId ? order.aliexpressId : "---"}</td>
                    </tr>
                    <tr>
                        <td>Tracking nr:</td>
                        <td>{order.trackingId ? order.trackingId : "---"}</td>
                    </tr>
                    <tr>
                        <td>Payment:</td>
                        <td>{order.paymentType}</td>
                    </tr>
                    <tr>
                        <td>Payment ID:</td>
                        <td>{order.chargeId}</td>
                    </tr>
                    {/*<tr>*/}
                    {/*    <td>Tracking status:</td>*/}
                    {/*    <td>---</td>*/}
                    {/*</tr>*/}
                    </tbody>
                </table>
            </div>
            }

        </Fragment>
    )
}

import React, {Fragment, useEffect, useState} from "react";
import {Order} from "../../../../../typings/Dropton";
import Separator from "./Separator";
import ShowHideComponent from "./ShowHideComponent";
import ApiPlaceOrder from "../../../../../services/ApiPlaceOrder";
import ApiOrders from "../../../../../services/ApiOrders";
import {toast} from "react-toastify";

interface Props {
    order: Order;
}

interface Event {
    event: string;
    timestamp: number;
}

export default function ShipbobCard({order}: Props) {
    const [expand, setExpand] = useState(false);
    const [data, setData] = useState<any>([]);

    const getData = () => {
        ApiOrders.getOrderShipbobDetails(order.id).then(res => {
            setData(res.data);
        });
    };

    useEffect(() => {
        getData();
    }, [expand, order]);

    const sendToShipbob = () => {
        ApiPlaceOrder.placeShipbob(order.id).then(res => {
            if (res.success === 1) {
                toast.success("Success");
            } else {
                toast.error("Error");
            }
        });
    }

    const parseData = () => {
        const keys = Object.keys(data);
        return <div className={"row"}>
            <div className={"col-12"}>
                <pre>{JSON.stringify(data, null, 2)}</pre>
            </div>
        </div>
        // return (
        //     <table className="simple-table">
        //         <tbody>
        //         {keys.map((key, index) => {
        //             const val: any = data[key];
        //             if (Array.isArray(val)) {
        //
        //                 return <Fragment>
        //                     <tr>
        //                         <td colSpan={2}>{key}</td>
        //                     </tr>
        //                     {val.map((ai: any) => {
        //                         const keys2 = Object.keys(ai);
        //                         const els = keys2.map((key2: string) => {
        //                             const val2 = ai[key2];
        //                             return (
        //                                 <tr>
        //                                     <td>&nbsp;&nbsp;&nbsp;{key2}:</td>
        //                                     <td>{val2}</td>
        //                                 </tr>
        //                             );
        //                         })
        //                         els.push(<tr>
        //                             <td colSpan={2}>&nbsp;</td>
        //                         </tr>);
        //                         return els;
        //                     })}
        //
        //                 </Fragment>
        //             } else if (typeof val === 'object') {
        //
        //                 return <tr>
        //                     <td>{key}:</td>
        //                     <td>{JSON.stringify(val)}</td>
        //                 </tr>
        //             }
        //
        //             return (
        //                 <tr>
        //                     <td>{key}:</td>
        //                     <td>{val}</td>
        //                 </tr>
        //             );
        //         })}
        //         </tbody>
        //     </table>
        // )
    }

    return (
        <Fragment>
            <Separator/>

            <div className="row no-gutters">
                <div className="col">
                    <strong>Shipbob details</strong>
                </div>

                <ShowHideComponent expand={expand} setExpand={setExpand}/>
            </div>
            {expand &&
            <div className="row no-gutters">
                <div className="col">
                    <button type={"button"} className={"btn btn-success"} onClick={sendToShipbob}>Send to shipbob
                    </button>
                    {!!data && parseData()}
                </div>

            </div>
            }

        </Fragment>
    )
}

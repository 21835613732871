import React, {useState} from "react";
import FullSizeRow from "../../../shared/grid/FullSizeRow";
import {FormProvider} from "../../../shared/form/FormProvider";
import ProviderFormGroupInput from "../../../shared/form/provider-fields/ProviderFormGroupInput";
import ProviderFormGroupTextarea from "../../../shared/form/provider-fields/ProviderFormGroupTextarea";
import FormPrimaryButton from "../../../shared/form/buttons/FormPrimaryButton";

interface FeedbackFormData {
    subject: string,
    body: string
}

export default function FeedbackForm() {
    const [formData, setFormData] = useState<FeedbackFormData>({
        subject: '',
        body: ''
    });

    return (
        <FormProvider formData={formData} setFormData={setFormData}>
            <FullSizeRow>
                <ProviderFormGroupInput title={"Subject"} inputKey={"subject"}/>
            </FullSizeRow>
            <FullSizeRow>
                <ProviderFormGroupTextarea title={"Body"} inputKey={"body"} rows={5}/>

            </FullSizeRow>
            <FullSizeRow>
                <FormPrimaryButton title={"Send"} onClick={() => {
                }}/>
            </FullSizeRow>
        </FormProvider>
    )
}

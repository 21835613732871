// @ts-nocheck
import React, {Fragment, useEffect} from "react";
import {useDispatch} from "react-redux";
import {ProductAddOption} from "../../../../typings/Dropton";
import ProductsSelectComponent from "../Order/ProductsAdd/ProductsSelectComponent";

interface Props {
    products: ProductAddOption[];
    setProducts: (val: ProductAddOption[]) => void;
    setIsDataChange: (val: boolean) => void;
    funnelId: number;
}

export default function ProductsForm({products, setProducts, setIsDataChange, funnelId}: Props) {
    const dispatch = useDispatch();

    const getData = () => {
        dispatch({type: "FUNNEL_PRODUCTS_FETCH_REQUESTED", id: funnelId})
    };

    useEffect(() => {
        getData();
    }, []);

    const addProduct = (item?: ProductAddOption) => {
        if (item && item.value > 0) {
            setProducts([...products, item]);
            setIsDataChange(true);
        }
    };
    const removeProduct = (index: number) => {
        setProducts([...products.filter((item: ProductAddOption, i: number) => i !== index)]);
        setIsDataChange(true);
    };

    return (
        <Fragment>
            {products.map((item, index: number) => {
                return (
                    <div className="row p-2" key={"product-" + item.value + "-" + index}>
                        <div className="col">{item.label}</div>

                        <div className="col-auto">
                            <a href={"#"} onClick={e => {
                                e.preventDefault();
                                removeProduct(index);
                            }}>X Remove product</a>
                        </div>

                    </div>
                )
            })}
            <div className={"row mt-2 mb-2"}>
                <div className={"col-12"}>
                    <ProductsSelectComponent value={-1} onChange={(product: Option) => {
                        addProduct(product)
                    }}/>
                </div>
            </div>
        </Fragment>
    )
}
import React from "react";

interface Props {
    isDataChange: boolean;
    activated: boolean;
}

export default function SettingsAfterTitle({isDataChange, activated}: Props) {
    return (
        isDataChange ? <span className={"ml-4 badge badge-warning"}>Save your changes</span> :
            <span
                className={"ml-4 badge " + (activated ? "badge-success" : "badge-light")}>{activated ? 'Active' : "Inactive"}</span>
    );
}
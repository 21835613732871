import {Api} from "./Api";
import {BackendConfig} from "../config/BackendConfig";

class ApiUser extends Api {

    changePassword = (oldPassword: string, password: string, passwordR: string) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({oldPassword, password, passwordR})
        };

        return fetch(BackendConfig.backendUrl + '/app/user/changePassword', requestOptions).then(res => res.json());
    }

    getPayments = () => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({})
        };

        return fetch(BackendConfig.backendUrl + '/app/user/payments', requestOptions).then(res => res.json());
    }

    processPayment = (paymentid: string, paytype: string) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({paymentid, paytype})
        };

        return fetch(BackendConfig.backendUrl + '/app/user/processPayment', requestOptions).then(res => res.json());
    }

    changePaymentMethod = (paymentid: string) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({paymentid})
        };

        return fetch(BackendConfig.backendUrl + '/app/user/changePaymentMethod', requestOptions).then(res => res.json());
    }

    cancelSubscription = (id: number) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id})
        };

        return fetch(BackendConfig.backendUrl + '/app/user/cancelSubscription', requestOptions).then(res => res.json());
    }

    getInfo = () => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({})
        };

        return fetch(BackendConfig.backendUrl + '/app/user/getInfo', requestOptions).then(res => res.json());
    }
}


export default new ApiUser();

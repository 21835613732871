import React from 'react';
import ComponentTitle from "../../page/toolbar/ToolbarTitle";
import ListToolbar from "./ListToolbar";
import FunnelsList from "./Table/FunnelsList";
import {ListProvider} from "../../../Providers/ListProvider";
import PageContainer from "../../shared/page/PageContainer";
import FullSizeRow from "../../shared/grid/FullSizeRow";
import OnBoardingPopup from "../../onboarding/OnBoardingPopup";


export default function AllFunnelsPage() {


    // const [funnels, setFunnels] = useState<Funnel[]>([]);
    // const {getIdTokenClaims} = useAuth0();

    // const getData = () => {
    //     getIdTokenClaims().then((token: IdToken) => {
    //         const requestOptions = {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': token.__raw
    //             },
    //             body: JSON.stringify({})
    //         };
    //
    //         fetch(BackendConfig.backendUrl + '/app/dashboard/funnel/list', requestOptions).then(res => res.json()).then(res => {
    //             setFunnels(res.data);
    //         });
    //     });
    // };


    return (
        <PageContainer>

            <ComponentTitle skipBack title={"Funnels"}/>
            <FullSizeRow>
                <div className="card">
                    <ListProvider>
                        <div className="card-body pt-0">
                            <ListToolbar/>
                            <div className="pb-2 pt-4">
                                <FunnelsList/>
                            </div>
                            {/*<Pagination aria-label="Page navigation" className="pagination-primary mt-4">*/}

                            {/*    <PaginationItem>*/}
                            {/*        <button onClick={() => {*/}
                            {/*        }} className="page-link">*/}
                            {/*            <span aria-hidden="true">«</span>*/}
                            {/*        </button>*/}
                            {/*    </PaginationItem>*/}

                            {/*    <PaginationItem disabled={false}>*/}
                            {/*        <button onClick={() => {*/}
                            {/*        }} className="page-link">*/}
                            {/*            <span aria-hidden="true">‹</span>*/}
                            {/*        </button>*/}
                            {/*    </PaginationItem>*/}
                            {/*    <PaginationItem active>*/}
                            {/*        <PaginationLink>*/}
                            {/*            111*/}
                            {/*        </PaginationLink>*/}
                            {/*    </PaginationItem>*/}

                            {/*    <PaginationItem>*/}
                            {/*        <button onClick={() => {*/}
                            {/*        }} className="page-link">*/}
                            {/*            <span aria-hidden="true">›</span>*/}
                            {/*        </button>*/}
                            {/*    </PaginationItem>*/}
                            {/*</Pagination>*/}
                        </div>
                    </ListProvider>
                </div>
            </FullSizeRow>

        </PageContainer>
    )
}

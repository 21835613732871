import React, {Fragment} from "react";
import {Order, OrderTargetProduct} from "../../../../../typings/Dropton";
import {useDispatch} from "react-redux";
import {runRemoveManualProductsOrder} from "../../../../../store/sagas/OrdersSaga";

interface Props {
    item: OrderTargetProduct;
    index: number;
    canRemove?: boolean;
    order: Order;
}

export default function TargetProduct({order, item, index, canRemove}: Props) {
    const dispatch = useDispatch();

    const doRemoveProduct = () => {
        if (!window.confirm('Are you sure?')) return false;

        dispatch(runRemoveManualProductsOrder(order.id, item.__id));
    }
    return (
        <Fragment>
            <div className="row no-gutters">
                <div className="col">
                    {item.name}
                </div>
                {canRemove &&
                <div className={"col-auto text-right"}>
                    <button onClick={doRemoveProduct} className={"btn btn-link txt-secondary"} style={{fontSize: 11}}>X
                        Remove added
                        product
                    </button>
                </div>
                }
            </div>
            {item.shipica && <Fragment>
                <em className={"pl-2"}>Shipica</em>
                <div className="row no-gutters mt-0 pl-4">
                    <div className="col">
                        {item.shipica.name} ({item.shipica.sku})
                    </div>
                    <div className="col-auto text-grey">
                        {item.quantity} unit
                    </div>

                </div>
            </Fragment>}
            {item.shipbobId && <Fragment>
                <em className={"pl-2"}>Shipbob</em>
                <div className="row no-gutters mt-0 pl-4">
                    <div className="col">
                        {item.shipbobId}
                    </div>
                    <div className="col-auto text-grey">
                        {item.quantity} unit
                    </div>

                </div>
            </Fragment>}
            {item.lctlogisticId && <Fragment>
                <em className={"pl-2"}>Lctlogistic</em>
                <div className="row no-gutters mt-0 pl-4">
                    <div className="col">
                        {item.lctlogisticId}
                    </div>
                    <div className="col-auto text-grey">
                        {item.quantity} unit
                    </div>

                </div>
            </Fragment>}


            {/* ---- */}

            {item.shipica2 && <Fragment>
                <em className={"pl-2"}>Shipica</em>
                <div className="row no-gutters mt-0 pl-4">
                    <div className="col">
                        {item.shipica2.name} ({item.shipica2.sku})
                    </div>
                    <div className="col-auto text-grey">
                        {item.quantity2} unit
                    </div>

                </div>
            </Fragment>}
            {item.shipbobId2 && <Fragment>
                <em className={"pl-2"}>Shipbob</em>
                <div className="row no-gutters mt-0 pl-4">
                    <div className="col">
                        {item.shipbobId2}
                    </div>
                    <div className="col-auto text-grey">
                        {item.quantity2} unit
                    </div>

                </div>
            </Fragment>}
            {item.lctlogisticId2 && <Fragment>
                <em className={"pl-2"}>Lctlogistic</em>
                <div className="row no-gutters mt-0 pl-4">
                    <div className="col">
                        {item.lctlogisticId2}
                    </div>
                    <div className="col-auto text-grey">
                        {item.quantity2} unit
                    </div>

                </div>
            </Fragment>}


            {/* ---- */}

            {item.shipica3 && <Fragment>
                <em className={"pl-2"}>Shipica</em>
                <div className="row no-gutters mt-0 pl-4">
                    <div className="col">
                        {item.shipica3.name} ({item.shipica3.sku})
                    </div>
                    <div className="col-auto text-grey">
                        {item.quantity3} unit
                    </div>

                </div>
            </Fragment>}
            {item.shipbobId3 && <Fragment>
                <em className={"pl-2"}>Shipbob</em>
                <div className="row no-gutters mt-0 pl-4">
                    <div className="col">
                        {item.shipbobId3}
                    </div>
                    <div className="col-auto text-grey">
                        {item.quantity3} unit
                    </div>

                </div>
            </Fragment>}
            {item.lctlogisticId3 && <Fragment>
                <em className={"pl-2"}>Lctlogistic</em>
                <div className="row no-gutters mt-0 pl-4">
                    <div className="col">
                        {item.lctlogisticId3}
                    </div>
                    <div className="col-auto text-grey">
                        {item.quantity3} unit
                    </div>

                </div>
            </Fragment>}

            {item.urls && item.urls.length > 0 && <Fragment>
                <em className={"pl-2"}>Aliexpress</em>
                {item.urls.map((url, urlIndex) => {
                    const q = !!item.quantity ? url.quantity * item.quantity : url.quantity;
                    return (
                        <div className="row no-gutters mt-0 pl-4"
                             key={"tpu-" + "-" + urlIndex + "-" + index + "-" + item.id + "-" + url.itemId}>
                            <div className="col">
                                <a href={url.url} className={"txt-secondary mr-1"}
                                   target={"_blank"}><i className="icon-link"></i></a>
                                {url.itemId}

                            </div>
                            <div className="col-auto text-grey">
                                {q} unit
                            </div>

                        </div>
                    )
                })}
            </Fragment>
            }

        </Fragment>
    )
}

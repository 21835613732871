import React, {Fragment, useEffect, useState} from "react";
import {Modal, ModalBody} from "reactstrap";
import {loadStripe} from '@stripe/stripe-js';
import {BackendConfig} from "../../config/BackendConfig";
import {CardElement, Elements} from "@stripe/react-stripe-js";
import StripeDropton from '../../assets/images/stripedropton.png';
import PaymentCheckoutBtn from "./PaymentCheckoutBtn";
import {useDispatch, useSelector} from "react-redux";

const stripePromise = loadStripe(BackendConfig.stripePkKey);

interface Props {
    isValidSubscription: boolean
}

export default function PaymentsModal(props: Props) {
    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(!props.isValidSubscription);
    const [mode, setMode] = useState(0);
    const needPayment = useSelector((state: any) => state.Auth.needPayment)

    useEffect(() => {
        if (needPayment) {
            setIsOpen(needPayment);
        } else {
            setIsOpen(!props.isValidSubscription);
        }

    }, [props.isValidSubscription, needPayment]);

    const closeBlock = () => {
        dispatch({type: 'CLOSE_NEED_PAYMENT'});
    }

    return <Fragment>
        <Modal isOpen={isOpen} size={"lg"} centered={true} toggle={props.isValidSubscription ? closeBlock : undefined}>
            <ModalBody>
                <Elements stripe={stripePromise}>
                    <h2 className="text-center mt-4">{!props.isValidSubscription ? 'Subscribe to Resume Account' : 'Subscribe'}</h2>
                    {!props.isValidSubscription &&
                    <p className="text-center">Unfortunately your 14-days free trial expired. To continue using
                        Dropton please subscribe</p>}

                    <div style={{padding: '10px 40px'}} className={"payment-modal"}>
                        <div className={"row"}>
                            <div className={"col payment-option " + (mode === 0 ? 'active' : '')}>
                                <div className="form-group m-checkbox-inline ml-1">
                                    <span className={"text-danger payment-label top-label"}><span>40% OFF</span></span>
                                    <span
                                        className={"text-danger payment-label bottom-label"}><span>Save 180$</span></span>
                                    <div
                                        className={"checkbox " + (mode === 0 ? "checkbox-success" : "checkbox-primary")}>
                                        <input type="checkbox" id={"mode0"} checked={mode === 0}
                                               onChange={() => setMode(0)}
                                               onClick={() => setMode(0)}/>
                                        <label className="mb-0" htmlFor={'mode0'}><span
                                            style={{fontSize: 22, marginRight: 5}}>Annual</span> 24.90 USD /
                                            month</label>
                                    </div>
                                    <div className="txt-grey">
                                        $299.88 USD billed every year
                                    </div>
                                </div>
                            </div>
                            <div className={"col-1"}></div>
                            <div className={"col payment-option " + (mode === 1 ? 'active' : '')}>
                                <div className="form-group m-checkbox-inline ml-1">
                                    <div
                                        className={"checkbox " + (mode === 1 ? "checkbox-success" : "checkbox-primary")}>
                                        <input type="checkbox" id={"mode1"} checked={mode === 1}
                                               onChange={() => setMode(1)}
                                               onClick={() => setMode(1)}/>
                                        <label className="mb-0" htmlFor={'mode1'}><span
                                            style={{fontSize: 22, marginRight: 5}}>Monthly</span>39.90 USD /
                                            month</label>
                                    </div>
                                    <div className="txt-grey">
                                        39.90 USD billed every month
                                    </div>
                                </div>
                            </div>
                        </div>

                        <p className="text-center mt-4">Add credit card</p>

                        <div className={"stripe-placeholder"}>

                            <CardElement
                                options={{
                                    style: {
                                        base: {
                                            fontSize: '16px',
                                            color: '#32325d',
                                            '::placeholder': {
                                                color: '#aab7c4',
                                            },
                                        },
                                        invalid: {
                                            color: '#fa755a',
                                            iconColor: '#fa755a',
                                        },
                                    },
                                }}
                            />
                        </div>
                        <div className="text-center mt-4">
                            <PaymentCheckoutBtn mode={mode}/>
                        </div>
                        {props.isValidSubscription &&
                        <div className="text-center mt-2">
                            <button className={"btn btn-link"} onClick={closeBlock}>Cancel</button>
                        </div>}

                        <div className={"mt-4"}>
                            {mode === 0 &&
                            <p className="text-center">Your new plan starts today and will be renewed automatically next
                                year.
                                <br/>
                                Subscription can be canceled at any time.
                                <br/>
                                <img src={StripeDropton} style={{width: 180, marginTop: 20}} alt={""}/>
                            </p>
                            }

                            {mode === 1 &&
                            <p className="text-center">Your new plan starts today and will be renewed
                                automatically next
                                month.<br/>Subscription can be canceled at any time.
                                <br/>
                                <img src={StripeDropton} style={{width: 180, marginTop: 20}} alt={""}/>
                            </p>
                            }
                        </div>
                    </div>
                </Elements>
            </ModalBody>
        </Modal>
    </Fragment>
}

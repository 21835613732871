import React from "react";
import {DroptonContactInfo} from "../../../../config/DroptonContactInfo";

export default function FeedbackText() {
    return (
        <div className={"text-16pt"}>
            <p>
                For sales, project or collaboration<br/>
                <a href={"mailto:" + DroptonContactInfo.businessMail}>{DroptonContactInfo.businessMail}</a>
            </p>
            <p>
                General inquiries. Response time up to 48 hours.<br/>
                <a href={"mailto:" + DroptonContactInfo.supportMail}>{DroptonContactInfo.supportMail}</a>
            </p>
            <p>
                Dropton Community Facebook group<br/>
                <a href={DroptonContactInfo.fblink} target="_blank" rel="noopener noreferrer">Join the Dropton Community</a>
            </p>

        </div>
    )
}
// @ts-nocheck
import React, {useEffect, useState} from "react";
import {Modal, ModalBody, ModalHeader} from 'reactstrap';

import {Contact, ContactFormFields, Order} from "../../../../typings/Dropton";
import SaveRow from "../../FunnelSettings/Components/SaveRow";
import {runUpdateContact} from "../../../../store/sagas/ContactsSaga";
import {useDispatch} from "react-redux";
import ContactEditForm from "../Forms/ContactEditForm";


interface Props {
    isOpen: boolean;
    toggle: () => void;
    contact: Contact,
    order: Order
}


export default function ContactEditModal({isOpen, toggle, contact, order}: Props) {
    const dispatch = useDispatch();
    const [isDataChange, setIsDataChange] = useState(false);
    const [contactForm, setContactForm] = useState<ContactFormFields>({
        name: contact.name,
        address: contact.address,
        city: contact.city,
        country: contact.country,
        state: contact.state,
        zip: contact.zip,
        email: contact.email,
        phone: contact.phone,
    });

    useEffect(() => {
        setContactForm({
            name: contact.name,
            address: contact.address,
            city: contact.city,
            country: contact.country,
            state: contact.state,
            zip: contact.zip,
            email: contact.email,
            phone: contact.phone,
        });
    }, [contact]);


    const saveData = (e) => {
        e.preventDefault();
        dispatch(runUpdateContact(contact.id, order.id, contactForm));
        toggle();
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle}>Customer details</ModalHeader>
            <ModalBody>
                <ContactEditForm contactForm={contactForm} setContactForm={setContactForm}
                                 setIsDataChange={setIsDataChange}/>
                <SaveRow isDataChange={isDataChange} saveData={saveData}/>
            </ModalBody>
        </Modal>
    )
}
import React, {useState} from 'react';
import DroptonAssets from "../../assets/images/dropton/DroptonAssets";
import {useHistory} from "react-router-dom";
import ApiPublicUser from "../../services/ApiPublicUser";
import {useDispatch} from "react-redux";

const RegisterPage = () => {
    const history = useHistory();

    const dispatch = useDispatch();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordR, setPasswordR] = useState('');
    const [error, setError] = useState('');

    const goToLogin = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        history.push('/ds/login')
    }

    const doRegister = () => {
        setError('');
        ApiPublicUser.register(email, password, passwordR).then(res => {
            if (res.success === 0) {
                setError(res.error.description);
            } else {
                dispatch({type: 'SET_AUTH_TOKEN', token: res.token});
                history.push('/ds/funnels');
            }
        }).catch(e => {
            setError('Server error');
        })
    }

    return (
        <div>
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    {/* <!-- login page start--> */}
                    <div className="authentication-main">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="">
                                    <div className="authentication-box">
                                        <div className="card mt-8">
                                            <div className="card-body">
                                                <div className="text-center mb-4"><img src={DroptonAssets.loginLogo}
                                                                                       alt=""
                                                                                       style={{borderRadius: 10}}/>
                                                </div>
                                                <div className="text-center">
                                                    <h6>Create an account</h6>
                                                </div>
                                                <form className="theme-form">
                                                    {error && <div className={"row mt-2 mb-2"}>
                                                        <div className={"col-12 text-center txt-danger"}>
                                                            {error}
                                                        </div>
                                                    </div>}

                                                    <div className="form-group">
                                                        <label className="col-form-label pt-0">Email</label>
                                                        <input className="form-control" type="text" required={true}
                                                               value={email} onChange={e => setEmail(e.target.value)}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label">Password</label>
                                                        <input className="form-control" type="password"
                                                               value={password}
                                                               onChange={e => setPassword(e.target.value)}
                                                               required={true}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label">Repeat password</label>
                                                        <input className="form-control" type="password"
                                                               value={passwordR}
                                                               onChange={e => setPasswordR(e.target.value)}
                                                               required={true}/>
                                                    </div>
                                                    <div className="form-group form-row mt-3 mb-0">
                                                        <button className="btn btn-primary btn-block"
                                                                type="button" onClick={doRegister}>Create my account
                                                        </button>
                                                    </div>

                                                    <div className={"row mt-4"}>
                                                        <div className={"col-12 text-center"}>
                                                            Already have an account? <a href={"/ds/login"}
                                                                                        onClick={goToLogin}>Log in</a>
                                                        </div>
                                                    </div>

                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- login page end--> */}
                </div>
            </div>
        </div>
    );
};

export default RegisterPage;

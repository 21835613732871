import React from "react";

import {useParams} from "react-router-dom";
import OrderPagination from "./OrderPagination";
import {useDispatch, useSelector} from "react-redux";

export default function OrderFooter() {
    const {id} = useParams();
    const dispatch = useDispatch();

    const pagingValue = useSelector((state: any) => state.Settings.paging);
    const setPagingValue = (val: any) => {
        const paging = parseInt(val, 10);
        dispatch({type: 'SET_PAGING', payload: paging});
        localStorage.setItem('paging', paging.toString());
    };
    const pagingValues = [10, 25, 50, 100];

    const ordering = useSelector((state: any) => state.Settings.ordering);
    const setOrdering = (val: any) => {
        dispatch({type: 'SET_ORDERING', payload: val});
        localStorage.setItem('ordering', val);
    };

    return (
        <div className={"row mt-4"}>
            <div className={"col-4"}>
                <OrderPagination/>
            </div>
            <div className="col-4">
                Order:

                <select value={ordering} onChange={(e) => setOrdering(e.target.value)}
                        className={"img-150 custom-select ml-2"}>
                    <option value={'ASC'}>Older first</option>
                    <option value={'DESC'}>Newest first</option>
                </select>

            </div>
            <div className="col-4">
                Items per page:

                <select value={pagingValue} onChange={(e) => setPagingValue(e.target.value)}
                        className={"img-100 custom-select ml-2"}>
                    {pagingValues.map(i => {
                        return (<option key={"opt-" + i} value={i}>{i}</option>);
                    })}
                </select>

            </div>
            {/*<div className={"col-4 d-flex justify-content-end"}>*/}
            {/*    <Link to={'/app/settings/' + id} className="d-flex align-items-center btn btn-icon"*/}
            {/*          title="Settings"><i className="icon-settings"></i></Link>*/}
            {/*</div>*/}
        </div>
    )
}
import React from "react";
import {StepWizardChildProps} from "react-step-wizard";
import PrimaryButton from "../../../navigation/components/PrimaryButton";
import LightButton from "../../../navigation/components/LighButton";

interface Props extends StepWizardChildProps {
    isInstall?: boolean;
    toggle?: () => void;
}

export default function StepNavigation(props: Props) {
    const showBack = !(props.isInstall && props.currentStep === 1);
    const goBack = () => {
        if (props.currentStep === 1) {
            if (props.toggle) {
                props.toggle();
            }
        } else {
            props.previousStep()
        }
    };
    return (<div className="row mt-4">

        <div className="col">
            {showBack &&
            <LightButton title={"Back"} onClick={goBack}/>}
        </div>

        <div className="col-auto">
            <PrimaryButton title={"Ok, Let's continue"} onClick={props.nextStep}/>
        </div>
    </div>)
}
import React, {ChangeEvent, useEffect, useState} from "react";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {useDispatch} from "react-redux";
import {ShipicaProduct} from "../../../../../typings/Dropton";
import PrimaryButton from "../../../../navigation/components/PrimaryButton";


interface Props {
    isOpen: boolean;
    toggle: () => void;
    id: number;
    product?: ShipicaProduct
}

export default function ShipicaProductModal({isOpen, toggle, id, product}: Props) {
    const dispatch = useDispatch();

    const [canSave, setCanSave] = useState(false);
    const [name, setName] = useState('');
    const [sku, setSku] = useState('');

    const resetState = () => {
        if (product) {
            setName(product.name);
            setSku(product.sku);
        }
        toggle();
    };

    useEffect(() => {
        let isError = false;
        if (name.length < 1) {
            isError = true;
        }
        if (sku.length < 1) {
            isError = true;
        }

        setCanSave(!isError);
    }, [name, sku]);

    useEffect(() => {
        if (product) {
            setName(product.name);
            setSku(product.sku);
        }

    }, [product]);

    const saveProduct = () => {
        if (!product) {
            dispatch({
                type: 'CREATE_FUNNEL_SHIPICA_PRODUCT',
                name: name,
                sku: sku,
                funnelId: id,
                success: () => {
                    resetState();
                }
            })
        } else {
            dispatch({
                type: 'UPDATE_FUNNEL_SHIPICA_PRODUCT',
                name: name,
                sku: sku,
                funnelId: id,
                id: product.id,
                success: () => {
                    resetState();
                }
            })
        }

    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle}>Shipica product</ModalHeader>
            <ModalBody>
                <div className="form-group">
                    <label htmlFor="name">Title</label>
                    <input type="text" className="form-control"
                           value={name}
                           onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}/>

                </div>

                <div className="form-group">
                    <label htmlFor="name">Sku</label>
                    <input type="text" className="form-control"
                           value={sku}
                           onChange={(e: ChangeEvent<HTMLInputElement>) => setSku(e.target.value)}/>

                </div>


            </ModalBody>
            <ModalFooter>
                <PrimaryButton title={"Save"} disabled={!canSave} onClick={() => {
                    saveProduct();
                }}/>
            </ModalFooter>
        </Modal>
    )
}

import React, {useState} from 'react'
import ToolbarInlineTitle from "../../page/toolbar/ToolbarInlineTitle";
import OrdersStatusDropdown from "./OrdersStatusDropdown";
import PrimaryButton from "../../navigation/components/PrimaryButton";
import NewOrderModal from "./NewOrder/NewOrderModal";
import {Link, useParams} from "react-router-dom";


interface Props {
    title?: string;
    activeStatus: string;
    setActiveStatus: (val: string) => void;
    syncTracking: () => void;
}

export default function ListToolbar({title, activeStatus, setActiveStatus, syncTracking}: Props) {
    const [newModalOpen, setNewModalOpen] = useState(false);
    const toggleNewModalOpen = () => setNewModalOpen(!newModalOpen);
    const {id} = useParams();

    return (
        <div className="pb-2 pt-4">

            <div className="row" style={{alignItems: 'center'}}>
                {title && <ToolbarInlineTitle title={title}/>}

                <div className={"col-auto p-0"}>
                    <Link to={'/ds/settings/' + id} className="p-0 btn btn-icon"
                          title="Settings"><i className="icon-settings"></i></Link>
                </div>
                <div className={"col-auto"}>
                    <Link to={'/ds/stats/' + id} className="p-0 btn btn-icon"
                          title="Settings"><i className="icon-stats-up"></i></Link>
                </div>

                <div className={"col"}></div>


                {/*<ToolbarSearch/>*/}


                {activeStatus === 'new' &&
                <div className={"col-auto"}>
                    <PrimaryButton onClick={() => setNewModalOpen(true)} title={"Create"}/>
                </div>
                }

                {activeStatus === 'fulfilled' &&
                <div className={"col-auto"}>
                    <button onClick={syncTracking}
                            className="btn btn-icon bg-primary" title="Update tracking">
                        <i className="icon icon-cloud-down txt-white"></i>
                    </button>
                </div>}


                <div className={"col-auto"}>
                    <OrdersStatusDropdown activeStatus={activeStatus} setActiveStatus={setActiveStatus}/>
                </div>

            </div>


            <NewOrderModal isOpen={newModalOpen} toggle={toggleNewModalOpen}/>
        </div>
    )
}

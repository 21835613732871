import React from "react";

export default function PaypalHelpText() {
    return (
        <p className="txt-grey">
            Sync trackings between ClickFunnels and PayPal using Dropton.<br/>
            <br/>
            Dropton offers one click automation to update tracking information for your PayPal
            orders. To configure this feature you need to create PayPal API keys. Please follow
            following steps: <br/>
            1. Login to your PayPal account<br/>
            2. Open this link - <a href="https://developer.paypal.com/developer/applications/"
                                   target="_blank" rel="noopener noreferrer">https://developer.paypal.com/developer/applications/</a>
            <br/>
            3. Select Live on Tab and Click on Create App<br/>
            4. Type the name for your App (recommended: Dropton Integration)<br/>
            5. Scroll to Live App Settings and make sure “Accept Payment” and “Transaction
            Search” boxes are checked only. (For security reasons, please uncheck all other
            checkboxes)<br/>
            6. Click SAVE, then Scroll back to Live API Credentials<br/>
            7. Copy the Client ID and Secret code, then paste them in Dropton settings.<br/>
            <br/>
            When integrated, Dropton will update tracking information to PayPal once per 24
            hours.<br/>
            <br/>
            Please note: you need to update tracking information from AliExpress. To update
            them, please go to Funnel (on the left side), select Filled from the dropdown and
            click on Update tracking button (on the left of Items per page).
        </p>
    )
}
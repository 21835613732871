import * as Icon from 'react-feather';
import {DroptonContactInfo} from "./DroptonContactInfo";

export const MENUITEMS = [
    {
        title: 'All funnels', icon: Icon.Briefcase, type: 'funnels', path: '/ds/funnels', active: true, children: []
    },
    {
        title: 'Helpdesk (FAQ}', icon: Icon.HelpCircle, type: 'link', path: '/ds/faq', active: false
    },
    {
        title: 'Send feedback', icon: Icon.Mail, type: 'link', path: '/ds/feedback/new', active: false
    },
    {
        title: 'Join Dropton Community', icon: Icon.User, type: 'external', path: DroptonContactInfo.fblink, active: false
    },
    {
        title: 'Affiliate Program',
        icon: Icon.Circle,
        type: 'external',
        path: DroptonContactInfo.affiliateLink,
        active: false
    },
];


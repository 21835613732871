import {BackendConfig} from "../config/BackendConfig";
import {FunnelGeneralInfo} from "../typings/Dropton";
import {Api} from "./Api";

class ApiFunnels extends Api {

    archiveFunnel = (id: number, flag: number) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: id, flag: flag})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/funnel/archive', requestOptions).then(res => res.json());
    };

    createFunnel = (funnelName: string, url: string) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({name: funnelName, url: url})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/funnel/create', requestOptions).then(res => res.json());
    };

    fetchFunnels = () => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/funnel/list', requestOptions).then(res => res.json());
    };

    fetchFunnelProducts = (id: number) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: id})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/funnel/products', requestOptions).then(res => res.json());
    };

    fetchFunnelShipicaProducts = (id: number) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: id})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/funnel/shipicaProducts', requestOptions).then(res => res.json());
    };

    fetchSourceProducts = (id: number) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: id})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/funnel/sourceproducts', requestOptions).then(res => res.json());
    };

    createFunnelProduct = (funnelId: number, name: string, products: any) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({funnelId, name, products})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/product/create', requestOptions).then(res => res.json());
    };

    updateFunnelProduct = (funnelId: number, id: number, name: string, products: any) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({funnelId, id, name, products})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/product/update', requestOptions).then(res => res.json());
    };

    archiveProduct = (id: number, flag: number) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: id, flag: flag})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/product/archive', requestOptions).then(res => res.json());
    };

    archiveSourceProduct = (id: number, flag: number) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: id, flag: flag})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/sourceproduct/archive', requestOptions).then(res => res.json());
    };

    mapSourceProduct = (id: number, productId: number) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: id, productId: productId})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/sourceproduct/map', requestOptions).then(res => res.json());
    };

    mapSourceProductShipbob = (id: number, productId: number, index: number) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: id, productId: productId, index})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/sourceproduct/mapShipbob', requestOptions).then(res => res.json());
    };
    mapSourceProductShipica = (id: number, productId: number, index: number) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: id, productId: productId, index})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/sourceproduct/mapShipica', requestOptions).then(res => res.json());
    };

    mapSourceProductLctlogistic = (id: number, productId: number, index: number) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: id, productId: productId, index})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/sourceproduct/mapLctlogistic', requestOptions).then(res => res.json());
    };

    mapSourceProductSku = (id: number, sku: string) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id, sku})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/sourceproduct/mapSku', requestOptions).then(res => res.json());
    };

    mapSourceProductType = (id: number, type: string) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id, type})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/sourceproduct/mapType', requestOptions).then(res => res.json());
    };

    mapSourceProductQuantity = (id: number, quantity: number, index: number) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: id, quantity, index})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/sourceproduct/mapQuantity', requestOptions).then(res => res.json());
    };

    getFunnelGeneralInfo = (id: number) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: id})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/funnel/general', requestOptions).then(res => res.json());
    }

    setFunnelGeneralInfo = (funnel: FunnelGeneralInfo, id: number) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: id, ...funnel})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/funnel/general/save', requestOptions).then(res => res.json());
    }

    getFunnelAliexpressCaptchaSettings(id: number) {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: id})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/funnelsettings/aliexpresscaptcha/get', requestOptions).then(res => res.json());
    }

    setFunnelAliexpressCaptchaSettings(settings: any, id: number) {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: id, ...settings})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/funnelsettings/aliexpresscaptcha/set', requestOptions).then(res => res.json());
    }


    getFunnelAliexpressSettings(id: number) {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: id})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/funnelsettings/aliexpress/get', requestOptions).then(res => res.json());
    }

    setFunnelAliexpressSettings(settings: any, id: number) {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: id, ...settings})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/funnelsettings/aliexpress/set', requestOptions).then(res => res.json());
    }


    getFunnelEmailNotificationsSettings(id: number) {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: id})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/funnelsettings/emailnotifications/get', requestOptions).then(res => res.json());
    }

    getFunnelShipicaSettings(id: number) {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: id})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/funnelsettings/shipica/get', requestOptions).then(res => res.json());
    }

    setFunnelShipicaSettings(settings: any, id: number) {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: id, ...settings})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/funnelsettings/shipica/set', requestOptions).then(res => res.json());
    }

    setFunnelEmailNotificationsSettings(settings: any, id: number) {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: id, ...settings})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/funnelsettings/emailnotifications/set', requestOptions).then(res => res.json());
    }


    getFunnelPaypalSettings(id: number) {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: id})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/funnelsettings/paypal/get', requestOptions).then(res => res.json());
    }

    setFunnelPaypalSettings(settings: any, id: number) {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: id, ...settings})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/funnelsettings/paypal/set', requestOptions).then(res => res.json());
    }

    getFunnelShipbobSettings(id: number) {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: id})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/funnelsettings/shipbob/get', requestOptions).then(res => res.json());
    }

    createShipbobProduct(id: number, name: string, referenceId: string, barcode: string, sku: string) {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id, name, referenceId, barcode, sku})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/funnelsettings/shipbob/createProduct', requestOptions).then(res => res.json());
    }

    setFunnelShipbobSettings(settings: any, id: number) {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: id, ...settings})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/funnelsettings/shipbob/set', requestOptions).then(res => res.json());
    }

    prettyUrl(url: string) {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({url: url})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/product/prettyUrl', requestOptions).then(res => res.json());
    }

    parseAliexpressResponse(content: any) {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({content: content})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/product/parseAliexpressResponse', requestOptions).then(res => res.json());
    }


    createFunnelShipicaProduct = (funnelId: number, name: string, sku: string) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({funnelId, name, sku})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/shipicaProduct/create', requestOptions).then(res => res.json());
    };

    updateFunnelShipicaProduct = (funnelId: number, id: number, name: string, sku: string) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({funnelId, id, name, sku})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/shipicaProduct/update', requestOptions).then(res => res.json());
    };

    archiveShipicaProduct = (id: number, flag: number) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: id, flag: flag})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/shipicaProduct/archive', requestOptions).then(res => res.json());
    };


    getFunnelLctlogisticSettings(id: number) {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: id})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/funnelsettings/lctlogistic/get', requestOptions).then(res => res.json());
    }

    setFunnelLctlogisticSettings(settings: any, id: number) {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({id: id, ...settings})
        };

        return fetch(BackendConfig.backendUrl + '/app/dashboard/funnelsettings/lctlogistic/set', requestOptions).then(res => res.json());
    }
}

export default new ApiFunnels();
